import { Alert, Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SimpleGridComponent from '../../SimpleGridComponent/SimpleGridComponent';
import { useState } from 'react';

import './styles.scss';

const columns = [
    {
        "key": "companyName",
        "name": "Company Name",
        "sortable": false,
        "filterable": false,
        "minWidth": "14rem"
    },
    {
        "key": "ticker",
        "name": "Ticker",
        "sortable": false,
        "filterable": false,
    },

    {
        "key": "exchangeTicker",
        "name": "Exchange / Ticker",
        "sortable": false,
        "filterable": false,
    },
];

const TickerNameConflictModal = ({ isOpen, onClose, conflictingCompanies, selectedCompanyIds, onSelectCompanyId, onSearch }) => {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const conflicingTickers = [...new Set(conflictingCompanies.map(company => company.ticker))];
    const selectedTickers = conflictingCompanies.filter(company => selectedCompanyIds.includes(company.exchangeTicker))
        .map(company => company.ticker);
    const uniqueSelectedTickers = [...new Set(selectedTickers)];

    const handleModalClose = () => {
        onClose()
    }

    const handleCompanySelection = (uniqueIds) => {
        onSelectCompanyId(uniqueIds)
    }

    const handleSearch = () => {
        if (conflicingTickers.length !== uniqueSelectedTickers.length) {
            setHasError(true);
            setErrorMessage("Please select at least one company for each ticker");
            return;
        }
        setHasError(false);
        setErrorMessage("");
        onClose();
        onSearch();
    }

    return (
        <Modal className='ticker-name-conflict-modal' show={isOpen} onHide={onClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div>Confirm Ticker / Company</div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='sub-text'>Multiple companies with the same ticker were found. Please select the company you would like to view.</div>
                <div className="conflicing-ticker-grid">
                    {(conflictingCompanies && conflictingCompanies.length > 0) ?
                        <SimpleGridComponent
                            columns={columns}
                            data={conflictingCompanies}
                            showHeaderFilters={false}
                            onRowSelect={(uniqueIds) => handleCompanySelection(uniqueIds)}
                            onCurrentPageSelect={() => { }}
                            itemsName="Conflicting Tickers"
                            uniqueKey={"exchangeTicker"}
                        ></SimpleGridComponent>
                        : <div className='no-conflicting-tickers'>No conflicting tickers found.</div>}
                </div>
                {hasError && <Alert variant="warning">{errorMessage}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSearch}>
                    Done
                </Button>
                <Button variant="secondary" onClick={handleModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default TickerNameConflictModal

TickerNameConflictModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    conflictingCompanies: PropTypes.array.isRequired,
    selectedCompanyIds: PropTypes.array.isRequired,
    onSelectCompanyId: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired
}