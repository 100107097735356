import React, { useContext } from 'react';
import { Toast } from 'react-bootstrap';
import { ToastContext } from '../../Contexts/ToastProvider';

import './styles.scss';

export const AppToast = () => {
    const { show, message, setShow } = useContext(ToastContext);

    return (
        <div className='app-toast-container'>
            <Toast show={show} onClose={() => setShow(false)} delay={3000} autohide>
                <Toast.Header closeButton={false}>
                    <strong className="mr-auto">Notification</strong>
                </Toast.Header>
                <Toast.Body>{message}</Toast.Body>
            </Toast>
        </div>
    );
};