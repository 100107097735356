import PropTypes from 'prop-types';
import React from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import './styles.scss';

export default function DataSubmissionConfirmationModal({ show, handleClose, handleDataRequestSubmission, latestTemplateDataAvailable, activeTemplates }) {
    return (
        <Modal className='data-submission-confirmation-modal' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Data Request Submission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {latestTemplateDataAvailable.size > 0 &&
                    <Alert variant="info">
                        <Alert.Heading>Latest Data Available</Alert.Heading>
                        <p>You can access the latest data for some of the companies you're requesting by clicking on the links below before submitting your request.</p>
                        <ul>
                            {Array.from(latestTemplateDataAvailable).map(([key, value]) => {
                                return (
                                    <li key={key}>
                                        {activeTemplates.find(template => template.templateId === key).templateName}:
                                        <Link
                                            to={`/?templateId=${key}&tickers=${value.join(',')}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >{value.join(', ')}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleClose}>Cancel</button>
                <button className="btn btn-primary" onClick={handleDataRequestSubmission}>Submit</button>
            </Modal.Footer>
        </Modal>
    )
}

DataSubmissionConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleDataRequestSubmission: PropTypes.func.isRequired,
    latestTemplateDataAvailable: PropTypes.object.isRequired,
    activeTemplates: PropTypes.array.isRequired
}