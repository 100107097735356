import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { Row } from 'react-bootstrap';

import './styles.scss';

export default function DataRequestLinksComponent({ companyRequests }) {
    const [templateTickerMap, setTemplateTickerMap] = useState({});
    const [templateNameMap, setTemplateNameMap] = useState({});

    const parseTemplateTickers = () => {
        const templateTickers = {}
        const templateNames = {}
        for (const request of companyRequests) {
            if (!templateTickers[request.templateId]) {
                templateTickers[request.templateId] = [];
            }
            templateTickers[request.templateId].push(request.companyId);
            if (!templateNames[request.templateId]) {
                templateNames[request.templateId] = request.templateName;
            }
        }
        setTemplateTickerMap(templateTickers);
        setTemplateNameMap(templateNames);
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setTemplateTickerMap({});
            setTemplateNameMap({});
        }
        parseTemplateTickers();
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyRequests]);

    return (
        <div className="data-request-links">
            < h6 > Data Links</h6 >
            <Row>
                {Object.keys(templateTickerMap).map((key) => {
                    return (
                        <a
                            key={key}
                            href={`/?templateId=${key}&tickers=${templateTickerMap[key].join(',')}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{templateNameMap[key]}</a>
                    )
                })}
            </Row>
        </div >
    )
}

DataRequestLinksComponent.propTypes = {
    companyRequests: PropTypes.array.isRequired
}