import { DATA_TYPES } from '../Constants';

export const filterRows = (filters, compensations) => {
    let fitleredCompensations = compensations;
    for (const [filterKey, filterValue] of Object.entries(filters)) {
        if (filterKey === "enabled")
            continue;
        if (filterValue !== null && filterValue !== '') {
            fitleredCompensations = fitleredCompensations.filter(comp => {
                if (comp[filterKey] === null || comp[filterKey] === undefined || comp[filterKey] === "")
                    return false;
                const filterValues = filterValue.split(',');
                if (filterValues.length > 1) {
                    return filterValues.some(filterValue => comp[filterKey].toString().toLowerCase().includes(filterValue.toLowerCase()))
                } else {
                    return (filterValue ? comp[filterKey].toString().toLowerCase().includes(filterValue.toLowerCase()) : true)
                }
            })
        }
    }
    return fitleredCompensations;
} 

export const sortComps = (dataRows, sortColumns, columns) => {
    if (sortColumns.length === 0) {
        return dataRows;
    }
    let sortedRows = [...dataRows];
    for (const { columnKey, direction } of sortColumns) {
        const selectedColumn = columns.find(col => col.key === columnKey);
        if (!selectedColumn) {
            return dataRows;
        }
        const columnType = selectedColumn.type;
        switch (columnType) {
            case DATA_TYPES.DATE:
                sortedRows = sortedRows.sort((a, b) => {
                    const dateA = a[columnKey] === undefined || a[columnKey] === null ? new Date(0) : new Date(a[columnKey]);
                    const dateB = b[columnKey] === undefined || b[columnKey] === null ? new Date(0) : new Date(b[columnKey]);
                    if (direction === 'ASC') {
                        return dateA - dateB;
                    } else {
                        return dateB - dateA;
                    }
                });
                break;
            case DATA_TYPES.NUMBER:
                sortedRows = sortedRows.sort((a, b) => {
                    const numA = a[columnKey] === '' || a[columnKey] === null || isNaN(a[columnKey]) ? 0 : Number(a[columnKey]);
                    const numB = b[columnKey] === '' || b[columnKey] === null || isNaN(b[columnKey]) ? 0 : Number(b[columnKey]);
                    if (direction === 'ASC') {
                        return numA - numB;
                    } else {
                        return numB - numA;
                    }
                });
                break;
            default:
                sortedRows = sortedRows.sort((a, b) => {
                    const strA = a[columnKey] === undefined || a[columnKey] === null ? '' : a[columnKey].toString().toLowerCase();
                    const strB = b[columnKey] === undefined || b[columnKey] === null ? '' : b[columnKey].toString().toLowerCase();
                    if (direction === 'ASC') {
                        return strA.localeCompare(strB);
                    } else {
                        return strB.localeCompare(strA);
                    }
                });
                break;
        }
    }
    return sortedRows;
};

export const copyValueToClipboard = async (e, showToast) => {
    const value = e.sourceRow[e.sourceColumnKey];
    await navigator.clipboard.writeText(value);
    showToast(`Copied ${value} to clipboard`);
}


export const inputStopPropagation = (event) => {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}