import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import CompensationGrid from "../CommonComponents/CompensationGrid/CompensationGrid";
import { EXEC_TITLES, HIDDEN_COLUMNS, formatTickers } from "../Constants";

import { useStateStore } from "../AppState";

import { API } from "aws-amplify";
import ToolTipWrapper from "../CommonComponents/ToolTipComponent/ToolTipWrapper";

import { useSearchParams } from "react-router-dom";
import TemplateCompaniesGridModal from "../TemplateCompaniesGridModal/TemplateCompaniesGridModal";
import { getAuthToken } from "../utils/AuthUtils";
import ResultIndicators from "./ResultIndicators/ResultIndicators";
import TickerNameConflictModal from "./TickerNameConflictModal/TickerNameConfictModal";

import BaseCompensationDataInputs from "./BaseCompensationDataInputs/BaseCompensationDataInputs";
import WarningMessageModal from "./WarningMessageModal/WarningMessageModal";

import TemplateFilters from "./TemplateFilters";
import ToolbarActions from "./ToolbarActions/ToolbarActions";
import "./styles.scss";

const SUMMARY = "Summary";

const ExploreCompensationData = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const searchParamTemplateId = searchParams.get("templateId");
    const searchParamsTickers = searchParams.get("tickers");
    const searchParamsYears = searchParams.get("years") ? parseInt(searchParams.get("years")) : searchParams.get("years") || 1;

    const [templateNameList, setTemplateNameList] = useState([]);

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState("");
    const [gridColumns, setGridColumns] = useState([]);

    const { loading, setLoadingStatus } = useStateStore();
    const [isFormValid, setIsFormValid] = useState(false);
    const [searching, setSearching] = useState(false);
    const [error, setError] = useState("");
    const [showError, setShowError] = useState(false);
    const [hasSearchResults, setHasSearchResults] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const [gridExpansionStatus, setGridExpansionStatus] = useState(false);
    const [compensationData, setCompensationData] = useState([]);
    const [selectedCompensationIds, setSelectedCompensationIds] = useState(new Set());
    const [unavailableCompaniesMap, setUnavailableCompaniesMap] = useState(new Map());

    const [unavailableTickersList, setUnavailableTickersList] = useState([]);

    const [numberOfMatchedCompanies, setNumberOfMatchedCompanies] = useState([]);
    const [numberOfUnmatchedCompanies, setNumberOfUnmatchedCompanies] = useState([]);

    const [tickers, setTickers] = useState([]);

    const [years, setYears] = useState(1);
    const [downloadEnabled, setDownloadEnabled] = useState(false);
    const [templateFilters, setTemplateFilters] = useState([]);

    const formRef = useRef(null);

    const [showCompaniesModal, setShowCompaniesModal] = useState(false);

    const [areTickersRequired, setAreTickersRequired] = useState(true);

    const [hasConflictingTicker, setHasConflictingTicker] = useState(false);
    const [selectedConflictingCompanyIds, setSelectedConflictingCompanyIds] = useState([]);
    const [conflictingCompanies, setConflictingCompanies] = useState([]);

    const [showTickerNameConflictModal, setShowTickerNameConflictModal] = useState(false);

    const [successfulDownload, setSuccessfulDownload] = useState(false);

    const [selectedSheetName, setSelectedSheetName] = useState(SUMMARY);

    const [showWarningMessageModal, setShowWarningMessageModal] = useState(false);

    const handleSettingGridColumns = (data) => {
        const columns = data.summaryColDefinitions.filter(colDef => !HIDDEN_COLUMNS.includes(colDef.name));
        setGridColumns(columns);
    }

    const handleSettingFilters = (columnDefinitions) => {
        const filters = [];
        const filterableColumnsDefs = columnDefinitions
            .filter(colDef => colDef.filterableColumnName && colDef.filterableColumnName !== null);
        filterableColumnsDefs.forEach(colDef => {
            const filter = {
                columnName: colDef.filterableColumnName,
                fieldName: colDef.name,
                selectedValues: [],
                options: colDef.isTitleCode !== undefined && colDef.isTitleCode ? EXEC_TITLES : []
            }
            filters.push(filter);
        });
        setTemplateFilters(filters);
    }

    const handleFilteringOptions = (filter, options) => {
        const newFilters = [...templateFilters];
        const updatedFilters = newFilters.map(f => {
            if (f.columnName === filter.columnName) {
                return {
                    ...f, options: options
                };
            } else {
                return f;
            }
        });
        setTemplateFilters(updatedFilters);
    }

    const handleFilterUpdate = (filter, selectedOptions) => {
        const newFilters = [...templateFilters];
        const updatedFilters = newFilters.map(f => {
            if (f.columnName === filter.columnName) {
                return {
                    ...f, selectedValues: selectedOptions
                };
            } else {
                return f;
            }
        });
        setTemplateFilters(updatedFilters);
    }

    const resolveSelectedOptions = (prevSelectedOptions, id) => {
        const newArray = [...prevSelectedOptions]
        if (newArray.includes(id)) {
            return newArray.filter(item => item !== id)
        } else {
            newArray.push(id)
            return newArray;
        }
    }

    const findMatchingOptions = (optionLower) => {
        return EXEC_TITLES.filter((title) => title.value.toLowerCase().includes(optionLower) || title.id.toString().toLowerCase().includes(optionLower));
    }

    const toggleMultiSelectedOptions = (optionId, filter) => {
        const selectedOptions = resolveSelectedOptions(filter.selectedValues, optionId);
        handleFilterUpdate(filter, selectedOptions);
    }

    const handleSettingCompensationData = (response, companyTickerList) => {
        if (response === "" || response === undefined || response === null) {
            handleEmptyResponse(companyTickerList);
            return;
        }

        const unmatchedByYearLookbackMap = getUnmatchedByYearLookbackMap(response);
        if (response.data == null || response.data.length === 0) {
            handleNoDataAvailableResponse(companyTickerList);
            return;
        }

        handleDataAvailableResponse(response, unmatchedByYearLookbackMap, companyTickerList);
    };

    const handleEmptyResponse = (companyTickerList) => {
        setCompensationData([]);
        setUnavailableTickersList(companyTickerList);
        setNumberOfMatchedCompanies(0);
        setNumberOfUnmatchedCompanies(companyTickerList.length);
        setUnavailableCompaniesMap(new Map());
        setHasSearchResults(false);
        setShowAlert(true);
    };

    const getUnmatchedByYearLookbackMap = (response) => {
        return response.unmatchedData !== null ? new Map(Object.entries(response.unmatchedByYearLookback)) : new Map();
    };

    const handleNoDataAvailableResponse = (companyTickerList) => {
        setCompensationData([]);
        setHasSearchResults(false);
        setUnavailableTickersList(companyTickerList);
        setNumberOfMatchedCompanies(0);
        setNumberOfUnmatchedCompanies(companyTickerList.length);
        setShowAlert(true);
        handleSettingTemplate(selectedTemplate); //reset columns to default summary columns
        setSelectedSheetName(SUMMARY);
    };

    const handleDataAvailableResponse = (response, unmatchedByYearLookbackMap, companyTickerList) => {
        setCompensationData(response.data);
        setUnavailableCompaniesMap(unmatchedByYearLookbackMap);
        setUnavailableTickersList(response.noDataAvailable);
        let highestYearLookback = '0';
        if (unmatchedByYearLookbackMap.size > 0) {
            highestYearLookback = Math.max(...unmatchedByYearLookbackMap.keys()).toString();
        }
        let unmatchedTickersByHighestLookback = unmatchedByYearLookbackMap.get(highestYearLookback);
        let unmatchedTickerCount = 0;
        if (unmatchedTickersByHighestLookback && unmatchedTickersByHighestLookback !== null && unmatchedTickersByHighestLookback.length > 0) {
            unmatchedTickerCount = unmatchedTickersByHighestLookback.length;
        }
        if (response.noDataAvailable !== null && response.noDataAvailable.length > 0) {
            unmatchedTickerCount = unmatchedTickerCount + response.noDataAvailable.length;
        }
        setNumberOfMatchedCompanies(companyTickerList.length - unmatchedTickerCount);
        setNumberOfUnmatchedCompanies(unmatchedTickerCount);
        setHasSearchResults(true);
        setShowAlert(unmatchedByYearLookbackMap.size > 0 || response.noDataAvailable.length > 0);
    };

    const hasAtleastOneFilterValueSelected = (filterList) => {
        if (filterList.length === 0) {
            return false
        }
        for (const filter of filterList) {
            if (filter.selectedValues.length > 0) {
                return true
            }
        }
        return false
    }

    const handleConflictingCompanies = (response) => {
        const conflictingCompanies = response.conflictingTickers;
        setConflictingCompanies(conflictingCompanies);
        setHasConflictingTicker(true);
        setShowTickerNameConflictModal(true);
    }

    const retrieveCompensationData = async (tickerList, templateId, numOfyears, sheetName = undefined) => {
        if (tickerList.length === 0 && !hasAtleastOneFilterValueSelected(templateFilters)) {
            setCompensationData([]);
            setUnavailableCompaniesMap([]);
            setYears(1);
            setNumberOfMatchedCompanies(0);
            setHasSearchResults(false);
            setShowAlert(false);
            setIsFormValid(false);
            return;
        }
        if (tickerList.length === 0 && hasAtleastOneFilterValueSelected(templateFilters)) {
            setTickers([]);
            setAreTickersRequired(false)
            setError(null);
            setShowError(false);
            setSearching(true);
            setYears(1);
            setSearchParams({ templateId });
        }
        let companyTickerList = tickerList;
        //check if the last value in the tickers array is empty or a space or a comma and remove it
        if (companyTickerList[companyTickerList.length - 1] === ""
            || companyTickerList[companyTickerList.length - 1] === " "
            || companyTickerList[companyTickerList.length - 1] === ",") {
            companyTickerList.pop();
        }
        companyTickerList = Array.isArray(companyTickerList) ? companyTickerList : [companyTickerList];
        companyTickerList = companyTickerList.map(ticker => formatTickers(ticker));

        setTickers(companyTickerList);
        setAreTickersRequired(true)
        setError(null);
        setShowError(false);
        setSearching(true);
        setSuccessfulDownload(false);
        setSelectedCompensationIds(new Set());

        const authToken = await getAuthToken();
        const queryStringParameters = {
            companyIds: companyTickerList.join(","),
            years: (numOfyears !== undefined && numOfyears !== null && numOfyears !== "") ? numOfyears : 0,
            templateId
        }
        if (templateFilters.length > 0) {
            templateFilters.forEach(filter => {
                if (filter.selectedValues.length > 0) {
                    queryStringParameters[filter.columnName] = filter.selectedValues.join(",");
                }
            });
        }
        if (selectedConflictingCompanyIds.length > 0) {
            queryStringParameters["selectedConflictingCompanyIds"] = selectedConflictingCompanyIds.join(",");
        }
        if (sheetName && sheetName !== SUMMARY) {
            queryStringParameters["sheetNames"] = sheetName;
        }
        API.get("AnnualCompensations",
            "/research-analysis/compensations",
            {
                headers: {
                    'Token': authToken
                },
                queryStringParameters
            }).then(response => {
                handleSettingCompensationData(response, companyTickerList);
                setHasSearchResults(true);
                setSearching(false);
                if (response.hasConflictingTickers) {
                    handleConflictingCompanies(response);
                }
            }
            ).catch(error => {
                setHasSearchResults(false);
                setCompensationData([]);
                setUnavailableCompaniesMap(new Map());
                setNumberOfMatchedCompanies(0);
                setSearching(false);
                setShowError(true);
                setSelectedSheetName(SUMMARY);
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred while retrieving compensation data. Please try again later.");
                    console.error("An error occurred while retrieving compensation data.", error);
                }
            });
    }

    const handleSearchBulkTickers = async (unParsedTickers) => {
        if (formRef.current !== null) {
            formRef.current.reportValidity();
            formRef.current.classList.add("was-validated");
        }
        if (unParsedTickers === undefined || unParsedTickers === null || unParsedTickers === "" || unParsedTickers.length === 0) {
            handleParsingTickers([]);
            setIsFormValid(false);
            return;
        }
        if (formRef.current !== null) {
            formRef.current.classList.remove("was-validated");
        }
        const parsedTickers = handleParsingTickers(unParsedTickers);
        await retrieveCompensationData(parsedTickers, selectedTemplateId, years, selectedSheetName);
    }

    const checkTickersRequired = (tickers, filters) => {
        if (tickers.length > 0) {
            return true
        }
        if (tickers.length === 0 && (!filters || filters.length === 0)) {
            return false
        } else if (tickers.length === 0 && filters.length > 0 && filters.some(filter => filter.selectedValues.length > 0)) {
            return true
        } else {
            return false
        }
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!checkTickersRequired(tickers, templateFilters)) {
            setAreTickersRequired(false);
        } else {
            setAreTickersRequired(true);
        }
        if (form.checkValidity() === false || showWarningMessageModal) {
            e.stopPropagation();
        } else {
            await retrieveCompensationData(tickers, selectedTemplateId, years, selectedSheetName);
        }
        setIsFormValid(true);
    }

    const handleSelectedRowsChange = (selectedRowIds) => {
        setSelectedCompensationIds(selectedRowIds);
        if (selectedRowIds.size > 0) {
            setDownloadEnabled(true);
        } else {
            setDownloadEnabled(false);
        }
    }

    const handleSettingTemplate = (template) => {
        setSelectedTemplate(template);
        handleSettingGridColumns(template);
        handleSettingFilters(template.summaryColDefinitions);
    }

    const getTemplate = async (templateId) => {
        setLoadingStatus(true);
        const authToken = await getAuthToken();
        API.get("AnnualCompensations", '/research-analysis/template-summary/' + templateId, {
            headers: {
                'Token': authToken
            }
        })
            .then((response) => {
                setLoadingStatus(false);
                handleSettingTemplate(response);
            })
            .catch((error) => {
                setSelectedTemplate(null);
                setLoadingStatus(false);
                setShowError(true);
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred while template data. Please try again later.");

                }
            });
    }

    const handleSettingSheetTemplate = (template) => {
        setGridColumns(template.columnDefinitions);
        handleSettingFilters(template.columnDefinitions);
    }

    const getSheetTemplate = async (templateId, sheetName) => {
        const authToken = await getAuthToken();
        API.get("AnnualCompensations", `/research-analysis/template-sheet/${templateId}/${encodeURIComponent(sheetName)}`, {
            headers: {
                'Token': authToken
            }
        }).then((response) => {
            setLoadingStatus(false);
            handleSettingSheetTemplate(response);
        }).catch((error) => {
            setSelectedTemplate(null);
            setShowError(true);
            if (error?.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError("An error occurred while retrieving sheet template data.");
                console.error("An error occurred while retrieving sheet template data.", error);
            }
        });
    }

    const handleComponentReset = () => {
        setSelectedTemplate(null);
        setSelectedTemplateId("");
        setGridColumns([]);
        setTemplateFilters([]);
        setYears(1);
        setHasSearchResults(false);
        setCompensationData([]);
        setUnavailableCompaniesMap([]);
        setNumberOfMatchedCompanies(0);
        setSelectedCompensationIds(new Set());
        setIsFormValid(false);
        setShowAlert(false);
        setError(null);
        setShowError(false);
        setDownloadEnabled(false);
        setSuccessfulDownload(false);
        setSelectedSheetName(SUMMARY);
        setShowWarningMessageModal(false);
    }

    const handleTemplateChange = (e) => {
        handleComponentReset();
        if (e.target.value === "") {
            setSearchParams({ ...searchParams, templateId: "" });
            return;
        }
        const updateTemplateId = e.target.value;
        setSelectedTemplateId(updateTemplateId);
        setSearchParams({ ...searchParams, templateId: updateTemplateId });
        getTemplate(updateTemplateId);
    }

    const handleParsingTickers = (tickers) => {
        if (!tickers || tickers.length === 0 || tickers === "") {
            setSearchParams({ templateId: searchParamTemplateId, years: searchParamsYears || 1 });
            return [];
        }

        if (Array.isArray(tickers)) {
            return handleArrayTickers(tickers);
        }

        if (typeof tickers === 'string' && tickers.includes(",")) {
            return handleStringTickers(tickers);
        }

        setSearchParams({ templateId: searchParamTemplateId, tickers: tickers.toUpperCase(), years: searchParamsYears || 1 });
        return [tickers.toUpperCase()];
    }

    const handleArrayTickers = (tickers) => {
        const uniqueTickers = [...new Set(tickers)];
        const formattedTickers = uniqueTickers.map(ticker => formatTickers(ticker));
        if (formattedTickers && formattedTickers.length > 0) {
            const flattenedTickers = [...new Set(formattedTickers.flat())]
            setSearchParams({ templateId: searchParamTemplateId, tickers: formattedTickers.join(","), years: searchParamsYears || 1 });
            return flattenedTickers;
        } else {
            setSearchParams({ templateId: searchParamTemplateId, tickers, years: searchParamsYears || 1 });
        }
        return [];
    }

    const handleStringTickers = (tickers) => {
        const rawTickers = tickers.split(",").map(ticker => ticker.trim().toUpperCase());
        const uniqueTickers = [...new Set(rawTickers)];
        const formattedTickers = uniqueTickers.map(ticker => formatTickers(ticker));
        if (formattedTickers && formattedTickers.length > 0) {
            setSearchParams({ templateId: searchParamTemplateId, tickers: formattedTickers.join(","), years: searchParamsYears || 1 });
            return formattedTickers;
        } else {
            setSearchParams({ templateId: searchParamTemplateId, tickers, years: searchParamsYears || 1 });
        }
        return [];
    }

    const handleCloseCompaniesModal = () => {
        setShowCompaniesModal(false);
    }

    const handleConflictingCompanyIdSelection = (companyIds) => {
        const selectedConflictingCompanies = conflictingCompanies.filter(company => companyIds.includes(company.exchangeTicker));
        const selectedExchangeTickers = selectedConflictingCompanies.map(company => company.exchangeTicker);
        setSelectedConflictingCompanyIds(selectedExchangeTickers);
    }

    const getSheetData = async (sheetName) => {
        await getSheetTemplate(selectedTemplateId, sheetName);
        await retrieveCompensationData(tickers, selectedTemplateId, years, sheetName);
    }

    const handleRetrieveSheetData = async (sheetName) => {
        setSelectedSheetName(sheetName);
        if (sheetName === SUMMARY) {
            handleSettingTemplate(selectedTemplate);
            await retrieveCompensationData(tickers, selectedTemplateId, years);
        } else if (tickers.length > 100) {
            setShowWarningMessageModal(true);
        } else {
            await getSheetData(sheetName);
        }
    }

    const continueSheetSearch = async () => {
        setShowWarningMessageModal(false);
        await getSheetData(selectedSheetName);
    }

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (selectedCompensationIds.size > compensationData.length) {
                setSelectedCompensationIds(new Set());
            }
        }
        return () => isSubscribed = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compensationData, selectedCompensationIds]);

    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            setDownloadEnabled(false);
        }
        return () => isSubscribed = false;
    }, [compensationData]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setLoadingStatus(true);
            const fetchTemplate = async (templateId) => {
                setLoadingStatus(true);
                const authToken = await getAuthToken();
                API.get("AnnualCompensations", '/research-analysis/template-summary/' + templateId, {
                    headers: {
                        'Token': authToken
                    }
                })
                    .then((response) => {
                        setLoadingStatus(false);
                        if (response === null || response === undefined || response === "") {
                            setSelectedTemplateId("");
                            setError("An error occurred while retrieving template. Please try again later.");
                            setShowError(true);
                            return;
                        }
                        setSelectedTemplateId(templateId);
                        handleSettingTemplate(response);
                    })
                    .catch((error) => {
                        setSelectedTemplate(null);
                        setLoadingStatus(false);
                        setShowError(true);
                        if (error?.response?.data?.message) {
                            setError(error.response.data.message);
                        } else {
                            setError("An error occurred while template data. Please try again later.");
                        }
                    });
            }
            const retrieveTemplateNames = async () => {
                const authToken = await getAuthToken();
                API.get("AnnualCompensations",
                    "/research-analysis/template-names",
                    {
                        headers: {
                            'Token': authToken
                        }
                    }).then(response => {
                        setTemplateNameList(response.data);
                        setLoadingStatus(false);
                        if (searchParamTemplateId && searchParamTemplateId !== "") {
                            fetchTemplate(searchParamTemplateId);
                        }
                    }
                    ).catch(error => {
                        setLoadingStatus(false);
                        setShowError(true);
                        if (error?.response?.data?.message) {
                            setError(error.response.data.message);
                        } else {
                            setError("An error occured while retrieving template data. Please try again later.");
                        }
                    });
            }
            handleComponentReset();
            retrieveTemplateNames();
            let tickersFromUrl = [];
            if (searchParamsTickers
                && searchParamsTickers !== "") {
                tickersFromUrl = searchParamsTickers.split(",");
                setTickers(tickersFromUrl);
            }
            if (searchParamsYears
                && searchParamsYears !== "") {
                setYears(searchParamsYears);
            }
            if (tickersFromUrl.length > 0 && searchParamTemplateId !== "") {
                retrieveCompensationData(tickersFromUrl, searchParamTemplateId, searchParamsYears || 1, selectedSheetName);
            }
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            if (searchParamTemplateId === ""
                || searchParamTemplateId === undefined
                || searchParamTemplateId === null) {
                handleComponentReset();
            }
            if (searchParamsTickers === ""
                || searchParamsTickers === undefined
                || searchParamsTickers === null) {
                setTickers([]);
            }
            if (searchParamsYears === ""
                || searchParamsYears === undefined
                || searchParamsYears === null) {
                setYears(1);
            }
        }
        return () => { isMounted = false };
    }, [searchParamsTickers, searchParamTemplateId, searchParamsYears]);

    return (
        <>
            {!loading ?
                <div className="explore-data">
                    {selectedTemplate && <TemplateCompaniesGridModal
                        showModal={showCompaniesModal}
                        onCloseModal={handleCloseCompaniesModal}
                        templateId={selectedTemplateId}
                        templateName={selectedTemplate.templateName}
                        onSearch={handleSearchBulkTickers}
                    />}
                    {hasConflictingTicker && <TickerNameConflictModal
                        isOpen={showTickerNameConflictModal}
                        onClose={() => setShowTickerNameConflictModal(false)}
                        conflictingCompanies={conflictingCompanies}
                        selectedCompanyIds={selectedConflictingCompanyIds}
                        onSelectCompanyId={(companyIds) => handleConflictingCompanyIdSelection(companyIds)}
                        onSearch={() => retrieveCompensationData(tickers, selectedTemplateId, years)}
                    />
                    }
                    <div className="explore-data__inputs">
                        <Form noValidate validated={isFormValid} ref={formRef} onSubmit={handleSearch} id="explore-data">
                            <Row>
                                <Col md={3} lg={3}>
                                    <Form.Group controlId="templates">
                                        <Form.Label>
                                            <span style={{ marginRight: "0.25rem" }}>Analysis Type</span>
                                            <ToolTipWrapper
                                                id={"analysis-type-tooltip"}
                                                placement={"top"}
                                                text={"Select the analysis type you want to search data for"}
                                                element={
                                                    <i className="fa fa-question-circle"></i>
                                                } />
                                        </Form.Label>
                                        <Form.Select required value={selectedTemplateId} onChange={handleTemplateChange} disabled={searching}>
                                            <option value="">Select analysis type</option>
                                            {templateNameList.length > 0 && templateNameList.map(template => {
                                                return <option key={template.templateId} value={template.templateId}>{template.templateName}</option>
                                            })}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a analysis type.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col md={3} lg={3}>
                                    {selectedTemplateId &&
                                        <Button
                                            variant="secondary"
                                            onClick={() => setShowCompaniesModal(true)}
                                            className="all-companies-btn"
                                            aria-label="Show all companies"
                                        >
                                            <i className="fa fa-list"/><span style={{marginLeft: "0.5rem"}}>View Companies</span>
                                        </Button>
                                    }
                                </Col>
                            </Row>
                            <BaseCompensationDataInputs
                                tickers={tickers}
                                setSearchParams={setSearchParams}
                                selectedTemplateId={selectedTemplateId}
                                areTickersRequired={areTickersRequired}
                                handleSettingTickers={(tickers) => {
                                    const parsedTickers = handleParsingTickers(tickers)
                                    setTickers(parsedTickers);
                                }}
                                handleSearch={handleSearchBulkTickers}
                                searching={searching}
                                years={years}
                                setYears={(year) => {
                                    setYears(year);
                                    setSearchParams({ templateId: searchParamTemplateId, tickers: searchParamsTickers, years: year });
                                }}>
                            </BaseCompensationDataInputs>
                            <TemplateFilters
                                disabled={searching}
                                filters={templateFilters}
                                handleFilteringOptions={handleFilteringOptions}
                                handleFilterUpdate={handleFilterUpdate}
                                findMatchingOptions={findMatchingOptions}
                                toggleMultiSelectedOptions={toggleMultiSelectedOptions}>
                            </TemplateFilters>
                            <WarningMessageModal
                                isOpen={showWarningMessageModal}
                                onClose={() => setShowWarningMessageModal(false)}
                                onContinue={continueSheetSearch}
                                disabled={searching}
                            />
                            <ToolbarActions
                                selectedTemplate={selectedTemplate}
                                searching={searching}
                                handleError={(error) => {
                                    setShowError(true);
                                    setError(error);
                                }}
                                selecteCompIds={selectedCompensationIds}
                                hasSearchResults={hasSearchResults}
                                downloadEnabled={downloadEnabled}
                                successfulDownload={(success) => setSuccessfulDownload(success)}></ToolbarActions>
                            {!searching ? <ResultIndicators
                                compensationData={compensationData}
                                unMatchedCompanies={numberOfUnmatchedCompanies}
                                numberOfMatchedCompanies={numberOfMatchedCompanies}
                                numberOfUnmatchedCompanies={numberOfUnmatchedCompanies}
                                unavailableDataMap={unavailableCompaniesMap}
                                noDataAvailableTickers={unavailableTickersList}
                                error={error}
                                showError={showError}
                                hasSearchResults={hasSearchResults}
                                showAlert={showAlert}
                                setShowAlert={setShowAlert}
                                successfulDownload={successfulDownload}
                                setSuccessfulDownload={(success) => setSuccessfulDownload(success)}>
                            </ResultIndicators> : ""}
                            {!searching && selectedTemplate && compensationData.length > 0 && <div className="data-grid">
                                <CompensationGrid
                                    columnDefinitions={gridColumns}
                                    compensations={compensationData}
                                    rowKeyGetter={(row) => row.uniqueId}
                                    selectedRows={selectedCompensationIds}
                                    onSelectedRowsChange={(selectedRows) => handleSelectedRowsChange(selectedRows)}
                                    templateTitle={selectedTemplate.templateName}
                                    allowGridToExcel={selectedTemplate.allowGridToExcel}
                                    sheetNames={selectedTemplate.sheetNames}
                                    selectedSheetName={selectedSheetName}
                                    handleRetrieveSheetData={(sheetName) => handleRetrieveSheetData(sheetName)}
                                    isGridExpanded={gridExpansionStatus}
                                    handleGridResize={(isExpanded) => setGridExpansionStatus(isExpanded)}
                                />
                            </div>}
                        </Form>
                    </div >
                </div >
                : ""
            }
        </>
    );
}

export default ExploreCompensationData;
