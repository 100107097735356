import { API, Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import ClientNameInputComponent from '../../../CommonComponents/DataExportConfirmationModal/ClientNameInputComponent/ClientNameInputComponent';
import ClientOwnerInputComponent from '../../../CommonComponents/DataExportConfirmationModal/ClientOwnerInputComponent/ClientOwnerInputComponent';
import { billableTypes } from '../../../Constants';
import { getAuthToken } from '../../../utils/AuthUtils';
import './styles.scss';

import PropTypes from 'prop-types';

const EditBillingDataModal = ({ show, onClose, data, onRecordUpdated }) => {
    const [billingRecord, setBillingRecord] = useState(data);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [error, setError] = useState("");
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);
    const [success, setSuccess] = useState("");
    const [successDisplayStatus, setSuccessDisplayStatus] = useState(false);
    const [clientNameInvalid, setClientNameInvalid] = useState(false);
    const [clientOwnerNameInvalid, setClientOwnerNameInvalid] = useState(false);
    const [clientOwnerNameErrorMessage, setClientOwnerNameErrorMessage] = useState("");
    const [missingAdminNotes, setMissingAdminNotes] = useState(false);

    const handleClose = () => {
        setSuccessDisplayStatus(false);
        setErrorDisplayStatus(false);
        setError("");
        setSuccess("");
        onClose();
    }
    const handleSetClientName = (clientName) => {
        setBillingRecord({ ...billingRecord, clientName: clientName })
    }

    const handleSetClientOwner = (clientOwnerName, clientOwnerUserId, clientOwnerEmail) => {
        if (clientOwnerName && clientOwnerUserId && clientOwnerEmail) {
            setBillingRecord({ ...billingRecord, clientOwnerName: clientOwnerName, clientOwnerEmail: clientOwnerEmail, clientOwnerUserId: clientOwnerUserId })
        }
    }

    const handleSetClientOwnerLookupTerm = (clientOwnerNameLookupTerm) => {
        setBillingRecord({ ...billingRecord, clientOwnerName: clientOwnerNameLookupTerm })
    }

    const handleSetSelectedClientOwnerNameRecord = (clientOwnerNameRecord) => {
        if (clientOwnerNameRecord.userName && clientOwnerNameRecord.userEmail && clientOwnerNameRecord.userId) {
            setBillingRecord({ ...billingRecord, clientOwnerName: clientOwnerNameRecord.userName, clientOwnerEmail: clientOwnerNameRecord.userEmail, clientOwnerUserId: clientOwnerNameRecord.userId })
        }
    }

    const handlSetBillableType = (billableType) => {
        setBillingRecord({ ...billingRecord, billableType: billableType })
    }

    const handleChangeNumCompaniesAccessed = (number) => {
        setBillingRecord({ ...billingRecord, numberOfCompaniesAccessed: number })
    }

    const handleSetAdminNotes = (adminNotes) => {
        setBillingRecord({ ...billingRecord, adminNotes: adminNotes })
        if (adminNotes.length > 2) {
            setMissingAdminNotes(false);
        }
    }

    const addModifiedInfo = async () => {
        const userInfo = await Auth.currentUserInfo();
        const currentDate = new Date().toDateString();
        setBillingRecord({ ...billingRecord, modifiedBy: userInfo.username, modifiedOn: currentDate })
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const token = await getAuthToken();
        if (!billingRecord.adminNotes || billingRecord.adminNotes.length < 3) {
            setMissingAdminNotes(true);
            return
        }

        if (billingRecord.billableType === billableTypes.billable && (!billingRecord.clientName || billingRecord.clientName.length < 2)) {
            setClientNameInvalid(true);
            return;
        }
        if (billingRecord.billableType === billableTypes.billable && (!billingRecord.clientOwnerName || !billingRecord.clientOwnerEmail)) {
            setClientOwnerNameInvalid(true);
            setClientOwnerNameErrorMessage("Client Owner not found");
            return;
        }

        try {
            await addModifiedInfo();
            setLoadingStatus(true);
            await API.put("AnnualCompensations", "/billing-data", {
                body: billingRecord,
                headers: {
                    "Content-Type": "application/json",
                    "Token": token
                }
            }).then(response => {
                setLoadingStatus(false);
                setSuccessDisplayStatus(true);
                setSuccess(response.message);
                onRecordUpdated();
                handleClose();
            }).catch(error => {
                console.log(error);
                setLoadingStatus(false);
                setErrorDisplayStatus(true);
                if (error?.response?.data?.message) {
                    setError(error.response.data.message);
                } else {
                    setError("An error occurred while modifying template data. Please try again later.");
                }
            })
        } catch (error) {
            console.log(error);
            setLoadingStatus(false);
            setErrorDisplayStatus(true);
            setError("Exception occurred updating billing record")
        }
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} size='xl' centered>
                <div className="edit-billing-record-container">
                    <Form noValidate onSubmit={(e) => handleUpdate(e)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Billing Record</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert show={errorDisplayStatus} variant="danger" dismissible onClose={() => setErrorDisplayStatus(false)}>
                                {error}
                            </Alert>
                            <Alert show={successDisplayStatus} variant="success" dismissible onClose={() => setSuccessDisplayStatus(false)}>
                                {success}
                            </Alert>
                            {(loadingStatus && billingRecord) ? <div className="loading">Loading...<output><Spinner as="span" animation="grow" size="sm" aria-hidden="true" /></output></div> :
                                <div className="edit-billing-record-form">
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label>Full Date Accessed</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={billingRecord.fullDateAccessed}
                                                readOnly
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label>Download User Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={billingRecord.userEmail}
                                                readOnly
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <ClientNameInputComponent
                                                clientName={billingRecord.clientName}
                                                setClientName={handleSetClientName}
                                                clientNameInvalid={clientNameInvalid}
                                                setClientNameInvalid={setClientNameInvalid}
                                                handleSetClientOwner={handleSetClientOwner}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <ClientOwnerInputComponent
                                                clientOwnerNameLookupTerm={billingRecord.clientOwnerName}
                                                setClientOwnerNameLookupTerm={handleSetClientOwnerLookupTerm}
                                                setSelectedClientOwnerUserRecord={handleSetSelectedClientOwnerNameRecord}
                                                clientOwnerNameInvalid={clientOwnerNameInvalid}
                                                setClientOwnerNameInvalid={setClientOwnerNameInvalid}
                                                clientOwnerNameErrorMessage={clientOwnerNameErrorMessage}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label>Billable Type</Form.Label>
                                            <Form.Control as="select" value={billingRecord.billableType} onChange={(e) => handlSetBillableType(e.target.value)}>
                                                <option value={billableTypes.billable}>Billable</option>
                                                <option value={billableTypes.nonBillableRFP}>Non Billable: RFP</option>
                                                <option value={billableTypes.nonBillableOther}>Non Billable: Other</option>
                                            </Form.Control>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label>No. Companies</Form.Label>
                                            <Form.Control
                                                type="number"
                                                value={billingRecord.numberOfCompaniesAccessed}
                                                onChange={e => {
                                                    const value = Number(e.target.value);
                                                    if (value < 1) {
                                                        return;
                                                    }
                                                    handleChangeNumCompaniesAccessed(value);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Form.Group controlId="adminNotes">
                                            <Form.Label>Admin Notes</Form.Label>
                                            <Form.Control
                                                type="text"
                                                as="textarea"
                                                value={billingRecord.adminNotes}
                                                onChange={(e) => handleSetAdminNotes(e.target.value)}
                                                isInvalid={missingAdminNotes}
                                            />
                                            <Form.Control.Feedback type="invalid">Please enter admin notes</Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="primary" disabled={loadingStatus} type="submit">Save</Button>
                        </Modal.Footer>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

EditBillingDataModal.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.object,
    onRecordUpdated: PropTypes.func
};

export default EditBillingDataModal;