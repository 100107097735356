import { Analytics } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import PaginationComponent from '../PaginationComponent/PaginationComponent';
import './styles.scss';

const SimpleGridComponent = ({ data, columns, selectedSize, selectedCurrentPage, onCurrentPageSelect, onRowSelect, itemsName, pageSize = 10, enableRowSelection = true, showHeaderFilters = true, enablePagination = true, uniqueKey = "id" }) => {
    const [sortConfig, setSortConfig] = useState(null);
    const [filterConfigMap, setFilterConfigMap] = useState({});
    const [currentPage, setCurrentPage] = useState(1 || selectedCurrentPage);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedPageSize, setSelectedPageSize] = useState(selectedSize || pageSize);
    const [paginatedData, setPaginatedData] = useState([]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const handleFilter = (key, value, exactSearch = false) => {
        Analytics.record({
            name: 'Filter',
            attributes: {
                key: key,
                value: value,
                itemType: itemsName
            }
        });
        if (value === '') {
            const filterConfigMapCopy = { ...filterConfigMap };
            delete filterConfigMapCopy[key];
            setFilterConfigMap(filterConfigMapCopy);
        } else {
            const filterValueConfig = { value, exactSearch };
            setFilterConfigMap((prev) => ({ ...prev, [key]: filterValueConfig }));
        }
        setCurrentPage(1);
        onCurrentPageSelect(1);
    };

    const sortedData = useMemo(() => {
        let sortedData = [...data];
        if (sortConfig !== null) {
            sortedData.sort((a, b) => {
                const aVal = a[sortConfig.key] ? a[sortConfig.key] : '';
                const bVal = b[sortConfig.key] ? b[sortConfig.key] : '';
                if (aVal < bVal) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aVal > bVal) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedData;
    }, [data, sortConfig]);

    const filteredData = useMemo(() => {
        let filteredData = [...sortedData];
        if (Object.keys(filterConfigMap).length > 0) {
            filteredData = filteredData.filter((row) => {
                let isMatch = true;
                Object.keys(filterConfigMap).forEach((key) => {
                    const valueConfig = filterConfigMap[key];
                    const searchValue = valueConfig.value.toLowerCase();
                    const rowValue = row[key] ? row[key].toString().toLowerCase() : '';
                    if (valueConfig.exactSearch) {
                        if (searchValue !== rowValue) {
                            isMatch = false;
                        }
                    } else if (!rowValue.includes(searchValue)) {
                        isMatch = false;
                    }
                });
                return isMatch;
            });
        }
        return filteredData;
    }, [sortedData, filterConfigMap]);

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allRows = paginatedData.map((row) => row[uniqueKey]);
            setSelectedRows([...new Set([...selectedRows, ...allRows])]);
        } else {
            setSelectedRows([]);
        }
    };

    const isRowSelected = (rowValue) => selectedRows.includes(rowValue);

    const createFilterElement = (column) => {
        if (column.filterType === 'dropdown') {
            return (
                <select className="column-filter" style={{ padding: "0.25rem", overflow: "visible" }} onChange={(e) => handleFilter(column.key, e.target.value, true)}>
                    <option value=''>All</option>
                    {column.filterOptions.map((option) => (
                        <option key={option.key} title={option.key} value={option.value}>{option.key}</option>
                    ))}
                </select>
            );
        } else {
            return <input type="text" className="column-filter" placeholder='Type to filter' onChange={(e) => handleFilter(column.key, e.target.value)} />;
        }
    };

    const determineRowIndex = (currentPage, selectedPageSize, rowIndex) => {
        return (currentPage - 1) * selectedPageSize + rowIndex;
    }

    const calculateMaxColWidth = () => {
        let maxColWidth = 0;
        const totalCols = columns.length;
        if (totalCols > 0 && totalCols <= 4) {
            maxColWidth = 100 / totalCols;
        } else if (totalCols > 4 && totalCols <= 8) {
            maxColWidth = 100 / (totalCols - 1);
        } else if (totalCols > 8 && totalCols <= 12) {
            maxColWidth = 100 / (totalCols - 2);
        } else if (totalCols > 12 && totalCols <= 16) {
            maxColWidth = 100 / (totalCols - 3);
        } else {
            maxColWidth = 100 / (totalCols - 4);
        }
        return `${maxColWidth}rem`;
    }

    useEffect(() => {
        if (enableRowSelection) {
            onRowSelect(selectedRows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows, enableRowSelection]);


    useEffect(() => {
        if (!showHeaderFilters) {
            setFilterConfigMap({});
        }
    }, [showHeaderFilters]);

    const onChangePaginatedData = useCallback((paginatedData) => {
        setPaginatedData(paginatedData);
    }, []);

    return (
        <div className='grid-component'>
            <div className="grid-table d-flex justify-content-start mb-2">
                <Table striped bordered hover responsive={true}>
                    <thead>
                        <tr>
                            <th className="select-row-checkbox-cell" style={enableRowSelection ? { display: 'table-cell' } : { display: 'none' }}>
                                <input className='grid-checkbox' type="checkbox" onChange={handleSelectAll} checked={paginatedData.length > 0 ? paginatedData.every((row) => isRowSelected(row[uniqueKey])) : false} />
                            </th>
                            {columns.map((column) => (
                                <th key={column.key} >
                                    {column.sortable ?
                                        <div className="column-header sortable" onClick={() => handleSort(column.key)}>
                                            <div className='column-title'>
                                                <span className='column-name'>{column.name}</span>
                                                {column.tooltip && <span>{column.tooltip}</span>}
                                            </div>
                                            {column.sortable ? (sortConfig && sortConfig.key === column.key) ? (
                                                sortConfig.direction === 'ascending' ?
                                                    <i className="fas fa-sort-up"></i> : <i className="fas fa-sort-down"></i>
                                            ) : (
                                                <i className="fas fa-sort"></i>
                                            )
                                                : null}
                                        </div>
                                        :
                                        <div className="column-header">
                                            <div className='column-title'>
                                                <span className='column-name'>{column.name}</span>
                                                {column.tooltip && <span>{column.tooltip}</span>}
                                            </div>
                                        </div>
                                    }
                                </th>
                            ))}
                        </tr>
                        {showHeaderFilters && <tr>
                            {enableRowSelection && <th></th>}
                            {columns.map((column) => (
                                column.filterable ? <th key={column.key}>
                                    {createFilterElement(column)}
                                </th> : <th key={column.key}></th>
                            ))}
                        </tr>}
                    </thead>
                    <tbody>
                        {paginatedData.map((row, rowIndex) => (
                            <tr key={row[uniqueKey] || rowIndex}>
                                <td className="select-row-checkbox-cell" style={enableRowSelection ? { display: 'table-cell' } : { display: 'none' }}>
                                    <input
                                        className='grid-checkbox'
                                        type="checkbox"
                                        checked={isRowSelected(row[uniqueKey])}
                                        onChange={() => setSelectedRows((prev) => (isRowSelected(row[uniqueKey]) ? prev.filter((uniqueKeyValue) => uniqueKeyValue !== row[uniqueKey]) : [...prev, row[uniqueKey]]))}
                                    />
                                </td>
                                {columns.map((column) => (
                                    <td
                                        key={column.key}
                                        style={{ maxWidth: calculateMaxColWidth() }}
                                        title={row[column.key]}>{column.formatter ? column.formatter(row[column.key], row, determineRowIndex(currentPage, selectedPageSize, rowIndex), column) : row[column.key]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className="grid-pagination" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='selected-items-count'>
                    <span >{selectedRows.length} {itemsName} selected</span>
                </div>
                {enablePagination &&
                    <PaginationComponent
                        filteredData={filteredData}
                        selectedCurrentPage={selectedCurrentPage}
                        onChangePaginatedData={onChangePaginatedData}
                        onCurrentPageSelect={(pageNumber) => onCurrentPageSelect(pageNumber)}
                        itemsName={itemsName}
                        selectedPageSize={selectedPageSize}
                        onChangeSelectedPageSize={(pageSize) => setSelectedPageSize(pageSize)}
                    />}
            </div>
        </div>
    );
};

export default SimpleGridComponent;

SimpleGridComponent.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    selectedSize: PropTypes.number,
    selectedCurrentPage: PropTypes.number,
    onCurrentPageSelect: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired,
    itemsName: PropTypes.string.isRequired,
    pageSize: PropTypes.number,
    enableRowSelection: PropTypes.bool,
    showHeaderFilters: PropTypes.bool,
    enablePagination: PropTypes.bool,
    uniqueKey: PropTypes.string
}