import PropTypes from "prop-types";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SimpleGridComponent from "../../SimpleGridComponent/SimpleGridComponent";
import DataRequestLinksComponent from "../DataRequestLinksComponent/DataRequestLinksComponent";

import './styles.scss';

const ViewDataRequestModal = ({ show, handleClose, data }) => {
    const columns = [
        {
            "key": "companyId",
            "name": "Company ID",
            "sortable": true,
            "filterable": true,
        },
        {
            "key": "companyName",
            "name": "Company Name",
            "sortable": true,
            "filterable": true
        },
        {
            "key": "templateName",
            "name": "Template Name",
            "sortable": true,
            "filterable": true,
        },
        {
            "key": "requestStatus",
            "name": "Request Status",
            "sortable": true,
            "filterable": true
        }
    ];

    return (
        <Modal className="view-data-request-modal" show={show} onHide={handleClose} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>Data Request Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="data-request-note">
                    <p style={{ color: "red", fontStyle: "italic" }}>If you need to make changes to this data request, please reach out to the data team at <a href="mailto:data@fwcook.com">data@fwcook.com </a> with the request ID: {data.id}</p>
                </div>
                <div className="data-request-details">
                    <div className="data-request-details-header">
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="clientCompanyId">
                                    <Form.Label>Client Company ID</Form.Label>
                                    <Form.Control type="text" value={data.clientCompanyId} readOnly />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="clientName">
                                    <Form.Label>Client Name</Form.Label>
                                    <Form.Control type="text" value={data.clientName} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="deadline">
                                    <Form.Label>Deadline</Form.Label>
                                    <Form.Control type="text" value={data.deadline} readOnly />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="dateRequested">
                                    <Form.Label>Date Requested</Form.Label>
                                    <Form.Control type="text" value={data.dateRequested} readOnly />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    {data?.companiesRequested && <div className="data-request-details-body" style={{ marginTop: "1rem" }}>
                        <DataRequestLinksComponent companyRequests={data.companiesRequested} />
                        <h4>COMPANIES / DATA REQUESTED</h4>
                        <div className="companies-requested">
                            <SimpleGridComponent
                                columns={columns}
                                data={data.companiesRequested}
                                enableRowSelection={false}
                                selectedSize={5}
                                selectedCurrentPage={1}
                                showHeaderFilters={false}
                                itemsName="companies"
                            />
                        </div>
                    </div>}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ViewDataRequestModal;

ViewDataRequestModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}