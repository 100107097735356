import React, { useEffect, useState } from 'react'
import { getAuthToken } from '../../utils/AuthUtils';
import { API, Auth } from 'aws-amplify';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { exportToCSV } from '../../utils/exportUtils';
import DataRequestStatusDropDown from '../DataRequestStatusDropDown/DataRequestStatusDropDown';
import GenericGridComponent from '../../GenericGridComponent/GenericGridComponent'
import EditDataRequestModal from '../EditDataRequestModal/EditDataRequestModal';
import './styles.scss';

export default function AllDataRequests() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [error, setError] = useState(null);
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);

    const [selectedRequestStatus, setSelectedRequestStatus] = useState("PENDING"); // Default to "PENDING"

    const [showEditDataRequestModal, setShowEditDataRequestModal] = useState(false);

    const [selectedDataRequestId, setSelectedDataRequestId] = useState(null);

    const [isAdminUser, setIsAdminUser] = useState(false);

    const [isAutoRefreshEnabled, setIsAutoRefreshEnabled] = useState(false);
    const [autoRefreshTimer, setAutoRefreshTimer] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const actions = (_, dataRequest) => {
        return (
            <div>
                <Button variant="primary edit-request-btn" size="sm" disabled={!isAdminUser} className="mr-2" onClick={() => handleEditDataRequest(dataRequest)}>
                    <i className="fa fa-edit"></i>
                </Button>
                {dataRequest.flag ? <i className="fa fa-flag" style={{ color: "red" }}></i> : <i className="fa fa-flag" style={{ color: "grey" }}></i>}
            </div>
        );
    };

    const columns = [{
        "key": "id",
        "name": "Data Request ID",
        "sortable": false,
        "filterable": true
    },
    {
        "key": "clientCompanyId",
        "name": "Client Company ID",
        "sortable": true,
        "filterable": true
    },
    {
        "key": "clientName",
        "name": "Client Name",
        "sortable": true,
        "filterable": true
    }, {
        "key": "templateTypes",
        "name": "Analysis Types",
        "sortable": true,
        "filterable": true
    },
    {
        "key": "requestorEmail",
        "name": "Requestor Email",
        "sortable": true,
        "filterable": true
    },
    {
        "key": "deadline",
        "name": "Deadline",
        "sortable": true,
        "filterable": true
    },
    {
        "key": "dateRequested",
        "name": "Date Requested",
        "sortable": true,
        "filterable": true,
        "headerCellClass": 'filter-cell'
    },
    {
        "key": "actions",
        "sortable": false,
        "filterable": false,
        "formatter": ({ column, row }) => {
            return (
                actions(column, row)
            );
        }
    }];

    const handleEditDataRequest = (dataRequest) => {
        if (!isAdminUser) {
            return;
        }
        setSelectedDataRequestId(dataRequest.id);
        setShowEditDataRequestModal(true);
    }

    const handleSetData = (dataRequests) => {
        for (const dataRequest of dataRequests) {
            const templateTypes = new Set();
            for (const companyRequest of dataRequest.companiesRequested) {
                templateTypes.add(companyRequest.templateName);
            }
            dataRequest.templateTypes = Array.from(templateTypes).join(", ");
        }
        setData(dataRequests);
    }

    const getData = async (requestStatus) => {
        setLoading(true);
        setErrorDisplayStatus(false);
        setError("");

        const authToken = await getAuthToken();

        const basePath = '/data-requests';
        const url = requestStatus ? `${basePath}?status=${requestStatus}` : basePath;

        API.get('AnnualCompensations', url, {
            headers: {
                "Content-Type": "application/json",
                "Token": authToken
            }
        }).then(response => {
            handleSetData(response);
            setLoading(false);
        }
        ).catch(error => {
            setError(error);
            setErrorDisplayStatus(true);
            setLoading(false);
        });
    }

    const getAdminUser = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        setIsAdminUser(groups.includes("FWC_Admin"));
    }

    const createDataRequestRows = (data) => {
        return data.map(dataRequest => {
            const companyDataRequests = dataRequest.companiesRequested.map(companyRequest => {
                return {
                    "Company Code (CIK or System Generated)": companyRequest.companyCode,
                    "Company ID / Exchange:Ticker": companyRequest.companyId,
                    "Company Name": companyRequest.companyName,
                    "Template Name": companyRequest.templateName,
                    "Expected Delivery Date": dataRequest.deadline,
                    "Is Latest FYE Data Available?": companyRequest.isLatestFYEAvailable ? "Yes" : "No",
                    "Latest FYE on Record": companyRequest.latestAvailableFYE,
                    "Client Name": dataRequest.clientName,
                    "Client Exchange:Ticker": dataRequest.clientCompanyId,
                    "Date Requested": dataRequest.dateRequested,
                    "Requestor Email": dataRequest.requestorEmail,
                    "Request Status": dataRequest.requestStatus,
                    "Flag": dataRequest.flag ? "Yes" : "No"
                }
            });

            return companyDataRequests;
        });
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setData([]);
            setLoading(false);
            setErrorDisplayStatus(false);
            setError("");
            getData(selectedRequestStatus);
            getAdminUser();
            setCurrentPage(1);
        }
        return () => mounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRequestStatus]);

    useEffect(() => {
        if (autoRefreshTimer) {
            clearInterval(autoRefreshTimer);
        }
        if (isAutoRefreshEnabled) {
            const timer = setInterval(() => {
                getData(selectedRequestStatus);
            }, 30000);
            setAutoRefreshTimer(timer);
        }
        return () => clearInterval(autoRefreshTimer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAutoRefreshEnabled, selectedRequestStatus]);

    return (
        <div className='data-requests-container'>
            {selectedDataRequestId &&
                <EditDataRequestModal
                    dataRequestId={selectedDataRequestId}
                    show={showEditDataRequestModal}
                    onClose={() => {
                        setShowEditDataRequestModal(false);
                        setSelectedDataRequestId(null);
                    }}
                    onRequestsUpdated={() => getData(selectedRequestStatus)}
                />
            }
            <div className='page-header'>
                <h2 className='page-title'>Data Requests</h2>
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" id="auto-refresh-switch" checked={isAutoRefreshEnabled} onChange={(e) => setIsAutoRefreshEnabled(e.target.checked)} />
                    <label className="form-check-label" htmlFor="auto-refresh-switch">Auto Refresh</label>
                </div>
            </div>
            <div className="alert-messages">
                {errorDisplayStatus && <Alert variant="danger" dismissible>{error}</Alert>}
            </div>
            {loading ? <Spinner animation="border" variant="primary" className="spinner-blue" /> :
                <div className='requests-content'>
                    <DataRequestStatusDropDown requestStatus={selectedRequestStatus} onRequestStatusChange={setSelectedRequestStatus} />
                    {data.length > 0 ?
                        <GenericGridComponent
                            columns={columns}
                            data={data}
                            enableGridToCSV={true}
                            useCustomExportMethod={true}
                            customExportMethod={() => exportToCSV(data, createDataRequestRows, "Data_Requests", "Data Requests")}
                            fileName={"Data_Requests"}
                            itemsName={"Data Requests"}
                            selectedCurrentPage={currentPage}
                            onCurrentPageSelect={(page) => setCurrentPage(page)}
                        />
                        : <Alert style={{ marginTop: "1rem" }} variant="info">No {selectedRequestStatus} requests</Alert>
                    }
                </div>
            }
        </div>
    )
}
