import React from 'react'
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';

import './styles.scss';
import { validateCompanyyId } from '../../utils/UtilFunctions';

export default function CompanyRequestsGrid({ companyRequests, onCompanyRequestsChange, activeTemplates, onRemoveCompanyRequest, onRequestValidityChange }) {
    const setCompanyRequestCompanyId = (e, position) => {
        const updatedCompanyRequests = [...companyRequests];
        if (e.target.value
            && !validateCompanyyId(e.target.value.trim().toUpperCase())
            && !e.target.value.trim().toUpperCase().includes('IQ')
        ) {
            updatedCompanyRequests[position].hasAlert = true;
            updatedCompanyRequests[position].alertMessage = "Company ID must be in the format of 'Exchange:Ticker' or 'IQXXXXXX' for IQ codes";
            onRequestValidityChange(false);
        } else {
            updatedCompanyRequests[position].hasAlert = false;
            updatedCompanyRequests[position].alertMessage = "";
            onRequestValidityChange(true);
        }
        updatedCompanyRequests[position].companyId = e.target.value;
        onCompanyRequestsChange(updatedCompanyRequests);
    }

    const setCompanyRequestCompanyName = (e, position) => {
        const updatedCompanyRequests = [...companyRequests];
        updatedCompanyRequests[position].companyName = e.target.value;
        if (e.target.value === "" || e.target.value === null) {
            onRequestValidityChange(false);
        } else {
            onRequestValidityChange(true);
        }
        onCompanyRequestsChange(updatedCompanyRequests);
    }

    const setCompanyRequestAnalysisType = (e, position) => {
        const updatedCompanyRequests = [...companyRequests];
        updatedCompanyRequests[position].templateId = e.target.value;
        if (e.target.value === "" || e.target.value === null) {
            onRequestValidityChange(false);
        } else {
            onRequestValidityChange(true);
        }
        onCompanyRequestsChange(updatedCompanyRequests);
    }

    const getUniqueId = (companyRequest, index) => {
        return `company-request-${companyRequest.companyRequestId ? companyRequest.companyRequestId : index}`;
    }

    return <div className="company-requests-rows-container">
        <Row className="company-requests-rows-container__header-row">
            <Col md={3}>Company ID</Col>
            <Col md={4}>Company Name</Col>
            <Col md={3}>Analysis Type</Col>
        </Row>
        <div className="company-requests-rows-container__request-rows">
            {companyRequests.map((companyRequest, index) => {
                return (
                    <Row key={getUniqueId(companyRequest, index)} className="company-request-row">
                        <Col md={3}>
                            <Form.Group controlId="companyId">
                                <Form.Control
                                    isInvalid={companyRequest.hasAlert}
                                    required
                                    type="text"
                                    placeholder="e.g. NASDAQ:APPL"
                                    value={companyRequest.companyId}
                                    onChange={(e) => setCompanyRequestCompanyId(e, index)} />
                                {companyRequest.hasAlert ? <Form.Control.Feedback type="invalid">
                                    {companyRequest.alertMessage ? companyRequest.alertMessage : "Please provide a valid company ID"}
                                </Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="companyName">
                                <Form.Control
                                    isInvalid={companyRequest.hasAlert}
                                    required
                                    type="text"
                                    placeholder="e.g. Apple, Microsoft, etc."
                                    value={companyRequest.companyName}
                                    onChange={(e) => setCompanyRequestCompanyName(e, index)} />
                                {!companyRequest.hasAlert ? <Form.Control.Feedback type="invalid">
                                    Please provide a valid company name
                                </Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group controlId="analysisType">
                                <Form.Control
                                    isInvalid={companyRequest.hasAlert}
                                    required as="select"
                                    value={companyRequest.templateId}
                                    onChange={(e) => setCompanyRequestAnalysisType(e, index)}>
                                    <option value="">Select analysis type</option>
                                    {activeTemplates.map((template, index) => {
                                        return (
                                            <option key={template.templateId} value={template.templateId}>{template.templateName}</option>
                                        );
                                    })}
                                </Form.Control>
                                {!companyRequest.hasAlert ? <Form.Control.Feedback type="invalid">
                                    Please select a valid analysis type
                                </Form.Control.Feedback> : null}
                            </Form.Group>
                        </Col>
                        <Col md={1}>
                            <div className="row-buttons">
                                <Button variant="danger" onClick={() => onRemoveCompanyRequest(index)}>X</Button>
                            </div>
                        </Col>
                    </Row>
                );
            })}
        </div>
    </div>;
}

CompanyRequestsGrid.propTypes = {
    companyRequests: PropTypes.array.isRequired,
    onCompanyRequestsChange: PropTypes.func.isRequired,
    activeTemplates: PropTypes.array.isRequired,
    onRemoveCompanyRequest: PropTypes.func.isRequired,
    onRequestValidityChange: PropTypes.func.isRequired
};