import { API } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Alert, Badge, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import { useStateStore } from "../../AppState";
import { DATA_PROVIDER, DATA_TYPES, DATA_VALIDATION_TYPES, NONE, TEMPLATES_URL } from "../../Constants";
import { getAuthToken } from "../../utils/AuthUtils";

import "./styles.scss";

const EditTemplateDefinition = () => {
    const { templateId } = useParams();
    const navigate = useNavigate();

    const alertRef = useRef(null);

    const [templateDefinition, setTemplateDefinition] = useState({});

    const templates = useStateStore(state => state.templates);
    const updateTemplates = useStateStore(state => state.setTemplates);

    const [sheetDefinitions, setSheetDefinitions] = useState([]);

    const [columnDefinitions, setColumnDefinitions] = useState([]);

    const [loadingStatus, setLoadingStatus] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [searchInput, setSearchInput] = useState('');
    const [dataTypeFilter, setDataTypeFilter] = useState('');
    const [validationFilter, setValidationFilter] = useState('');
    const [positionFilter, setPositionFilter] = useState('');
    const [sheetNameFilter, setSheetNameFilter] = useState('');
    const [filteredColumnDefinitions, setFilteredColumnDefinitions] = useState([]);

    const [accessGroups, setAccessGroups] = useState(NONE);

    const setTemplateName = (e) => {
        setTemplateDefinition({ ...templateDefinition, templateName: e.target.value });
    };

    const setColDataType = (colIndex, dataType) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[colIndex].dataType = dataType;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const setColValidations = (colIndex, validationValue) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[colIndex].validations = validationValue;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const setColPosition = (colIndex, position) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[colIndex].position = position.toUpperCase();
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const setRowNunber = (colIndex, rowNumber) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[colIndex].rowNumber = rowNumber;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const setColSheetName = (colIndex, sheetName) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[colIndex].sheetName = sheetName;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleColumnNameChange = (colIndex, event) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[colIndex].name = event.target.value;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleActiveChange = (event) => {
        const isActive = event.target.checked;
        setTemplateDefinition({ ...templateDefinition, active: isActive });
    }

    const handleAllowGridToExcelChange = (event) => {
        const allowGridToExcel = event.target.checked;
        setTemplateDefinition({ ...templateDefinition, allowGridToExcel });
    }

    const handleSettingColDefinitions = (templateDef) => {
        const sheets = [];
        const colDefinitions = [];
        const sheetColDefMap = new Map(Object.entries(templateDef.sheetColDefinitions));
        const groups = templateDef.accessGroups;
        if (groups === null || groups === undefined || groups.length === 0) {
            setAccessGroups(NONE);
        } else {
            setAccessGroups(groups[0]);
        }
        Array.from(sheetColDefMap.keys()).forEach(sheetName => {
            const sheetColDef = sheetColDefMap.get(sheetName);
            const sheet = {
                sheetName,
                startRow: sheetColDef.startRow,
                isDerived: sheetColDef.isDerived,
                isSummary: sheetColDef.isSummary,
                optional: sheetColDef.optional
            };
            sheets.push(sheet);
            colDefinitions.push(...sheetColDef.columnDefinitions);
        });
        setColumnDefinitions(colDefinitions);
        setFilteredColumnDefinitions(colDefinitions);
        setSheetDefinitions(sheets);
        setTemplateDefinition(templateDef);
    }

    const handleSheetNameChange = (sheetIndex, event) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[sheetIndex].sheetName = event.target.value;
        setSheetDefinitions(newSheetDefinitions);
    }

    const handleSheetStartRowChange = (sheetIndex, event) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[sheetIndex].startRow = event.target.value;
        setSheetDefinitions(newSheetDefinitions);
    }

    const removeCol = (colIndex) => {
        setErrorMessage("");
        setShowErrorMessage(false);
        const newColDefinitions = [...filteredColumnDefinitions];
        const removedCol = newColDefinitions.splice(colIndex, 1)[0];
        const newColDefinitionsUpdated = [...columnDefinitions];
        const removedColIndex = newColDefinitionsUpdated.findIndex(col => col.name === removedCol.name
            && col.dataType === removedCol.dataType
            && col.validations === removedCol.validations
            && col.position === removedCol.position
            && col.sheetName === removedCol.sheetName
            && col.id === removedCol.id
        );
        if (removedColIndex > -1) {
            newColDefinitionsUpdated.splice(removedColIndex, 1);
            setColumnDefinitions(newColDefinitionsUpdated);
            setFilteredColumnDefinitions(newColDefinitions);
        } else {
            setErrorMessage("An error occurred while removing column. Please try again later.");
            setShowErrorMessage(true);
        }
    }

    const addCol = () => {
        const updateColDefinitions = [...columnDefinitions];
        const blankColumnDefinitionCopy = {
            tempId: uuidv4(),
            name: searchInput,
            dataType: dataTypeFilter,
            validations: validationFilter,
            sheetName: sheetNameFilter,
            isFilterable: false,
            position: "",
            isTitleCode: false,
            secondaryReview: false,
            isReadOnly: false,
            isValueOnly: false,
            isSensitive: false,
            rowNumber: ""
        }
        updateColDefinitions.push(blankColumnDefinitionCopy);
        setColumnDefinitions(updateColDefinitions);
        filterColumns(updateColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const addSheet = () => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions.push({ name: "", startRow: "", isDerived: false, isSummary: false, optional: false });
        setSheetDefinitions(newSheetDefinitions);
    }

    const removeSheet = (sheetIndex) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions.splice(sheetIndex, 1);
        setSheetDefinitions(newSheetDefinitions);
    }

    const handleSettingFilterableCol = (index, event) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[index].isFilterable = event.target.checked;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleSettingIsReadOnlyCol = (index, event) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[index].isReadOnly = event.target.checked;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleSettingIsTitleCode = (index, event) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[index].isTitleCode = event.target.checked;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleSettingSecondaryReview = (index, event) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[index].secondaryReview = event.target.checked;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleIsValueOnlyChange = (index, event) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[index].isValueOnly = event.target.checked;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleIsSensitiveChange = (index, event) => {
        const newColDefinitions = [...filteredColumnDefinitions];
        newColDefinitions[index].isSensitive = event.target.checked;
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleSettingDerivedSheet = (index, value) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[index].isDerived = value;
        setSheetDefinitions(newSheetDefinitions);
    };

    const handleSettingSummarySheet = (index, value) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[index].isSummary = value;
        setSheetDefinitions(newSheetDefinitions);
    };

    const handleSettingOptionalSheet = (index, value) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[index].optional = value;
        setSheetDefinitions(newSheetDefinitions);
    };

    const handeUpdatingTemplates = () => {
        const existingTemplate = templates.find(template => template.templateId === templateId);
        const indexOfExistingTemplate = templates.indexOf(existingTemplate);
        if (indexOfExistingTemplate > -1) {
            const newTemplates = [...templates];
            newTemplates[indexOfExistingTemplate] = templateDefinition;
            updateTemplates(newTemplates);
        }
    }

    const handleUpdateTemplateDefinition = async () => {
        setLoadingStatus(true);
        const authToken = await getAuthToken();
        let groups;
        if (accessGroups && accessGroups !== NONE) {
            groups = [accessGroups]
        }
        API.put(
            "AnnualCompensations",
            TEMPLATES_URL + "/" + templateId,
            {
                headers: {
                    "Content-Type": "application/json",
                    'Token': authToken
                },
                body: {
                    templateName: templateDefinition.templateName,
                    templateId: templateId,
                    templateType: templateDefinition.templateType,
                    active: templateDefinition.active,
                    allowGridToExcel: templateDefinition.allowGridToExcel,
                    sheets: sheetDefinitions,
                    accessGroups: groups,
                    columnDefinitions
                }
            })
            .then(response => {
                setSuccessMessage(response.message);
                setShowSuccessMessage(true);
                setLoadingStatus(false);
                setShowErrorMessage(false);
                setErrorMessage("");
                handeUpdatingTemplates();
            })
            .catch(error => {
                setShowSuccessMessage(false);
                setLoadingStatus(false);
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                    setShowErrorMessage(true);
                } else {
                    setErrorMessage("An error occurred while modifying template data. Please try again later.");
                    setShowErrorMessage(true);
                }

            });
    }

    const handleSubmit = async (event) => {
        if (event.target.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            if (columnDefinitions.filter(col => col.isFilterable === true).length > 5) {
                setShowSuccessMessage(false);
                setSuccessMessage("");
                setErrorMessage("Only 5 columns can be filterable");
                setShowErrorMessage(true);
                setIsFormValid(false);
                return;
            }
            await handleUpdateTemplateDefinition();
        }
        setIsFormValid(true);
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        width: 'auto'
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: "0.5" * 2 + "rem",
        margin: `0.25rem 0`,
        background: isDragging ? "grey" : "white",
        ...draggableStyle
    });

    const updateColDefinitionOrder = (newItems) => {
        const newColumnDefinitions = [...columnDefinitions];
        newItems.forEach((item, index) => {
            const colIndex = newColumnDefinitions.findIndex(col => col.id === item.id);
            if (colIndex > -1) {
                const temp = newColumnDefinitions[index];
                newColumnDefinitions[index] = newColumnDefinitions[colIndex];
                newColumnDefinitions[colIndex] = temp;
            }
        });
        setColumnDefinitions(newColumnDefinitions);
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            filteredColumnDefinitions,
            result.source.index,
            result.destination.index
        );
        updateColDefinitionOrder(newItems);
        filterColumns(newItems, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);
        filterColumns(columnDefinitions, input, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const handleDataTypeFilterChange = (event) => {
        const dataType = event.target.value;
        setDataTypeFilter(dataType);
        filterColumns(columnDefinitions, searchInput, dataType, validationFilter, positionFilter, sheetNameFilter);
    };

    const handleValidationFilterChange = (event) => {
        const validation = event.target.value;
        setValidationFilter(validation);
        filterColumns(columnDefinitions, searchInput, dataTypeFilter, validation, positionFilter, sheetNameFilter);
    };

    const handlePositionFilterChange = (event) => {
        const position = event.target.value;
        setPositionFilter(position);
        filterColumns(columnDefinitions, searchInput, dataTypeFilter, validationFilter, position, sheetNameFilter);
    };

    const handleSheetNameFilterChange = (event) => {
        const sheetName = event.target.value;
        setSheetNameFilter(sheetName);
        filterColumns(columnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetName);
    };

    const filterColumns = (colDefs, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter) => {
        let filteredColumns = colDefs.filter((column) => {
            let matchesSearchInput = true;
            let matchesDataTypeFilter = true;
            let matchesValidationFilter = true;
            let matchesPositionFilter = true;
            let matchesSheetNameFilter = true;

            if (searchInput !== '') {
                matchesSearchInput = column.name.toLowerCase().includes(searchInput.toLowerCase());
            }

            if (dataTypeFilter !== '') {
                matchesDataTypeFilter = column.dataType === dataTypeFilter;
            }

            if (validationFilter !== '') {
                matchesValidationFilter = column.validations === validationFilter;
            }

            if (positionFilter !== '') {
                matchesPositionFilter = column.position === positionFilter;
            }

            if (sheetNameFilter !== '') {
                matchesSheetNameFilter = column.sheetName === sheetNameFilter;
            }

            return matchesSearchInput && matchesDataTypeFilter && matchesValidationFilter && matchesPositionFilter && matchesSheetNameFilter;
        });

        setFilteredColumnDefinitions(filteredColumns);
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const fetchTemplateById = async () => {
                setLoadingStatus(true);
                const authToken = await getAuthToken();
                API.get(
                    "AnnualCompensations",
                    TEMPLATES_URL + "/" + templateId,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            'Token': authToken
                        }
                    })
                    .then(response => {
                        handleSettingColDefinitions(response.data);
                        setLoadingStatus(false);
                    })
                    .catch(error => {
                        setLoadingStatus(false);
                        if (error?.response?.data?.message) {
                            setErrorMessage(error.response.data.message);
                            setShowErrorMessage(true);
                        } else {
                            setErrorMessage("An error occurred while retrieving template data. Please try again later.");
                            setShowErrorMessage(true);
                        }
                    });
            }
            if (templateId) {
                fetchTemplateById();
            }
        }
        return () => { isMounted = false };
    }, [templateId]);

    useEffect(() => {
        if (alertRef.current && (showErrorMessage || showSuccessMessage || loadingStatus))
            alertRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [alertRef, showErrorMessage, showSuccessMessage, loadingStatus]);

    return (
        <>
            {loadingStatus ? <Spinner animation="border" variant="primary" /> :
                <div className='main-section'>
                    <h4>Edit Template Definition</h4>
                    <div className='divider'></div>
                    <Form noValidate validated={isFormValid} onSubmit={handleSubmit}>
                        <div className="top-level-section">
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="templateName">
                                        <Form.Label >Template Name</Form.Label>
                                        <Form.Control type='text' placeholder='Template Name' value={templateDefinition.templateName || ""} required onChange={(e) => setTemplateName(e)} />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a template name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Row>
                                        <Form.Group controlId="isActive">
                                            <Form.Check type="switch" label="Active" checked={templateDefinition.active || false} onChange={handleActiveChange} />
                                        </Form.Group>
                                        <Form.Group controlId="allowExportGridToExcel">
                                            <Form.Check type="switch" label="Allow Export Grid To Excel" checked={templateDefinition.allowGridToExcel || false} onChange={handleAllowGridToExcelChange} />
                                        </Form.Group>
                                    </Row>
                                    <Form.Group controlId="accessGroup">
                                        <Form.Label>Access Group</Form.Label>
                                        <Form.Control as='select' value={accessGroups} onChange={(e) => setAccessGroups(e.target.value)}>
                                            <option value={NONE}>None</option>
                                            <option value={DATA_PROVIDER}>Data_Provider</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                        <div className="section">
                            <div className="title-section">
                                <h5>Sheet Definitions</h5>
                            </div>
                            {sheetDefinitions.map((sheet, sheetIndex) => {
                                return (
                                    <Row key={`sheet-${sheetIndex}`}>
                                        <Col md={2}>
                                            <Form.Group controlId='sheetName'>
                                                <Form.Label >Sheet Name</Form.Label>
                                                <Form.Control type='text' placeholder='Sheet Name' value={sheet.sheetName} onChange={(event) => handleSheetNameChange(sheetIndex, event)} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a sheet name.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId="isDerivedSheet">
                                                <Form.Check type="checkbox" label="Derived" checked={sheet.isDerived || false} onChange={(e) => handleSettingDerivedSheet(sheetIndex, e.target.checked)} />
                                            </Form.Group>
                                            <Form.Group controlId="isSummarySheet">
                                                <Form.Check type="checkbox" label="Summary" checked={sheet.isSummary || false} onChange={(e) => handleSettingSummarySheet(sheetIndex, e.target.checked)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group controlId='startRow'>
                                                <Form.Label >Start Row</Form.Label>
                                                <Form.Control type='number' placeholder='Start Row' value={sheet.startRow} onChange={(event) => handleSheetStartRowChange(sheetIndex, event)} required />
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide a start row.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId="isOptionalSheet">
                                                <Form.Check type="checkbox" label="Optional" checked={sheet.optional || false} onChange={(e) => handleSettingOptionalSheet(sheetIndex, e.target.checked)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <div className="sheet-operations-section">
                                                <div className="def-row-operations">
                                                    <Button variant="danger" onClick={() => removeSheet(sheetIndex)}><i className="fa fa-trash" style={{ padding: 0 }} /></Button>
                                                    {sheetIndex === sheetDefinitions.length - 1 ? <Button variant="primary" onClick={() => addSheet()}>+</Button> : null}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                        <div className="section">
                            <div className="title-section">
                                <div className="title">
                                    Column Definitions
                                </div>
                                <span className="column-count">
                                    <Badge variant="secondary">{filteredColumnDefinitions.length}</Badge>
                                </span>
                            </div>
                            <div className="filters">
                                <Row>
                                    <Col md={3}>
                                        <Form.Control type="text" placeholder="Search column names" value={searchInput} onChange={handleSearchInputChange} />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Control as="select" value={dataTypeFilter} onChange={handleDataTypeFilterChange}>
                                            <option value="">Filter by data type</option>
                                            <option value={DATA_TYPES.COMPOSITE_COMPANY_ID}>Company Id (exchange:ticker)</option>
                                            <option value={DATA_TYPES.STRING}>String</option>
                                            <option value={DATA_TYPES.NUMBER}>Number</option>
                                            <option value={DATA_TYPES.DATE}>Date</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Control as="select" value={validationFilter} onChange={handleValidationFilterChange}>
                                            <option value="">Filter by validations</option>
                                            <option value={DATA_VALIDATION_TYPES.REQUIRED}>Required (not null)</option>
                                            <option value={DATA_VALIDATION_TYPES.ND_REQUIRED}>Required or N/D</option>
                                            <option value={DATA_VALIDATION_TYPES.NA_REQUIRED}>Required or N/A</option>
                                            <option value={DATA_VALIDATION_TYPES.NOT_REQUIRED}>Not Required</option>
                                            <option value={DATA_VALIDATION_TYPES.ND_NOT_REQUIRED}>Not Required not N/A</option>
                                            <option value={DATA_VALIDATION_TYPES.NOT_REQUIRED_NOT_ND_NA}>Nullable not N/D or N/A</option>
                                            <option value={DATA_VALIDATION_TYPES.MARKER}>Marker (only x or X allowed)</option>
                                        </Form.Control>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Control type="text" placeholder="Filter by position" value={positionFilter} onChange={handlePositionFilterChange} />
                                    </Col>
                                    <Col md={2}>
                                        <Form.Control as="select" value={sheetNameFilter} onChange={handleSheetNameFilterChange}>
                                            <option value="">Filter by sheet name</option>
                                            {sheetDefinitions.map((sheet, index) => (
                                                <option key={index} value={sheet.sheetName}>{sheet.sheetName}</option>
                                            ))}
                                        </Form.Control>
                                    </Col>
                                    <Col md={1}>
                                        <div className="actions">
                                            <Button variant="primary" onClick={() => addCol()}>+</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="variable-col-section">
                                <DragDropContext onDragEnd={handleOnDragEnd}>
                                    <Droppable droppableId="variableColumns">
                                        {(provided, snapshot) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                {filteredColumnDefinitions.map((colDef, index) => {
                                                    return (
                                                        <Draggable key={colDef.id ? colDef.id : colDef.tempId} draggableId={colDef.id ? colDef.id : colDef.tempId} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <Row key={colDef.id ? colDef.id : colDef.tempId}>
                                                                        <Col md={3}>
                                                                            <Row>
                                                                                <Col md={2} className="count-indicator">
                                                                                    <Badge variant="secondary">{index + 1}</Badge>
                                                                                </Col>
                                                                                <Col md={10}>
                                                                                    <Form.Group controlId='columnName'>
                                                                                        <Form.Label>Column Name</Form.Label>
                                                                                        <Form.Control type='text' required placeholder='Column Name' value={colDef.name} onChange={(e) => handleColumnNameChange(index, e)} />
                                                                                        <Form.Control.Feedback type="invalid">
                                                                                            Please provide a column name.
                                                                                        </Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                    <Form.Group controlId="isValueOnly">
                                                                                        <Form.Check type="checkbox" label="Is Value Only" checked={colDef.isValueOnly || false} onChange={(e) => handleIsValueOnlyChange(index, e)} />
                                                                                    </Form.Group>
                                                                                    <Form.Group controlId="isSensitive">
                                                                                        <Form.Check type="checkbox" label="Sensitive" checked={colDef.isSensitive || false} onChange={(e) => handleIsSensitiveChange(index, e)} />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <Form.Group controlId='dataType'>
                                                                                <Form.Label>Data Type</Form.Label>
                                                                                <Form.Control as="select" required value={colDef.dataType} onChange={(e) => setColDataType(index, e.target.value)}>
                                                                                    <option value="">Select Data Type</option>
                                                                                    <option value={DATA_TYPES.COMPOSITE_COMPANY_ID}>Company Id (exchange:ticker)</option>
                                                                                    <option value={DATA_TYPES.STRING}>String</option>
                                                                                    <option value={DATA_TYPES.NUMBER}>Number</option>
                                                                                    <option value={DATA_TYPES.DATE}>Date</option>
                                                                                </Form.Control>
                                                                                <Form.Control.Feedback type="invalid">Please select a data type.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group controlId="isFilterable">
                                                                                <Form.Check type="checkbox" label="Filterable" checked={colDef.isFilterable || false} onChange={(e) => handleSettingFilterableCol(index, e)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <Form.Group controlId='columnValidations'>
                                                                                <Form.Label>Validations</Form.Label>
                                                                                <Form.Control as="select" required value={colDef.validations} onChange={(e) => setColValidations(index, e.target.value)}>
                                                                                    <option value="">Select Validations</option>
                                                                                    <option value={DATA_VALIDATION_TYPES.REQUIRED}>Required (not null)</option>
                                                                                    <option value={DATA_VALIDATION_TYPES.ND_REQUIRED}>Required or N/D</option>
                                                                                    <option value={DATA_VALIDATION_TYPES.NA_REQUIRED}>Required or N/A</option>
                                                                                    <option value={DATA_VALIDATION_TYPES.NOT_REQUIRED}>Not Required</option>
                                                                                    <option value={DATA_VALIDATION_TYPES.ND_NOT_REQUIRED}>Not Required not N/A</option>
                                                                                    <option value={DATA_VALIDATION_TYPES.NOT_REQUIRED_NOT_ND_NA}>Nullable not N/D or N/A</option>
                                                                                    <option value={DATA_VALIDATION_TYPES.MARKER}>Marker (only x or X allowed)</option>
                                                                                </Form.Control>
                                                                                <Form.Control.Feedback type="invalid">Please select a validation.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group controlId="isTitleCode">
                                                                                <Form.Check type="checkbox" label="Title Code" checked={colDef.isTitleCode || false} onChange={(e) => handleSettingIsTitleCode(index, e)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <Row>
                                                                                <Col md={4}>
                                                                                    <Form.Group controlId='columnPosition'>
                                                                                        <Form.Label>Position</Form.Label>
                                                                                        <Form.Control required type='text' placeholder='Position' value={colDef.position} onChange={(e) => setColPosition(index, e.target.value)} />
                                                                                        <Form.Control.Feedback type="invalid">Please enter a position.</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Col md={8}>
                                                                                    <Form.Group controlId="rowNumber">
                                                                                        <Form.Label>Row Number</Form.Label>
                                                                                        <Form.Control type="number" placeholder="Row Number"
                                                                                            value={colDef.rowNumber} onChange={(e) => setRowNunber(index, e.target.value)}></Form.Control>
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                <Form.Group controlId="needsToReviewed">
                                                                                    <Form.Check type="checkbox" label="Secondary Review" checked={colDef.secondaryReview} onChange={(e) => handleSettingSecondaryReview(index, e)} />
                                                                                </Form.Group>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <Form.Group controlId='sheetName'>
                                                                                <Form.Label>Sheet Name</Form.Label>
                                                                                <Form.Control as="select" required value={colDef.sheetName} onChange={(e) => setColSheetName(index, e.target.value)}>
                                                                                    <option value="">Select Sheet Name</option>
                                                                                    {sheetDefinitions.map((sheet, sheetIndex) => {
                                                                                        return (
                                                                                            <option key={sheetIndex} value={sheet.sheetName}>{sheet.sheetName}</option>
                                                                                        )
                                                                                    })}
                                                                                </Form.Control>
                                                                                <Form.Control.Feedback type="invalid">Please select a sheet name.</Form.Control.Feedback>
                                                                            </Form.Group>
                                                                            <Form.Group controlId="isReadOnly">
                                                                                <Form.Check type="checkbox" label="Is Read Only" checked={colDef.isReadOnly} onChange={(e) => handleSettingIsReadOnlyCol(index, e)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={1} className="row-operations-section">
                                                                            <div className="def-row-operations">
                                                                                <Button variant="danger" onClick={() => removeCol(index)}><i className="fa fa-trash" style={{ padding: 0 }} /></Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div className="actions-and-alerts">
                                <div className="actions">
                                    <Button variant="primary" disabled={loadingStatus} type="submit">Submit</Button>
                                    <Button variant="secondary" disabled={loadingStatus} onClick={() => navigate("/admin/templates")}>Cancel</Button>
                                </div>
                                {(showErrorMessage || showSuccessMessage) && <div className="alerts" ref={alertRef}>
                                    {showSuccessMessage && <Alert variant="success" show={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} dismissible>
                                        {successMessage}
                                    </Alert>}
                                    {showErrorMessage && <Alert variant="danger" show={showErrorMessage} onClose={() => setShowErrorMessage(false)} dismissible>
                                        {errorMessage}
                                    </Alert>}
                                </div>}
                            </div>
                        </div>
                    </Form>
                </div>
            }
        </>
    );
}

export default EditTemplateDefinition;