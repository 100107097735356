import { API } from "aws-amplify";
import { create } from 'zustand';
import { getAuthToken } from "./utils/AuthUtils";


export const useStateStore = create((set) => ({
    loading: false,
    templates: [],
    setTemplates: (templates) => ({ templates: set({ templates }) }),
    fetchTemplates: async () => {
        set({ loading: true });
        const url = "/templates";
        const authToken = await getAuthToken();
       const response = await API.get(
            "AnnualCompensations",
            url,
            {
                headers: {
                    'Token': authToken
                }
            });
        set({ templates: response.data });
        set({ loading: false });
        return response;
    },
    fetchTemplateById: async (templateId) => {
        set({ loading: true });
        const url = `/templates/${templateId}`;
        const authToken = await getAuthToken();
        const response = await API.get(
            "AnnualCompensations",
            url,
            {
                headers: {
                    'Token': authToken
                }
            });
        set({ loading: false });
        set({ templates: { ...response.data } });
        return response;
    },
    getTemplateByTemplateId: (templates, templateId) => {
        return templates.find((template) => template.templateId === templateId);
    },
    setLoadingStatus: (loading) => ({ loading: set({ loading }) }),
}));