import { API } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Alert, Badge, Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import { useStateStore } from "../../AppState";
import {
    BROAD_BASED_STANDARD_COLUMNS,
    DATA_TYPES,
    DATA_VALIDATION_TYPES,
    EXECUTIVE_STANDARD_COLUMNS,
    REQUIRED_COMPENSATION_FIELDS,
    SURVEY_STANDARD_COLUMNS,
    TEMPLATE_TYPE,
    NONE,
    DATA_PROVIDER
} from "../../Constants";
import { getAuthToken } from "../../utils/AuthUtils";

import "./styles.scss";

const CreateTemplate = () => {

    const fetchTemplates = useStateStore(state => state.fetchTemplates);
    const navigate = useNavigate();

    const [isFormValid, setIsFormValid] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [sheetNames, setSheetNames] = useState([]);

    const alertRef = useRef(null);

    const [columnDefinitions, setColumnDefinitions] = useState([]);
    const [templateName, setTemplateName] = useState("");
    const [active, setActive] = useState(false);
    const [allowGridToExcel, setAllowGridToExcel] = useState(false);
    const [templateType, setTemplateType] = useState("");
    const [fileContent, setFileContent] = useState("");
    const [sheetDefinitions, setSheetDefinitions] = useState([{ startRow: "", sheetName: "", isDerived: false, isSummary: false, optional: false }]);

    const [searchInput, setSearchInput] = useState('');
    const [dataTypeFilter, setDataTypeFilter] = useState('');
    const [validationFilter, setValidationFilter] = useState('');
    const [positionFilter, setPositionFilter] = useState('');
    const [sheetNameFilter, setSheetNameFilter] = useState('');
    const [filteredColumnDefinitions, setFilteredColumnDefinitions] = useState([]);

    const [accessGroups, setAccessGroups] = useState(NONE);

    const setColName = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].name = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setColDataType = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].dataType = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setColValidations = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].validations = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setColPosition = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].position = value.toUpperCase();
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setRowNunber = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].rowNumber = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setIsValueOnly = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].isValueOnly = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setIsSensitive = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].isSensitive = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const setIsFilterable = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].isFilterable = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setIsReadOnly = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].isReadOnly = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setColSheetName = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].sheetName = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setIsTitleCode = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].isTitleCode = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const setSecondaryReview = (index, value) => {
        const newVariableColumnDefinitions = [...filteredColumnDefinitions];
        newVariableColumnDefinitions[index].secondaryReview = value;
        filterColumns(newVariableColumnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const removeCol = (colIndex) => {
        setErrorMessage("");
        setShowErrorMessage(false);
        const newColDefinitions = [...filteredColumnDefinitions];
        const removedCol = newColDefinitions.splice(colIndex, 1)[0];
        const newColDefinitionsUpdated = [...columnDefinitions];
        const removedColIndex = newColDefinitionsUpdated.findIndex(col => col.name === removedCol.name
            && col.dataType === removedCol.dataType
            && col.validations === removedCol.validations
            && col.position === removedCol.position
            && col.sheetName === removedCol.sheetName
            && col.id === removedCol.id
        );
        if (removedColIndex > -1) {
            newColDefinitionsUpdated.splice(removedColIndex, 1);
            setColumnDefinitions(newColDefinitionsUpdated);
            setFilteredColumnDefinitions(newColDefinitions);
        } else {
            setErrorMessage("An error occurred while removing column. Please try again later.");
            setShowErrorMessage(true);
        }
    };

    const addCol = () => {
        const newColDefinitions = [...columnDefinitions];
        const blankColumnDefinitionCopy = {
            tempId: uuidv4(),
            name: searchInput,
            dataType: dataTypeFilter,
            validations: validationFilter,
            sheetName: sheetNameFilter,
            isFilterable: false,
            position: "",
            isTitleCode: false,
            secondaryReview: false,
            isReadOnly: false,
            isValueOnly: false,
            isSensitive: false,
            rowNumber: ""
        }
        newColDefinitions.push(blankColumnDefinitionCopy);
        setColumnDefinitions(newColDefinitions);
        filterColumns(newColDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const handleSubmit = async (e) => {
        if (e.currentTarget.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            let groups;
            if (accessGroups && accessGroups !== NONE) {
                groups = [accessGroups]
            }
            if (filteredColumnDefinitions.filter(col => col.isFilterable === true).length > 5) {
                setSuccessMessage("");
                setShowSuccessMessage(false);
                setErrorMessage("Only 5 columns can be filterable");
                setShowErrorMessage(true);
                setIsFormValid(false);
                return;
            }
            const request = {
                templateName,
                templateType,
                active,
                allowGridToExcel,
                sheets: sheetDefinitions,
                encodedTemplateFile: fileContent,
                accessGroups: groups,
                columnDefinitions: columnDefinitions
            };
            await postTemplateDefinition(request);
        }
        setIsFormValid(true);
    };

    const handleTemplateTypeChange = (e) => {
        const selectedTemplateType = e.target.value;
        setTemplateType(selectedTemplateType);

        switch (selectedTemplateType) {
            case TEMPLATE_TYPE.EXECUTIVE: {
                const execRequiredCols = [...JSON.parse(JSON.stringify(EXECUTIVE_STANDARD_COLUMNS))];
                setColumnDefinitions(execRequiredCols);
                setFilteredColumnDefinitions(execRequiredCols);
                break;
            }
            case TEMPLATE_TYPE.BROAD_BASED: {
                const broadBasedRequiredCols = [...JSON.parse(JSON.stringify(BROAD_BASED_STANDARD_COLUMNS))];
                setColumnDefinitions(broadBasedRequiredCols);
                setFilteredColumnDefinitions(broadBasedRequiredCols);
                break;
            }
            case TEMPLATE_TYPE.SURVEY: {
                const surveyRequiredCols = [...JSON.parse(JSON.stringify(SURVEY_STANDARD_COLUMNS))];
                setColumnDefinitions(surveyRequiredCols);
                setFilteredColumnDefinitions(surveyRequiredCols);
                break;
            }
            default:
                throw new Error("Invalid template type");
        }
    };

    const handleFile = async (e) => {
        setLoadingStatus(true);
        const file = e.target.files[0];
        if (file === undefined)
            return;
        const reader = new FileReader();
        reader.onloadend = async (e) => {
            const base64 = e.target.result.split(',')[1];
            const XLSX = await import('xlsx');
            setSheetNames(XLSX.read(base64, { type: 'base64' }).SheetNames);
            setFileContent(e.target.result);
            setLoadingStatus(false);
        };
        reader.readAsDataURL(file);
    };

    const handleReset = () => {
        setTemplateName("");
        setTemplateType("");
        setColumnDefinitions([]);
        setFileContent("");
        setSheetDefinitions([{ name: "", columns: [] }]);
        setIsFormValid(false);
    };

    const handleTemplateNameChange = (value) => {
        const formattedTicker = value === undefined ? "" : value.toUpperCase();
        setTemplateName(formattedTicker);
    };

    const postTemplateDefinition = async (request) => {
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setLoadingStatus(true);
        const idToken = await getAuthToken();
        const requestOptions = {
            headers: { 'Content-Type': 'application/json', 'Token': idToken },
            body: request
        };
        API.post("AnnualCompensations", "/templates", requestOptions)
            .then(response => {
                console.log(response);
                setSuccessMessage("Template definition created successfully");
                setShowSuccessMessage(true);
                fetchTemplates();
                setLoadingStatus(false);
                navigate("/admin/templates");
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("Error creating template definition");
                }
                setShowErrorMessage(true);
                setLoadingStatus(false);
            });
    };

    const handleSheetNameChange = (index, value) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[index].sheetName = value;
        setSheetDefinitions(newSheetDefinitions);
    };

    const handleSettingDerivedSheet = (index, value) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[index].isDerived = value;
        setSheetDefinitions(newSheetDefinitions);
    };

    const handleSettingSummarySheet = (index, value) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[index].isSummary = value;
        setSheetDefinitions(newSheetDefinitions);
    };

    const handleSettingOptionalSheet = (index, value) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[index].optional = value;
        setSheetDefinitions(newSheetDefinitions);
    };

    const handleSheetStartRowChange = (index, value) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions[index].startRow = value;
        setSheetDefinitions(newSheetDefinitions);
    };

    const removeSheet = (index) => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions.splice(index, 1);
        setSheetDefinitions(newSheetDefinitions);
    };

    const addSheet = () => {
        const newSheetDefinitions = [...sheetDefinitions];
        newSheetDefinitions.push({ name: "", startRow: "", isDerived: false, isSummary: false, optional: false });
        setSheetDefinitions(newSheetDefinitions);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const updateColDefinitionOrder = (newItems) => {
        const newColumnDefinitions = [...columnDefinitions];
        newItems.forEach((item, index) => {
            const colIndex = newColumnDefinitions.findIndex(col => col.id === item.id);
            if (colIndex > -1) {
                const temp = newColumnDefinitions[index];
                newColumnDefinitions[index] = newColumnDefinitions[colIndex];
                newColumnDefinitions[colIndex] = temp;
            }
        });
        setColumnDefinitions(newColumnDefinitions);
    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            filteredColumnDefinitions,
            result.source.index,
            result.destination.index
        );
        updateColDefinitionOrder(newItems);
        filterColumns(newItems, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        width: 'auto'
    });

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: "0.5" * 2 + "rem",
        margin: `0.25rem 0`,
        background: isDragging ? "grey" : "white",
        ...draggableStyle
    });

    const handleSearchInputChange = (event) => {
        const input = event.target.value;
        setSearchInput(input);
        filterColumns(columnDefinitions, input, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter);
    };

    const handleDataTypeFilterChange = (event) => {
        const dataType = event.target.value;
        setDataTypeFilter(dataType);
        filterColumns(columnDefinitions, searchInput, dataType, validationFilter, positionFilter, sheetNameFilter);
    };

    const handleValidationFilterChange = (event) => {
        const validation = event.target.value;
        setValidationFilter(validation);
        filterColumns(columnDefinitions, searchInput, dataTypeFilter, validation, positionFilter, sheetNameFilter);
    };

    const handlePositionFilterChange = (event) => {
        const position = event.target.value;
        setPositionFilter(position);
        filterColumns(columnDefinitions, searchInput, dataTypeFilter, validationFilter, position, sheetNameFilter);
    };

    const handleSheetNameFilterChange = (event) => {
        const sheetName = event.target.value;
        setSheetNameFilter(sheetName);
        filterColumns(columnDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetName);
    };

    const filterColumns = (colDefinitions, searchInput, dataTypeFilter, validationFilter, positionFilter, sheetNameFilter) => {
        let filteredColumns = colDefinitions.filter((column) => {
            let matchesSearchInput = true;
            let matchesDataTypeFilter = true;
            let matchesValidationFilter = true;
            let matchesPositionFilter = true;
            let matchesSheetNameFilter = true;

            if (searchInput !== '') {
                matchesSearchInput = column.name.toLowerCase().includes(searchInput.toLowerCase());
            }

            if (dataTypeFilter !== '') {
                matchesDataTypeFilter = column.dataType === dataTypeFilter;
            }

            if (validationFilter !== '') {
                matchesValidationFilter = column.validations === validationFilter;
            }

            if (positionFilter !== '') {
                matchesPositionFilter = column.position === positionFilter;
            }

            if (sheetNameFilter !== '') {
                matchesSheetNameFilter = column.sheetName === sheetNameFilter;
            }

            return matchesSearchInput && matchesDataTypeFilter && matchesValidationFilter && matchesPositionFilter && matchesSheetNameFilter;
        });

        setFilteredColumnDefinitions(filteredColumns);
    };

    useEffect(() => {
        if (alertRef.current && (showErrorMessage || showSuccessMessage || loadingStatus))
            alertRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [alertRef, showErrorMessage, showSuccessMessage, loadingStatus]);

    return (
        <div className="main-panel">
            <h4>Create Template Definition</h4>
            <div className="divider"></div>
            <Form noValidate validated={isFormValid} onSubmit={handleSubmit}>
                <div className="section">
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="templateName">
                                <Form.Label>Template Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter Template Name" value={templateName}
                                    onChange={(e) => handleTemplateNameChange(e.target.value)}></Form.Control>
                                <Form.Control.Feedback type="invalid">Please provide a template name.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="isActive">
                                <Form.Check type="switch" label="Active" checked={active} onChange={(e) => setActive(e.target.checked)} />
                            </Form.Group>
                            <Form.Group controlId="allowExportGridToExcel">
                                <Form.Check type="switch" label="Allow Export Grid To Excel" checked={allowGridToExcel} onChange={(e) => setAllowGridToExcel(e.target.checked)} />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="templateType">
                                <Form.Label>Template Type</Form.Label>
                                <Form.Control as="select" required value={templateType} onChange={handleTemplateTypeChange}>
                                    <option value="">Select Template Type</option>
                                    <option value={TEMPLATE_TYPE.EXECUTIVE}>Executive</option>
                                    <option value={TEMPLATE_TYPE.BROAD_BASED}>Broad Based</option>
                                    <option value={TEMPLATE_TYPE.SURVEY}>Survey</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">Please select a template type.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="fileContent">
                                <Form.Label>Excel File</Form.Label>
                                <Form.Control
                                    type="file"
                                    required
                                    name="file"
                                    accept=".xlsx"
                                    onChange={handleFile}
                                />
                                <Form.Control.Feedback type="invalid">Please select a excel file</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="accessGroup">
                                <Form.Label>Access Group</Form.Label>
                                <Form.Control as='select' value={accessGroups} onChange={(e) => setAccessGroups(e.target.value)}>
                                    <option value={NONE}>None</option>
                                    <option value={DATA_PROVIDER}>Data_Provider</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <div className="section">
                    <div className="title-section">
                        <div className="title">Sheets</div>
                    </div>
                    <div className="sheets-section">
                        {sheetDefinitions.map((sheet, index) => (
                            <Row key={`sheet-${index}`}>
                                <Col md={2}>
                                    <Form.Group controlId="sheetName">
                                        <Form.Label>Sheet Name</Form.Label>
                                        <Form.Control as="select" required value={sheet.sheetName} disabled={sheetNames.length === 0} onChange={(e) => handleSheetNameChange(index, e.target.value)}>
                                            <option value="">Select Sheet Name</option>
                                            {sheetNames.map((sheetName, index) => (
                                                <option key={index} value={sheetName}>{sheetName}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">Please select a sheet name.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="isDerivedSheet">
                                        <Form.Check id={index} type="checkbox" label="Derived" checked={sheet.isDerived} onChange={(e) => handleSettingDerivedSheet(index, e.target.checked)} />
                                    </Form.Group>
                                    <Form.Group controlId="isSummarySheet">
                                        <Form.Check id={index} type="checkbox" label="Summary" checked={sheet.isSummary} onChange={(e) => handleSettingSummarySheet(index, e.target.checked)} />
                                    </Form.Group>
                                </Col>
                                <Col md={2}>
                                    <Form.Group controlId="sheetStartRow">
                                        <Form.Label>Start Row</Form.Label>
                                        <Form.Control required type="number" placeholder="Enter Start Row" value={sheet.startRow} onChange={(e) => handleSheetStartRowChange(index, e.target.value)}></Form.Control>
                                        <Form.Control.Feedback type="invalid">Please provide a start row.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="isOptional">
                                        <Form.Check id={index} type="checkbox" label="Optional" checked={sheet.optional} onChange={(e) => handleSettingOptionalSheet(index, e.target.checked)} />
                                    </Form.Group>
                                </Col>
                                <Col md={1} className="sheet-actions">
                                    <div className="def-row-operations">
                                        {index !== 0 ? <Button variant="danger" onClick={() => removeSheet(index)}>-</Button> : null}
                                        {index === sheetDefinitions.length - 1 ? <Button variant="primary" onClick={addSheet}>+</Button> : null}
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </div>
                <div className="section">
                    <div className="title-section">
                        <div className="title">
                            Column Definitions
                        </div>
                        <span className="column-count">
                            <Badge variant="secondary">{filteredColumnDefinitions.length}</Badge>
                        </span>
                    </div>
                    <div className="filters">
                        <Row>
                            <Col md={3}>
                                <Form.Control type="text" placeholder="Search column names" value={searchInput} onChange={handleSearchInputChange} />
                            </Col>
                            <Col md={2}>
                                <Form.Control as="select" value={dataTypeFilter} onChange={handleDataTypeFilterChange}>
                                    <option value="">Filter by data type</option>
                                    <option value={DATA_TYPES.COMPOSITE_COMPANY_ID}>Company Id (exchange:ticker)</option>
                                    <option value={DATA_TYPES.STRING}>String</option>
                                    <option value={DATA_TYPES.NUMBER}>Number</option>
                                    <option value={DATA_TYPES.DATE}>Date</option>
                                </Form.Control>
                            </Col>
                            <Col md={2}>
                                <Form.Control as="select" value={validationFilter} onChange={handleValidationFilterChange}>
                                    <option value="">Filter by validations</option>
                                    <option value={DATA_VALIDATION_TYPES.REQUIRED}>Required (not null)</option>
                                    <option value={DATA_VALIDATION_TYPES.ND_REQUIRED}>Required or N/D</option>
                                    <option value={DATA_VALIDATION_TYPES.NA_REQUIRED}>Required or N/A</option>
                                    <option value={DATA_VALIDATION_TYPES.NOT_REQUIRED}>Not Required</option>
                                    <option value={DATA_VALIDATION_TYPES.ND_NOT_REQUIRED}>Not Required not N/A</option>
                                    <option value={DATA_VALIDATION_TYPES.NOT_REQUIRED_NOT_ND_NA}>Nullable not N/D or N/A</option>
                                    <option value={DATA_VALIDATION_TYPES.MARKER}>Marker (only x or X allowed)</option>
                                </Form.Control>
                            </Col>
                            <Col md={2}>
                                <Form.Control type="text" placeholder="Filter by position" value={positionFilter} onChange={handlePositionFilterChange} />
                            </Col>
                            <Col md={2}>
                                <Form.Control as="select" value={sheetNameFilter} onChange={handleSheetNameFilterChange}>
                                    <option value="">Filter by sheet name</option>
                                    {sheetDefinitions.map((sheet, index) => (
                                        <option key={index} value={sheet.sheetName}>{sheet.sheetName}</option>
                                    ))}
                                </Form.Control>
                            </Col>
                            <Col md={1}>
                                <div className="actions">
                                    <Button variant="primary" onClick={() => addCol()}>+</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="variable-col-section">
                        <DragDropContext onDragEnd={(result) => handleOnDragEnd(result)}>
                            <Droppable droppableId="variableColumns">
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                                        {filteredColumnDefinitions.map((col, index) => (
                                            <Draggable key={col.id ? col.id : col.tempId} draggableId={col.id ? col.id : col.tempId} index={index}>
                                                {(provided, snapshot) => (
                                                    <div ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    >
                                                        <Row key={col.id ? col.id : col.tempId}>
                                                            <Col md={3}>
                                                                <Row>
                                                                    <Col md={1} style={{ marginTop: "0.8rem" }}>
                                                                        <Badge>{index + 1}</Badge>
                                                                    </Col>
                                                                    <Col md={11}>
                                                                        <Form.Group controlId="columnName">
                                                                            <Form.Control required type="text" placeholder="Enter Col Name"
                                                                                value={col.name} onChange={(e) => setColName(index, e.target.value)}></Form.Control>
                                                                            <Form.Control.Feedback type="invalid">Please provide a column name.</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                        <Form.Group controlId="isValueOnly">
                                                                            <Form.Check type="checkbox" label="Is Value Only" checked={col.isValueOnly} onChange={(e) => setIsValueOnly(index, e.target.checked)} />
                                                                        </Form.Group>
                                                                        <Form.Group controlId="isSensitive">
                                                                            <Form.Check type="checkbox" label="Sensitive" checked={col.isSensitive} onChange={(e) => setIsSensitive(index, e.target.checked)} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Form.Group controlId="dataType">
                                                                    <Form.Control as="select" required value={col.dataType} onChange={(e) => setColDataType(index, e.target.value)}>
                                                                        <option value="">Select Data Type</option>
                                                                        <option value={DATA_TYPES.COMPOSITE_COMPANY_ID}>Company Id (exchange:ticker)</option>
                                                                        <option value={DATA_TYPES.STRING}>String</option>
                                                                        <option value={DATA_TYPES.NUMBER}>Number</option>
                                                                        <option value={DATA_TYPES.DATE}>Date</option>
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid">Please select a data type.</Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group controlId="isFilterable">
                                                                    <Form.Check type="checkbox" label="Filterable" checked={col.isFilterable} onChange={(e) => setIsFilterable(index, e.target.checked)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Form.Group controlId="validations">
                                                                    <Form.Control as="select" required value={col.validations} onChange={(e) => setColValidations(index, e.target.value)}>
                                                                        <option value="">Select Validations</option>
                                                                        <option value={DATA_VALIDATION_TYPES.REQUIRED}>Required (not null)</option>
                                                                        <option value={DATA_VALIDATION_TYPES.ND_REQUIRED}>Required or N/D</option>
                                                                        <option value={DATA_VALIDATION_TYPES.NA_REQUIRED}>Required or N/A</option>
                                                                        <option value={DATA_VALIDATION_TYPES.NOT_REQUIRED}>Not Required</option>
                                                                        <option value={DATA_VALIDATION_TYPES.ND_NOT_REQUIRED}>Not Required not N/A</option>
                                                                        <option value={DATA_VALIDATION_TYPES.NOT_REQUIRED_NOT_ND_NA}>Nullable not N/D or N/A</option>
                                                                        <option value={DATA_VALIDATION_TYPES.MARKER}>Marker (only x or X allowed)</option>
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid">Please select a validation.</Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group controlId="isTileCode">
                                                                    <Form.Check type="checkbox" label="Title Code" checked={col.isTitleCode} onChange={(e) => setIsTitleCode(index, e.target.checked)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Row>
                                                                    <Col md={4}>
                                                                        <Form.Group controlId="position">
                                                                            <Form.Control required type="text" placeholder="Enter Col Position"
                                                                                value={col.position} onChange={(e) => setColPosition(index, e.target.value)}></Form.Control>
                                                                            <Form.Control.Feedback type="invalid">Please provide a position of for this column.</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={8}>
                                                                        <Form.Group controlId="rowNumber">
                                                                            <Form.Control type="number" placeholder="Enter Row Number"
                                                                                value={col.rowNumber} onChange={(e) => setRowNunber(index, e.target.value)}></Form.Control>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Form.Group controlId="needsToReviewed">
                                                                        <Form.Check type="checkbox" label="Secondary Review" checked={col.secondaryReview} onChange={(e) => setSecondaryReview(index, e.target.checked)} />
                                                                    </Form.Group>
                                                                </Row>
                                                            </Col>
                                                            <Col md={2}>
                                                                <Form.Group controlId="sheetName">
                                                                    <Form.Control as="select" required value={col.sheetName} disabled={sheetNames.length === 0} onChange={(e) => setColSheetName(index, e.target.value)}>
                                                                        <option value="">Select Sheet</option>
                                                                        {sheetDefinitions.map((sheet, index) => (
                                                                            <option key={index} value={sheet.sheetName}>{sheet.sheetName}</option>
                                                                        ))}
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid">Please select a sheet.</Form.Control.Feedback>
                                                                </Form.Group>
                                                                <Form.Group controlId="isReadOnly">
                                                                    <Form.Check type="checkbox" label="Is Read Only" checked={col.isReadOnly} onChange={(e) => setIsReadOnly(index, e.target.checked)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={1}>
                                                                <div className="def-row-operations">
                                                                    {!REQUIRED_COMPENSATION_FIELDS.includes(col.name) ? <Button variant="danger" onClick={() => removeCol(index)}><i className="fa fa-trash" style={{ padding: 0 }} /></Button> : null}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                <div className="actions-and-alerts">
                    <div className="actions">
                        <Button variant="primary" disabled={loadingStatus} type="submit">Submit</Button>
                        <Button variant="secondary" type="reset" onClick={handleReset}>Reset</Button>
                    </div>
                    {(showErrorMessage || showSuccessMessage) && <div className="alerts" ref={alertRef}>
                        {showSuccessMessage && <Alert variant="success" show={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} dismissible>
                            {successMessage}
                        </Alert>}
                        {showErrorMessage && <Alert variant="danger" show={showErrorMessage} onClose={() => setShowErrorMessage(false)} dismissible>
                            {errorMessage}
                        </Alert>}
                    </div>}
                </div>
            </Form>
        </div>
    )
}

export default CreateTemplate;