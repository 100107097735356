import { Button, Modal, Alert } from "react-bootstrap";

import "./styles.scss";

const WarningMessageModal = ({ isOpen, onClose, onContinue, disabled }) => {

    return (
        <>
            {!disabled ? <Modal className="warning-message-modal" show={isOpen} onHide={onClose}>
                <Modal.Header>
                    <Modal.Title>WARNING</Modal.Title>
                </Modal.Header>
                <Modal.Body><Alert variant="warning">{"Searching sheet data with a large number of tickers (over 100) may cause the application to timeout. To ensure optimal performance, we recommend searching for fewer than 100 tickers at once."}</Alert></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onContinue}>
                        Continue
                    </Button>
                    <Button variant="secondary" onClick={onClose}>
                        Exit
                    </Button>
                </Modal.Footer>
            </Modal> : null}
        </>
    )
}

export default WarningMessageModal;