import { Analytics } from 'aws-amplify';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import NavBar from '../Home/NavigationBar/NavBar';
import QuestionnaireUploadModal from './QuestionnaireUploadModal/QuestionnaireUploadModal';

import './styles.scss';

const finalSurveyResult = 'finalResult';

const unrestrictedSurveyDataAPI = process.env.REACT_APP_UNRESTRICTED_SURVEY_DATA_API;

export default function SurveyParticipantConsole() {
    const [isLoading, setIsLoading] = useState(true);

    const finalResultDownloadLinkRef = useRef(null);

    const [finalResultLink, setFinalResultLink] = useState(null);

    const { participantId } = useParams();
    const [participantData, setParticipantData] = useState(null);

    const [showUploadModal, setShowUploadModal] = useState(false);

    const [error, setError] = useState(null);
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);

    const fetchParticipantData = () => {
        axios.get(`${unrestrictedSurveyDataAPI}/unrestricted/survey/participants?id=${participantId}`)
            .then(response => {
                setParticipantData(response.data);
                navigatedToParticipantConsoleEvent(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const fetchStaticFileLink = (fileType, setLink) => {
        axios.get(`${unrestrictedSurveyDataAPI}/unrestricted/survey/files?docType=${fileType}&participantId=${participantId}`)
            .then(response => {
                setLink(response.data);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const navigatedToParticipantConsoleEvent = (participant) => {
        Analytics.record({
            name: 'NavigatedToParticipantConsole',
            attributes: {
                participantId: participant.participantId,
                participantName: participant.participantName
            }
        });
    }

    const emailAccountExecutiveEvent = () => {
        Analytics.record({
            name: 'EmailAccountExecutive',
            attributes: {
                participantId: participantId,
                participantName: participantData.participantName,
                accountExecutiveEmail: participantData.accountExecutiveEmail
            }
        });
    }

    const emailFwcSurveyEvent = () => {
        Analytics.record({
            name: 'EmailFwcSurvey',
            attributes: {
                participantId: participantId,
                participantName: participantData.participantName
            }
        });
    }

    const downloadFinalResultEvent = () => {
        Analytics.record({
            name: 'DownloadFinalResult',
            attributes: {
                participantId: participantId,
                participantName: participantData.participantName
            }
        });
    }

    const handleDownloadFinalResult = () => {
        if (!participantData?.earlySurveyResultEligible) {
            setError("You are not eligible to download the final result. Please contact your account representative for more information.");
            setErrorDisplayStatus(true);
            return;
        }

        if (finalResultLink) {
            finalResultDownloadLinkRef.current.href = finalResultLink.url;
            finalResultDownloadLinkRef.current.click();
            downloadFinalResultEvent();
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setIsLoading(true);
            fetchParticipantData();
            fetchStaticFileLink(finalSurveyResult, setFinalResultLink);
            setIsLoading(false);
        }
        return () => { isMounted = false };
        // eslint-disable-next-line
    }, [participantId]);

    return (
        <>
            <NavBar landingPagePath={`/ecs-survey/${participantId}`} />
            {(participantData && !isLoading) ? <div className='page-width'>
                <QuestionnaireUploadModal show={showUploadModal} onClose={() => setShowUploadModal(false)} participantId={participantId} participantName={participantData.participantName} />
                <div className='survey-participant-console-container'>
                    <h3 className='title-text'>
                        <span className='hello-text'>Hello</span>, <span className='participant-name-text'>{participantData.participantName}</span>
                    </h3>
                    <div className='welcome-text'>
                        Welcome to the 2024 FW Cook Executive Compensation Survey Portal!
                    </div>
                    <div className='survey-participant-console-content'>
                        <div className='section'>
                            <h4 className='section-heading'>Survey Access</h4>
                            <p>Access the relevant survey file(s) and related materials for download here</p>
                            <a href="#self" ref={finalResultDownloadLinkRef} download id="download-final-result" hidden={true}>hidden</a>
                            <div className='actions-buttons-container' style={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                <div className='tooltip-container'>
                                    <button className='btn btn-primary download' onClick={handleDownloadFinalResult}>Download Final Result</button>
                                    <span className='tooltip-text'>Download Final Survey Result - General Industry</span>
                                </div>
                            </div>
                            <div className='alert-messages'>
                                {errorDisplayStatus && <div className='alert alert-danger'>{error}</div>}
                            </div>
                        </div>
                        <div className='section'>
                            <h4 className='section-heading'>Questionnaire Submission Portal</h4>
                            <p>Survey submissions for 2024 are now closed. Thank you for your participation! We look forward to your continued involvement.</p>
                        </div>
                        <div className='section'>
                            <h4 className='section-heading'>Contact Us</h4>
                            <p>For any queries, reach out to {(participantData?.accountExecutiveEmail) ?
                                <span> your account representative <a href={`mailto:${participantData.accountExecutiveEmail}`} onClick={emailAccountExecutiveEvent} className='link'>{participantData.accountExecutiveName}</a> or</span> : null}
                                <span> <a href='mailto:FWCSurvey@fwcook.com' onClick={emailFwcSurveyEvent} className='link'>FWCSurvey@fwcook.com</a></span></p>
                        </div>
                        <div className='thank-you-text'>
                            Thank you for your contribution to shaping the future of executive compensation practices with the FW Cook Executive Compensation Survey
                        </div>
                    </div>
                </div>
            </div> : <Spinner animation="border" variant="primary" />}
        </>
    )
}