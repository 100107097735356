import React from 'react'
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap'

export default function SurveyStatusDropDown({ submissionStatus, onStatusChange }) {
    return (<Form.Group controlId="submissionStatus">
        <Form.Label>Submission Status</Form.Label>
        <Form.Control
            as="select"
            value={submissionStatus}
            className="col-md-2"
            onChange={(e) => onStatusChange(e.target.value)}
            style={{ width: "12.5rem" }}
        >
            <option value={"SUBMITTED_SUCCESSFULLY"}>Submitted Successfully</option>
            <option value={"SUBMITTED_WITH_ERRORS"}>Submitted With Errors</option>
            <option value={"READY_FOR_REVIEW"}>Ready For Review</option>
            <option value={"REVIEWED"}>Reviewed</option>
        </Form.Control>
    </Form.Group>
    )
}

SurveyStatusDropDown.propTypes = {
    submissionStatus: PropTypes.string.isRequired,
    onStatusChange: PropTypes.func.isRequired
}
