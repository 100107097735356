import React, { useEffect, useState } from 'react'
import { API, Auth } from 'aws-amplify';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { getAuthToken } from '../../utils/AuthUtils';
import GenericGridComponent from '../../GenericGridComponent/GenericGridComponent';
import './styles.scss';
import SurveyStatusDropDown from './SurveyStatusDropDown/SurveyStatusDropDown';
import EditSurveySubmissionModal from './EditSurveySubmissionModal/EditSurveySubmissionModal';
import { exportToCSV } from '../../utils/exportUtils';

const SurveySubmissionTab = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);
    const [selectedSubmissionStatus, setSelectedSubmissionStatus] = useState("SUBMITTED_SUCCESSFULLY");
    const [isAdminUser, setIsAdminUser] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedSubmissionRecord, setSelectedSubmissionRecord] = useState(null);
    const [showEditSubmissionModal, setShowEditSubmissionModal] = useState(false)

    const actions = (row) => {
        return (
            <div>
                <Button variant="primary edit-submission-btn" size="sm" disabled={!isAdminUser} className="mr-2" onClick={() => handleEditSumbissionRecord(row)}>
                    <i className="fa fa-edit"></i>
                </Button>
                <Button variant="btn btn-primary btn-download" size="sm" disabled={!isAdminUser} className="mr-2" onClick={() => getSubmisionFile(row.fileKey)}>
                    <i className="fa fa-download"></i>
                </Button>
                {row.validationErrorCsv ? <Button title='Export grid to excel' variant='primary' onClick={() => handleExportValidationErrors(row.validationErrorCsv)} style={{ padding: "0.25rem" }}><i className="fa fa-file-csv" style={{ padding: "0.25rem" }}></i></Button> : null}
            </div>
        )
    }

    const handleEditSumbissionRecord = (submissionRecord) => {
        setSelectedSubmissionRecord(submissionRecord);
        setShowEditSubmissionModal(true);
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleString();
    }

    const handleDownloadFile = (url, fileName = undefined) => {
        const link = document.createElement('a');
        link.href = url;
        if (fileName) {
            link.download = fileName;
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleExportValidationErrors = (validationErrorsCsv) => {
        const blob = new Blob([validationErrorsCsv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        handleDownloadFile(url, "ECS_Survey_Validation.csv");
    }

    const columns = [{
        "key": "participantName",
        "name": "Participant Name",
        "sortable": true,
        "filterable": true
    }, {
        "key": "participantId",
        "name": "Participant ID",
        "sortable": true,
        "filterable": true
    }, {
        "key": "submitterEmail",
        "name": "Submitter Email",
        "sortable": true,
        "filterable": true
    }, {
        "key": "submissionDate",
        "name": "Submission Date",
        "sortable": true,
        "filterable": true,
        "formatter": (props) => {
            return (
                formatDate(props.row.submissionDate)
            );
        }
    }, {
        "key": "version",
        "name": "Submission Version",
        "sortable": true,
        "filterable": true
    }, {
        "key": "actions",
        "sortable": false,
        "filterable": false,
        "formatter": (props) => {
            return (
                actions(props.row)
            );
        }
    }]

    const getSubmisionFile = async (fileKey) => {
        const authToken = await getAuthToken();
        const path = `/restricted/survey/files?fileKey=${encodeURIComponent(fileKey)}`;
        API.get('AnnualCompensations', path, {
            headers: {
                'Token': authToken
            }
        }).then((response) => {
            handleDownloadFile(response.url);
        }).catch((error) => {
            if (error?.response?.data?.message) {
                console.log(error.response.data.message);
            } else {
                console.log(error);
                console.log("An error occurred while retrieving the submission file. Please try again later.");
            }
            setError("An error occurred downloading the submission file");
            setErrorDisplayStatus(true);
        })
    }

    const getSubmissionData = async (submissionStatus) => {
        setLoading(true);
        setErrorDisplayStatus(false);
        setError("");

        const authToken = await getAuthToken();

        const path = `/restricted/survey/submissions?status=${submissionStatus}`

        API.get('AnnualCompensations', path, {
            headers: {
                "Content-Type": "application/json",
                "Token": authToken
            }
        }).then(response => {
            setData(response);
            setLoading(false);
        }).catch(error => {
            console.log(error);
            setError("Error occurred retrieving submission data");
            setErrorDisplayStatus(true);
            setLoading(false);
        });
    }

    const createDataRows = (data) => {
        return data.map(row => {
            return {
                "Participant Name": row.participantName,
                "Participant ID": row.participantId,
                "Submitter Email": row.submitterEmail,
                "Submission Date": row.submissionDate,
                "Submission Version": row.version
            }
        });
    }

    const getAdminUser = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
        setIsAdminUser(groups.includes("FWC_Admin"));
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setData([]);
            setLoading(false);
            setErrorDisplayStatus(false);
            setError("");
            getSubmissionData(selectedSubmissionStatus);
            getAdminUser();
            setCurrentPage(1);
        }
        return () => mounted = false;
    }, [selectedSubmissionStatus]);

    return (
        <div className='survey-submissions-container'>
            {selectedSubmissionRecord &&
                <EditSurveySubmissionModal
                    data={selectedSubmissionRecord}
                    show={showEditSubmissionModal}
                    onClose={() => {
                        setShowEditSubmissionModal(false);
                        setSelectedSubmissionRecord(null);
                    }}
                    onSubmissionUpdated={() => getSubmissionData(selectedSubmissionStatus)}
                />}
            <div className="alert-messages">
                {errorDisplayStatus && <Alert variant="danger" dismissible>{error}</Alert>}
            </div>
            {isAdminUser ? <div>{loading ? <Spinner animation="border" variant="primary" className="spinner-blue" /> :
                <div className='submissions-content'>
                    <SurveyStatusDropDown
                        submissionStatus={selectedSubmissionStatus}
                        onStatusChange={(status) => setSelectedSubmissionStatus(status)}
                    />
                    {data.length > 0 ? <GenericGridComponent
                        columns={columns}
                        data={data}
                        enableGridToCSV={true}
                        useCustomExportMethod={true}
                        customExportMethod={() => exportToCSV(data, createDataRows, "Survey_Submissions", "Survey Submissions")}
                        fileName={"Survey_Submissions"}
                        itemsName={"Survey Submissions"}
                        selectedCurrentPage={currentPage}
                        onCurrentPageSelect={(page) => setCurrentPage(page)}
                    />
                        : <Alert style={{ marginTop: "1rem" }} variant="info">No {selectedSubmissionStatus} submissions</Alert>}
                </div>}</div> : <Alert variant="danger" dismissible>Administrative access required</Alert>}
        </div>
    )

}

export default SurveySubmissionTab;