import { Authenticator, SelectField, useAuthenticator, useTheme, View } from "@aws-amplify/ui-react";

import React from 'react';
import { Outlet } from 'react-router-dom';

import NavBar from './Home/NavigationBar/NavBar.js';
import { ReactComponent as Logo } from './images/FWC-Logo.svg';

import '@aws-amplify/ui-react/styles.css';
import { AppToast } from "./CommonComponents/AppToast/AppToast.js";
import { ToastProvider } from "./Contexts/ToastProvider.js";

const OFFICE_LOCATION_CUSTOM_ATTRIBUTE = 'custom:office_location';

const officeLocations = [
	"Chicago",
	"Los Angeles",
	"New York",
	"San Francisco",
	"Atlanta",
	"Houston"
];

const components = {
	Header() {
		const { tokens } = useTheme();
		return (
			<View textAlign="center" padding={tokens.space.large}>
				<Logo title="FW Cook" style={{ "cursor": "pointer", "width": "11.5rem" }} />
			</View>
		)
	},
	SignUp: {
		FormFields() {
			const { validationErrors } = useAuthenticator();
			return (
				<>
					<SelectField
						label="Office Location"
						name={OFFICE_LOCATION_CUSTOM_ATTRIBUTE}
						placeholder="Enter your office location"
						options={officeLocations}
						errorMessage={validationErrors[OFFICE_LOCATION_CUSTOM_ATTRIBUTE]}
						hasError={!!validationErrors[OFFICE_LOCATION_CUSTOM_ATTRIBUTE]}
					/>
					<Authenticator.SignUp.FormFields />
				</>
			)
		}
	}
}

const services = {
	async validateCustomSignUp(formData) {
		if (formData[OFFICE_LOCATION_CUSTOM_ATTRIBUTE] === '') {
			return { [OFFICE_LOCATION_CUSTOM_ATTRIBUTE]: 'Office location is required' };
		}
	}
};

const signUpFormFields = {
	username: {
		placeholder: 'Enter your FW Cook email address',
		label: 'Email',
		isRequired: true,
		order: 1
	},
	name: {
		placeholder: 'Enter your name',
		isRequired: true,
		order: 2
	}
};

const formFields = {
	signIn: {
		username: {
			placeholder: 'Enter your FW Cook email address',
			isRequired: true,
		}
	}, signUp: signUpFormFields
}

const App = () => {
	return (
		<Authenticator components={{ ...components }} services={services} formFields={formFields} signUpAttributes={['username', 'name', 'custom:office_location']}>
			{({ signOut, user }) => (
				<ToastProvider>
					<NavBar signOut={signOut} user={user} />
					<div className='page-width'>
						<AppToast />
						<Outlet />
					</div>
				</ToastProvider>
			)}
		</Authenticator>
	);
}

export default App;
