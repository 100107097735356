export const billableTypeVals = Object.freeze({ billable: "BILLABLE", nonBillableRFP: "NON_BILLABLE_RFP", nonBillableOther: "NON_BILLABLE_OTHER" });
export const billableTypeMap = Object.freeze({ "BILLABLE": "Billable", "NON_BILLABLE_RFP": "Non Billable - RFP", "NON_BILLABLE_OTHER": "Non Billable - Other" });

export const createUpdateRequestBody = (event, uniqueId, userHistory) => {
    event.preventDefault();
    const index = userHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
    const userHistoryRecord = userHistory[index];

    const request = {
        uniqueId: userHistoryRecord.uniqueId,
        billableType: userHistoryRecord.billableType,
        clientName: userHistoryRecord.clientName,
        userId: userHistoryRecord.userId,
        userNotes: userHistoryRecord.userNotes,
        clientOwnerName: userHistoryRecord.clientOwnerName,
        clientOwnerEmail: userHistoryRecord.clientOwnerEmail,
        clientOwnerUserId: userHistoryRecord.clientOwnerUserId,
        templateId: userHistoryRecord.templateId,
        monthAndYearAccessed: userHistoryRecord.monthAndYearAccessed,
        dataIds: userHistoryRecord.dataIds,
        numberOfCompaniesAccessed: userHistoryRecord.numberOfCompaniesAccessed
    };
    return request
}

export const exportUsageDowloadDataToXlsx = async (userHistory, normalizeData) => {
    const XLSX = await import("xlsx");
    const normalizedData = normalizeData(userHistory);
    const ws = XLSX.utils.json_to_sheet(normalizedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "DATA");
    XLSX.writeFile(wb, `Data_Download_History.xlsx`);
}