import { Badge } from "react-bootstrap"

const DismissiblePill = ({ backgroundColor, displayText, id, onRemove }) => {
    return (
        <>
            <Badge pill bg={backgroundColor}>
                {displayText}
                <i className="fa fa-sharp fa-solid fa-times"
                    tabIndex={-1}
                    onClick={() => onRemove(id)}
                    style={{ color: "white", paddingLeft: "3px", fontSize: "0.65rem", cursor: "pointer", verticalAlign: "text-top" }}>
                </i>
            </Badge>
        </>
    );
}

export default DismissiblePill;