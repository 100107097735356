import { OverlayTrigger, Tooltip } from "react-bootstrap"

const ToolTipWrapper = ({ placement, id, text, element, style }) => {
    const renderToolTip = (props) => {
        return (
            <Tooltip id={id} {...props}>{text}</Tooltip>
        );
    }

    return (
        <OverlayTrigger placement={placement} delay={{ show: 200, hide: 200 }} overlay={renderToolTip}>
            <div className="d-inline-flex" style={style}>
                {element}
            </div>
        </OverlayTrigger>
    );
}

export default ToolTipWrapper;