import React from 'react';
import { Auth } from 'aws-amplify';

export default class GroupVisibleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    async componentDidMount() {
        if (this.props.groups) {
            const user = await Auth.currentAuthenticatedUser();
            let groups = user.signInUserSession.accessToken.payload["cognito:groups"];
            let userInAConfiguredGroups = groups?.some(group => this.props?.groups?.includes(group));
            this.setState({ visible: userInAConfiguredGroups });
        }
    }

    render() {
        if (this.state.visible)
            return (
                this.props.styles ?
                    <div style={this.props.styles}>
                        {this.props.children}
                    </div> :
                    <>
                        {this.props.children}
                    </>
            );
        else
            return (<div></div>);
    }
}
