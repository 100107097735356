import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { getAuthToken } from '../../../utils/AuthUtils';
import { Alert, Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { API } from 'aws-amplify';
import SurveyStatusDropDown from '../SurveyStatusDropDown/SurveyStatusDropDown'
import './styles.scss';

export default function EditSurveySubmissionModal({ data, show, onClose, onSubmissionUpdated }) {

    const [submissionRecord, setSubmissionRecord] = useState(data);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [error, setError] = useState("");
    const [errorDisplayStatus, setErrorDisplayStatus] = useState(false);
    const [success, setSuccess] = useState("");
    const [successDisplayStatus, setSuccessDisplayStatus] = useState(false);

    const handleChangeStatus = (status) => {
        setSubmissionRecord({ ...submissionRecord, submissionStatus: status });
    }

    const formatDate = (date) => {
        return new Date(date).toLocaleString();
    }

    const handleClose = () => {
        setSuccessDisplayStatus(false);
        setErrorDisplayStatus(false);
        setError("");
        setSuccess("");
        onClose();
    }

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const token = await getAuthToken();

        try {
            setLoadingStatus(true);
            await API.put("AnnualCompensations", "/restricted/survey/submissions", {
                body: submissionRecord,
                headers: {
                    "Content-Type": "application/json",
                    "Token": token
                }
            });
            setLoadingStatus(false);
            setSuccessDisplayStatus(true);
            setSuccess("Survey Submission updated successfully");
            onSubmissionUpdated();
            handleClose();
        } catch (error) {
            console.log(error)
            setLoadingStatus(false);
            setErrorDisplayStatus(true);
            setError("An error occurred while updating the survey submission.");
        }
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setSubmissionRecord(data)
        }
        return () => { isMounted = false };
    }, [data]);

    return (
        <div>
            <Modal show={show} onHide={handleClose} size='xl' centered>
                <div className="edit-survey-submission-container">
                    <Form noValidate onSubmit={(e) => handleSaveChanges(e)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Survey Submission</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Alert show={errorDisplayStatus} variant="danger" dismissible onClose={() => setErrorDisplayStatus(false)}>
                                {error}
                            </Alert>
                            <Alert show={successDisplayStatus} variant="success" dismissible onClose={() => setSuccessDisplayStatus(false)}>
                                {success}
                            </Alert>
                            {(loadingStatus && submissionRecord) ? <div className="loading">Loading...<output><Spinner as="span" animation="grow" size="sm" aria-hidden="true" /> </output></div> :
                                <>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="participantName">
                                                <Form.Label>Participant Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Participant Name"
                                                    value={submissionRecord.participantName}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <SurveyStatusDropDown
                                                submissionStatus={submissionRecord.submissionStatus}
                                                onStatusChange={(status) => handleChangeStatus(status)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="submitterEmail">
                                                <Form.Label>Submitter Email</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Submitter Email"
                                                    value={submissionRecord.submitterEmail}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="submissionDate">
                                                <Form.Label>Submission Date</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Submission Date"
                                                    value={formatDate(submissionRecord.submissionDate)}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    {(submissionRecord.modifiedBy && submissionRecord.modifiedOn) ?
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group controlId="modifiedBy">
                                                    <Form.Label>Modified By</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Modified By"
                                                        value={submissionRecord.modifiedBy}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group controlId="modifiedOn">
                                                    <Form.Label>Modified On</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Modified On"
                                                        value={submissionRecord.modifiedOn}
                                                        readOnly
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row> : ""}
                                </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" disabled={loadingStatus} type="submit">
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}

EditSurveySubmissionModal.protoTypes = {
    data: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmissionUpdated: PropTypes.func.isRequired
};