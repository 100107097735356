import { Pagination } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import './styles.scss';

const PaginationComponent = ({ filteredData, onChangePaginatedData, onCurrentPageSelect, itemsName, selectedPageSize, onChangeSelectedPageSize, selectedCurrentPage = 1 }) => {
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(selectedCurrentPage);
    const [totalCount, setTotalCount] = useState(0);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        onCurrentPageSelect(pageNumber);
    };

    const handleChangingPageSize = (size) => {
        const dataPaginated = filteredData.slice((currentPage - 1) * size, currentPage * size);
        if (size > dataPaginated.length)
            return;
        onChangeSelectedPageSize(size);
    };
    useEffect(() => {
        const currentPageCount = Math.ceil(filteredData.length / selectedPageSize);
        const dataPaginated = filteredData.slice((currentPage - 1) * selectedPageSize, currentPage * selectedPageSize);
        onChangePaginatedData(dataPaginated);
        setPageCount(currentPageCount);
        setTotalCount(filteredData.length);
    }, [selectedPageSize, filteredData, currentPage, onChangePaginatedData]);

    useEffect(() => {
        if (selectedCurrentPage !== undefined) {
            setCurrentPage(selectedCurrentPage);
        }
    }, [selectedCurrentPage]);

    return (
        <div>
            {totalCount > 0 && <div className='d-flex flex-row justify-content-end mt-1'>
                <div className='pagination-info d-flex flex-column justify-content-between align-items-end'>
                    <Pagination>
                        <Pagination.First className='paginator-item' key='first' onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                        <Pagination.Prev className='paginator-item' key='prev' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                        {Array.from({ length: pageCount > 10 ? 10 : pageCount }, (_, i) => i + 1).map((pageNumber) => (
                            <Pagination.Item
                                key={pageNumber}
                                active={pageNumber === currentPage}
                                onClick={() => handlePageChange(pageNumber)}
                                className='paginator-item'>
                                {pageNumber}
                            </Pagination.Item>
                        ))}
                        {pageCount > 10 ? <Pagination.Ellipsis className='paginator-item' key='ellipsis' disabled /> : null}
                        {currentPage > 10 && currentPage < pageCount ? <Pagination.Item className='paginator-item' key='selected-page' active={currentPage} onClick={() => handlePageChange(currentPage)}>{currentPage}</Pagination.Item> : null}
                        {pageCount > 10 ? <Pagination.Item className='paginator-item' key='last-page' active={pageCount === currentPage} onClick={() => handlePageChange(pageCount)}>{pageCount}</Pagination.Item> : null}
                        <Pagination.Next className='paginator-item' key='next' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount} />
                        <Pagination.Last className='paginator-item' key='last' onClick={() => handlePageChange(pageCount)} disabled={currentPage === pageCount} />
                        <Pagination.Item className='page-size' key='page-size' style={{ borderRadius: "0" }}>
                            <select value={selectedPageSize} style={{ padding: "0.4rem 0" }} onChange={(e) => handleChangingPageSize(parseInt(e.target.value))}>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                            </select>
                        </Pagination.Item>
                    </Pagination>
                    <div className='result-count'>{totalCount} Total {itemsName}</div>
                </div>
            </div>
            }
        </div>
    );
}

export default PaginationComponent;

PaginationComponent.propTypes = {
    filteredData: PropTypes.array.isRequired,
    selectedCurrentPage: PropTypes.number,
    onChangePaginatedData: PropTypes.func.isRequired,
    onCurrentPageSelect: PropTypes.func.isRequired,
    itemsName: PropTypes.string.isRequired,
    selectedPageSize: PropTypes.number.isRequired,
    onChangeSelectedPageSize: PropTypes.func.isRequired
};