import { Badge, Alert } from "react-bootstrap";
import ToolTipWrapper from "../../CommonComponents/ToolTipComponent/ToolTipWrapper";

const StatBadge = ({ title, value, color }) => (
    <div className="row-stat">
        <span className="row-stat_title">{title}</span>
        <Badge style={{ marginLeft: "0.25rem", "marginRight": "0.25rem" }} bg={color}>
            {value}
        </Badge>
    </div>
);

const StatAlert = ({ variant, message, onClose }) => (
    <Alert variant={variant} style={{ marginTop: "0.5rem", textTransform: "uppercase" }} onClose={onClose} dismissible>
        {message}
    </Alert>
);

const ResultIndicators = ({ compensationData, hasSearchResults, numberOfMatchedCompanies, numberOfUnmatchedCompanies, unavailableDataMap, noDataAvailableTickers, setShowAlert, showAlert, showError, error, successfulDownload, setSuccessfulDownload }) => {
    const getYearLookbackLabel = (yearLookback) => {
        switch (yearLookback) {
            case '1':
                return 'Latest Year';
            case '2':
                return 'Second Latest';
            case '3':
                return 'Third Latest';
            // Add more cases as needed
            default:
                return `${yearLookback} Years Ago`;
        }
    }

    return (
        <div className="result-indicators">
            {compensationData !== null && hasSearchResults && (
                <div className="mb-2 row-stat-indicator-row" role="alert" style={{ padding: "unset" }}>
                    <StatBadge title="Matched" value={numberOfMatchedCompanies} color="success" />
                    {numberOfUnmatchedCompanies !== null && (
                        <ToolTipWrapper
                            id={"number-of-unmatched-companies"}
                            placement={"top"}
                            text={"Number of unmatched tickers"}
                            style={{ marginLeft: "0.5rem" }}
                            element={<StatBadge title="Unmatched" value={numberOfUnmatchedCompanies} color="danger" />}
                        />
                    )}
                    <StatBadge title="Data Rows" value={compensationData.length ?? 0} color="secondary" />
                </div>
            )}
            {successfulDownload && <StatAlert variant="success" message="Download Successful" onClose={() => setSuccessfulDownload(false)} />}
            {showError && <StatAlert variant="danger" message={error} />}
            {showAlert ? <div className="alert alert-warning alert-dismissible fade show" role="alert">
                <div style={{ maxHeight: '10rem', overflow: 'auto' }}>
                    <div className='d-inline-flex' style={{ textTransform: "uppercase", alignItems: "center" }}>
                        <div style={{ alignItems: "inherit", marginRight: "0.25rem", marginLeft: "0.25rem" }}>
                            {noDataAvailableTickers && noDataAvailableTickers.length > 0 && (
                                <div>
                                    <span>No Data Available: <strong>{noDataAvailableTickers.join(',')}</strong></span>
                                </div>
                            )}
                            {unavailableDataMap.size > 0 &&
                                <div>
                                    <span style={{ top: 0 }}>Data Available absent following years:</span>
                                    <ul style={{ marginLeft: "1rem", paddingLeft: "1rem", marginTop: "0.5rem" }}>
                                        {Array.from(unavailableDataMap).map(([yearLookback, tickers]) => {
                                            // Split tickers into chunks of 20
                                            const chunkSize = 20;
                                            const tickerChunks = [];
                                            for (let i = 0; i < tickers.length; i += chunkSize) {
                                                tickerChunks.push(tickers.slice(i, i + chunkSize));
                                            }
                                            return (
                                                <li key={yearLookback}>
                                                    <span>{getYearLookbackLabel(yearLookback)} ({yearLookback}): </span>
                                                    {tickerChunks.map((chunk, index) => (
                                                        <p key={index} style={{ wordWrap: "break-word", marginTop: "0.25rem", marginBottom: "0.25rem" }}>
                                                            <strong>{chunk.join(',')}</strong>
                                                        </p>
                                                    ))}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <button type="button" className="btn-close" onClick={() => setShowAlert(false)} aria-label="Close"></button>
            </div> : null}
        </div>);
}

export default ResultIndicators;