import React from 'react';
import { Alert, Nav } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import MonthlyUsageDataTab from './MonthlyUsageDataTab/MontlyUsageDataTab';
import TemplateDefinitionTab from './TemplateDefinitionsTab/TemplateDefinitionTab';
import SurveySubmissionTab from './SurveySubmissionsTab/SurveySubmissions';
import ClientMetaDataTab from './ClientMetaDataTab/ClientMetaData'
import BillingDataTab from './BillingDataTab/BillingDataTab';
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

const MONTHLY_USAGE_REPORT = "monthly-usage-report";
const TEMPLATE_DEFINITIONS_TAB = "templates";
const SURVEY_SUBMISSIONS_TAB = "survey-submissions";
const CLIENT_METADATA = "client-metadata";
const BILLING_DATA = "billing-data";

const AdminConsole = () => {
    const { tabName } = useParams();
    const navigate = useNavigate();

    const renderActiveTab = () => {
        switch (tabName) {
            case MONTHLY_USAGE_REPORT:
                return (<MonthlyUsageDataTab />);
            case TEMPLATE_DEFINITIONS_TAB:
                return (<TemplateDefinitionTab />);
            case SURVEY_SUBMISSIONS_TAB:
                return (<SurveySubmissionTab />);
            case CLIENT_METADATA:
                return (<ClientMetaDataTab />);
            case BILLING_DATA:
                return (<BillingDataTab />);
            default:
                return (<Alert variant="danger">Invalid tab name</Alert>);
        }
    }

    return (
        <div className="admin-console">
            <Nav justify variant="tabs" defaultActiveKey={tabName} onSelect={(selectedTab) => navigate("/admin/" + selectedTab)}>
                <Nav.Item>
                    <Nav.Link key={MONTHLY_USAGE_REPORT} active={tabName === MONTHLY_USAGE_REPORT} eventKey={MONTHLY_USAGE_REPORT}>Monthly Usage Report</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={TEMPLATE_DEFINITIONS_TAB} active={tabName === TEMPLATE_DEFINITIONS_TAB} eventKey={TEMPLATE_DEFINITIONS_TAB}>Template Definitions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={SURVEY_SUBMISSIONS_TAB} active={tabName === SURVEY_SUBMISSIONS_TAB} eventKey={SURVEY_SUBMISSIONS_TAB}>Survey Submissions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={CLIENT_METADATA} active={tabName === CLIENT_METADATA} eventKey={CLIENT_METADATA}>Client Metadata</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={BILLING_DATA} active={tabName === BILLING_DATA} eventKey={BILLING_DATA}>Billing Data</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="main-content-area">{renderActiveTab()}</div>
        </div>
    );
}

export default AdminConsole;