import { useEffect, useRef } from "react"

export const useFocuRef = (isSelected) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!isSelected) return;
        ref.current?.focus({ preventScroll: true })
    }, [isSelected]);

    return {
        ref,
        tabIndex: isSelected ? 0 : -1
    };
}