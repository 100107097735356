import { React } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Nav } from "react-bootstrap";
import ClientOwnerDataDownloadHistoryTab from "./ClientOwnerDataDownloadHistoryTab/ClientOwnerDataDownloadHistoryTab";
import UserDataDownloadHistoryTab from "./UserDataDownloadHistoryTab/UserDataDownloadHistoryTab";
import "./styles.scss";

const USER_DOWNLOAD_HISTORY = "user";
const CLIENT_OWNER_DOWNLOAD_HISTORY = "client-owner";

const DownloadHistoryConsole = () => {
    const { tabName } = useParams();
    const navigate = useNavigate();

    const renderActiveTab = () => {
        switch (tabName) {
            case USER_DOWNLOAD_HISTORY:
                return (<UserDataDownloadHistoryTab />);
            case CLIENT_OWNER_DOWNLOAD_HISTORY:
                return (<ClientOwnerDataDownloadHistoryTab />);
            default:
                return (<UserDataDownloadHistoryTab />);
        }
    }

    return (
        <div className="download-history-console">
            <Nav justify variant="tabs" defaultActiveKey={tabName} onSelect={(selectedTab) => navigate("/download-history/" + selectedTab)}>
                <Nav.Item>
                    <Nav.Link key={USER_DOWNLOAD_HISTORY} active={tabName === USER_DOWNLOAD_HISTORY} eventKey={USER_DOWNLOAD_HISTORY}>Accessed By Me</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link key={CLIENT_OWNER_DOWNLOAD_HISTORY} active={tabName === CLIENT_OWNER_DOWNLOAD_HISTORY} eventKey={CLIENT_OWNER_DOWNLOAD_HISTORY}>Accessed On My Behalf</Nav.Link>
                </Nav.Item>
            </Nav>
            <div className="main-content-area">{renderActiveTab()}</div>
        </div>
    )
}

export default DownloadHistoryConsole;