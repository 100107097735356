import { useState } from "react";
import PropTypes from 'prop-types';
import { Alert, Badge, Button, Form, Modal } from "react-bootstrap";

import "./styles.scss";
import ToolTipWrapper from "../../CommonComponents/ToolTipComponent/ToolTipWrapper";
import { validateCompanyyId } from "../../utils/UtilFunctions";

const NASDAQ_EXCHANGE = "NASDAQ";
const NYSE_EXCHANGE = "NYSE";
const PRIVATE_COMPANY_IQ_CODE = "IQ";
const PUBLIC_COMPANY_ID_SEPARATOR = ":";

const BulkCompanyRequestModal = ({ showModal, onClose, activeTemplates, handleCompanyRequests }) => {
    const [companyIDs, setCompanyIDs] = useState('');
    const [formattedCompanyIDs, setFormattedCompanyIDs] = useState([]);
    const [analysisType, setAnalysisType] = useState('');

    const [error, setError] = useState("");
    const [hasError, setHasError] = useState(false);

    const standardizeCompanyId = (companyId) => {
        if (companyId.toUpperCase().startsWith(PRIVATE_COMPANY_IQ_CODE)) {
            return companyId.trim().toUpperCase();
        }
        const companyIdParts = companyId.split(PUBLIC_COMPANY_ID_SEPARATOR);
        if (companyIdParts.length !== 2) {
            setError(`Company IDs must be in the format of 'Exchange:Ticker' or 'IQ' followed by a number. Incorrect format: ${companyId}`);
            setHasError(true);
            return;
        }
        let exchange = companyIdParts[0].trim().toUpperCase();
        const ticker = companyIdParts[1].trim().toUpperCase();
        if (exchange.includes(NASDAQ_EXCHANGE)) {
            exchange = NASDAQ_EXCHANGE;
        } else if (exchange.includes(NYSE_EXCHANGE)) {
            exchange = NYSE_EXCHANGE;
        }
        return `${exchange}:${ticker}`;
    }

    const parseCompanyIds = (companyIds) => {
        const delimiter = /\r?\n/;
        const formattedCompanyIDs = new Set();
        companyIds.split(delimiter).forEach(tks => {
            if (tks.split(",").length > 1) {
                tks.split(",").forEach(tk => {
                    if (tk === "") return;
                    const nomalizedCompanyId = standardizeCompanyId(tk);
                    formattedCompanyIDs.add(nomalizedCompanyId);
                });
                return;
            }
            if (tks === "") return;
            const normalizedCompanyId = standardizeCompanyId(tks);
            formattedCompanyIDs.add(normalizedCompanyId);
        });
        return formattedCompanyIDs;
    }

    const formatCompanyIds = (companyIds) => {
        if (companyIds !== null && companyIds.length > 0) {
            if (Array.isArray(companyIds))
                return companyIds.filter(ticker => ticker !== "," || ticker !== "" || ticker !== " ");
            const formattedTickers = parseCompanyIds(companyIds);
            return [...formattedTickers].map(t => t.toString().trim()).filter(t => t !== "" && t !== ",");
        }
    }

    const handleModalClosing = () => {
        setCompanyIDs('');
        setAnalysisType('');
        setError('');
        setHasError(false);
        setFormattedCompanyIDs([]);
        onClose();
    }

    const handleSettingCompanyIds = () => {
        setError('');
        setHasError(false);
        if (companyIDs.length > 0) {
            const formattedCompanyIds = formatCompanyIds(companyIDs);
            setFormattedCompanyIDs(formattedCompanyIds);
            const hasIncorrectFormat = formattedCompanyIds.some(companyId => {
                return companyId.toUpperCase().includes(PRIVATE_COMPANY_IQ_CODE) ? false : !validateCompanyyId(companyId);
            });
            if (hasIncorrectFormat) {
                setError("Company IDs must be in the format of 'Exchange:Ticker' or 'IQ' followed by a number.");
                setHasError(true);
                return;
            }
            handleCompanyRequests(formattedCompanyIds, analysisType);
        }
        handleModalClosing();
    }

    return (
        <Modal className="bulk-company-requests-modal" show={showModal} onHide={() => handleModalClosing()}>
            <Modal.Header closeButton>
                <Modal.Title>Add Companies</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {hasError && <Alert variant="danger">{error}</Alert>}
                <Form.Group>
                    <Form.Label>Company IDs (Exchange : Ticker)</Form.Label>
                    {formattedCompanyIDs.length > 0 ?
                        <ToolTipWrapper
                            placement={"top"}
                            id="number-of-companies-badge"
                            text="Number of unique tickers"
                            element={<Badge style={{ fontSize: "0.6rem", marginLeft: "0.25rem" }}
                                bg="secondary">{Array.isArray(formattedCompanyIDs) ? formattedCompanyIDs.length : 1}</Badge>} /> : ""}

                    <Form.Control required as="textarea" rows={2} value={companyIDs} onChange={(e) => setCompanyIDs(e.target.value)} />
                    <Form.Text className="text-muted" style={{ fontSize: "0.7rem" }}>
                        Enter company IDs separated by commas. Example: NASDAQ:AAPL, NASDAQ:MSFT, NYSE:IBM, LSE:BRBY. Copy and paste from Excel is also supported.
                    </Form.Text>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Analysis Type</Form.Label>
                    <Form.Control required as="select" value={analysisType} onChange={(e) => setAnalysisType(e.target.value)}>
                        <option value="">Select analysis type</option>
                        {activeTemplates.map(template => <option key={template.templateId} value={template.templateId}>{template.templateName}</option>)}
                    </Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleModalClosing()}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => handleSettingCompanyIds()}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

BulkCompanyRequestModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    activeTemplates: PropTypes.array.isRequired,
    handleCompanyRequests: PropTypes.func.isRequired
}

export default BulkCompanyRequestModal;