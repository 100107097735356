import React from 'react'
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap'

export default function DataRequestStatusDropDown({ requestStatus, onRequestStatusChange }) {
    return (
        <Form.Group controlId="requestStatus">
            <Form.Label>Request Status</Form.Label>
            <Form.Control
                as="select"
                value={requestStatus}
                className="col-md-2"
                onChange={(e) => onRequestStatusChange(e.target.value)}
                style={{ width: "10rem" }}
            >
                <option value="">Select Status</option>
                <option value="PENDING">Pending</option>
                <option value="RESOLVED">Resolved</option>
                <option value="CANCELLED">Cancelled</option>
            </Form.Control>
        </Form.Group>
    )
}

DataRequestStatusDropDown.propTypes = {
    requestStatus: PropTypes.string,
    onRequestStatusChange: PropTypes.func
}
