import PropTypes from 'prop-types'
import { Badge, Button, Col, Form, InputGroup, Row } from "react-bootstrap";

import { Link } from "@aws-amplify/ui-react";
import { useState } from "react";
import { formatTickers } from "../../Constants";
import ToolTipWrapper from '../../CommonComponents/ToolTipComponent/ToolTipWrapper';
import BulkTickerInputModal from '../BulkTickerInputModal/BulkTickerInputModal';
import TickerLookupModal from '../TickerLookupModal/TickerLookupModal';

const BaseCompensationDataInputs = ({ tickers, handleSettingTickers, handleSearch, searching, selectedTemplateId, areTickersRequired, years, setYears }) => {
    const [showBulkTickerInputModal, setShowBulkTickerInputModal] = useState(false);
    const [showTickerLookupModal, setShowTickerLookupModal] = useState(false);

    const handlePastedTickers = (e) => {
        e.preventDefault();
        const pastedTickers = e.clipboardData.getData('text').split(",");
        if (pastedTickers.length === 1) {
            handleSettingTickers(formatTickers(pastedTickers[0]));
            return;
        }
        handleSettingTickers(formatTickers(pastedTickers));
    }

    return (
        <>
            {selectedTemplateId && selectedTemplateId !== "" ?
                <Row className="mt-3">
                    <Col md={6} lg={6}>
                        <Form.Group controlId="tickers" className="tickers-input">
                            <Form.Label>
                                <span style={{ marginRight: "0.25rem" }}>Ticker(s)</span>
                                <ToolTipWrapper
                                    id="tickers-tooltip"
                                    text="Enter a ticker or multiple tickers separated by commas or new lines. Copy and paste from Excel is also supported."
                                    element={<i className="fa fa-question-circle"></i>}
                                    placement={"top"}
                                />
                            </Form.Label>
                            {tickers.length > 0 ?
                                <ToolTipWrapper
                                    placement={"top"}
                                    id="number-of-companies-badge"
                                    text="Number of unique tickers"
                                    element={<Badge style={{ fontSize: "0.6rem", marginLeft: "0.25rem" }}
                                        bg="secondary">{Array.isArray(tickers) ? tickers.length : 1}</Badge>} /> : ""}
                            <BulkTickerInputModal
                                initialTickers={tickers}
                                disabled={searching}
                                onClose={() => setShowBulkTickerInputModal(false)}
                                isOpen={showBulkTickerInputModal}
                                onAddTickers={(formattedTickers) => handleSearch(formattedTickers)}
                            />
                            <TickerLookupModal isOpen={showTickerLookupModal} onClose={() => setShowTickerLookupModal(false)} onAddTickers={handleSettingTickers} />
                            <InputGroup>
                                <Form.Control
                                    disabled={searching}
                                    className="ticker-base-input"
                                    type="text"
                                    isInvalid={!areTickersRequired}
                                    placeholder="e.g. AAPL, MSFT, GOOG, etc."
                                    value={tickers}
                                    onChange={(e) => handleSettingTickers(e.target.value)}
                                    onPaste={(e) => handlePastedTickers(e)}
                                    autoComplete="off"
                                ></Form.Control>
                                <InputGroup.Text title="Expand to view all tickers">
                                    <Button className="expand-tickers-view-btn" disabled={searching} onClick={() => setShowBulkTickerInputModal(true)}>
                                        <i className="fa fa-expand-alt"></i>
                                    </Button>
                                </InputGroup.Text>
                                <Form.Control.Feedback type="invalid">Please enter a ticker</Form.Control.Feedback>
                            </InputGroup>
                            <div className="company-lookup">
                                <Link className="ticker-link" onClick={() => setShowTickerLookupModal(true)}>Find Ticker(s) <i className="fa fa-search"></i></Link>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={3} lg={3}>
                        <Form.Group controlId="yearLookBack">
                            <Form.Label>
                                <span style={{ marginRight: "0.25rem" }}>Years to Look Back</span>
                                <ToolTipWrapper
                                    id="years-tooltip"
                                    text={<p>Select the number of years to look back for data<br/><b>1 year</b> will return the most recent year of data available.</p>}
                                    element={<i className="fa fa-question-circle"></i>}
                                    placement={"top"}
                                />
                            </Form.Label>
                            <Form.Range
                                type="number"
                                placeholder="Latest Year"
                                value={years}
                                min={1}
                                max={10}
                                disabled={searching || tickers.length === 0}
                                onChange={e => {
                                    const value = Number(e.target.value);
                                    if (value < 1) {
                                        return;
                                    }
                                    setYears(value);
                                }} />
                                <Form.Text className="text-muted">
                                    {years} year{years > 1 ? "s" : ""}
                                </Form.Text>
                        </Form.Group>
                    </Col>
                </Row> : ""}
        </>
    );
}

export default BaseCompensationDataInputs;

BaseCompensationDataInputs.propTypes = {
    tickers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    handleSettingTickers: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    searching: PropTypes.bool.isRequired,
    selectedTemplateId: PropTypes.string,
    areTickersRequired: PropTypes.bool.isRequired,
    years: PropTypes.number.isRequired,
    setYears: PropTypes.func.isRequired
}
