import { useContext } from "react"
import PropTypes from "prop-types"
import { FilterContext } from "../Contexts/FilterContext"
import { useFocuRef } from "../Hooks/useFocusRef";

export const ColumnFilterRenderer = ({ isCellSelected, column, children, sortDirection, onSort }) => {
    const filters = useContext(FilterContext);
    const { ref, tabIndex } = useFocuRef(isCellSelected);

    const sortIcon = (sortDirection, onSort) => {
        if (!sortDirection) {
            return <i style={{ fontSize: "1rem" }} className="fa fa-sort" onClick={onSort}></i>;
        }
        if (sortDirection === 'ASC') {
            return <i style={{ fontSize: "1rem" }} className="fa fa-sort-up" onClick={onSort}></i>;
        }
        return <i style={{ fontSize: "1rem" }} className="fa fa-sort-down" onClick={onSort}></i>;
    }

    return (
        <>
            {column.sortable ? <div>
                <span tabIndex={0} className="rdg-header-sort-cell h1tr5c9i700-beta12" onClick={onSort}>
                    <span className="rdg-header-sort-name h19r0msv700-beta12">{column.name}</span>
                    <span>{sortIcon(sortDirection, onSort)}</span>
                </span>
            </div> : <div><span tabIndex={-1} className="rdg-header h1tr5c9i700-beta12">
                <span className="rdg-header h19r0msv700-beta12">{column.name}</span>
            </span></div>}
            {(filters?.enabled) ? <div>{children({ ref, tabIndex, filters })}</div> : null}
        </>
    );
}

ColumnFilterRenderer.propTypes = {
    isCellSelected: PropTypes.bool,
    column: PropTypes.object,
    children: PropTypes.func,
    sortDirection: PropTypes.string,
    onSort: PropTypes.func
}