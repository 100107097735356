import React from "react";

const currencyFormatter = new Intl.NumberFormat(navigator.language,
	{
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0
	}
);

const numberFormatter = new Intl.NumberFormat(navigator.language,
	{
		maximumFractionDigits: 1,
		minimumFractionDigits: 0
	}
);

export const PercentageFormatter = (obj) => {
	const formatter = new Intl.NumberFormat(navigator.language, {
		style: 'percent',
		minimumFractionDigits: 1,
		maximumFractionDigits: 1
	});
	if(isNaN(obj.value)){
		return <>{obj.value}</>;
	} else{
		return <>{formatter.format(obj.value)}</>;
	}
}

export const CurrencyFormatter = (obj) => {
	if (isNaN(obj.value)) {
		return <>{obj.value}</>;
	} else {
		return <>{currencyFormatter.format(obj.value)}</>;
	}
}

export const NumberFormatter = (obj) => {
	if (isNaN(obj.value)) {
		return <>{obj.value}</>;
	} else {
		return <>{numberFormatter.format(obj.value)}</>;
	}
}

export const HyperLinkFormatter = (obj) => {
	const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
	if (urlRegex.test(obj.value)) {
	  return <a href={obj.value} target="_blank" rel="noreferrer">{obj.value}</a>;
	} else {
	  return <>{obj.value}</>;
	}
  }