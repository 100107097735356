import React, { createContext, useState, useCallback } from 'react';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  const showToast = useCallback((message) => {
    setMessage(message);
    setShow(true);
  }, []);

  return (
    <ToastContext.Provider value={{ show, message, showToast, setShow }}>
      {children}
    </ToastContext.Provider>
  );
};