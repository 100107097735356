import { Auth } from "aws-amplify";

export async function getAuthToken() {
    try {
        const currentSession = (await Auth.currentSession());
        return currentSession.getIdToken().getJwtToken();
    } catch (error) {
        await Auth.signOut({ global: true });
        // reload the page to get the user to the login page
        window.location.reload();
    }
}