import { API, Analytics } from "aws-amplify";
import { getAuthToken } from "../../utils/AuthUtils";
import { useEffect, useState } from "react";
import { Alert, Button, Spinner, Form } from "react-bootstrap";
import { createUpdateRequestBody, exportUsageDowloadDataToXlsx, billableTypeVals, billableTypeMap } from "../DataDownloadHistoryUtils/DataDownloadHistoryUtils";
import { rowActionsComponent } from "../RowActionsComponent/RowActionsComponent";
import { billableTypeComponent } from "../BillableTypesComponent/BillableTypesComponent";
import SimpleGridComponent from "../../SimpleGridComponent/SimpleGridComponent";

const API_PATH = "/usage-data";

const userNotesComponent = (userHistoryRecordsStateMap, row, cell, handleUserNotesChange) => {
  return <div key={row.uniqueId}>
    {userHistoryRecordsStateMap.get(row.uniqueId) ?
      <div>
        <Form.Control
          as="textarea"
          rows={cell.length > 20 ? 5 : 1}
          value={cell || ""}
          maxLength="250"
          isInvalid={row.billableType !== billableTypeVals.billable && cell.length === 0}
          onChange={(e) => handleUserNotesChange(e, row.uniqueId)} />
        <Form.Control.Feedback type="invalid">
          User notes are required.
        </Form.Control.Feedback>
      </div>
      : cell}
  </div>;
}

const clientOwnerNameComponent = (userHistoryRecordsStateMap, row, cell, handleClientOwnerNameChange, checkClientOwnerNameInvalid) => {
  return <div key={row.uniqueId}>
    {userHistoryRecordsStateMap.get(row.uniqueId) ?
      <div>
        <Form.Control
          type="text"
          required={row.billable === billableTypeVals.billable}
          value={cell || ""}
          onChange={(e) => handleClientOwnerNameChange(e, row.uniqueId)}
          isInvalid={checkClientOwnerNameInvalid(row.uniqueId)} />
        <Form.Control.Feedback type="invalid">
          {cell && cell.split(" ").length !== 2 ? "Please enter first and last name." : "Please enter a client owner name."}
        </Form.Control.Feedback>
      </div>
      : cell}
  </div>;
}

const clientNameComponent = (userHistoryRecordsStateMap, row, cell, handleClientNameChange) => {
  return <div key={row.uniqueId}>
    {userHistoryRecordsStateMap.get(row.uniqueId) ?
      <div>
        <Form.Control
          type="text"
          required={row.billableType === billableTypeVals.billable}
          value={cell || ""}
          onChange={(e) => handleClientNameChange(e, row.uniqueId)}
          isInvalid={row.billableType === billableTypeVals.billable && cell === ""} />
        <Form.Control.Feedback type="invalid">
          Please enter a client name.
        </Form.Control.Feedback>
      </div>
      : cell}
  </div>;
}

const UserDataDownloadHistoryTab = () => {

  const [loading, setLoadingStatus] = useState(false);

  const [showHeaderFilters, setShowHeaderFilters] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [userHistory, setUserHistory] = useState([]);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const [success, setSuccess] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [userHistoryRecordsStateMap, setUserHistoryRecordsStateMap] = useState(new Map());

  const columns = [{
    "key": "fullDateAccessed",
    "name": "Date Accessed",
    "sortable": true,
    "filterable": true
  },
  {
    "key": "templateName",
    "name": "Template Name",
    "sortable": true,
    "filterable": true,
  },
  {
    "key": "numberOfCompaniesAccessed",
    "name": "No. of Companies Accessed",
    "sortable": true,
    "filterable": true
  },
  {
    "key": "billableType",
    "name": "Billing Type",
    "sortable": false,
    "filterable": false,
    "filterType": "dropdown",
    "filterOptions": [
      { "value": billableTypeVals.billable, "key": "Billable" },
      { "value": billableTypeVals.nonBillableRFP, "key": "Non Billable - RFP" },
      { "value": billableTypeVals.nonBillableOther, "key": "Non Billable - Other" }
    ],
    "formatter": (cell, row) => {
      return (
        billableTypeComponent(userHistoryRecordsStateMap, row.uniqueId, cell, handleBillableTypeChange)
      );
    }
  },
  {
    "key": "clientName",
    "name": "Client Name",
    "sortable": false,
    "filterable": false,
    "formatter": (cell, row) => {
      return (
        clientNameComponent(userHistoryRecordsStateMap, row, cell, handleClientNameChange)
      );
    }
  },
  {
    "key": "clientOwnerName",
    "name": "Client Owner Name",
    "sortable": false,
    "filterable": false,
    "formatter": (cell, row) => {
      return (
        clientOwnerNameComponent(userHistoryRecordsStateMap, row, cell, handleClientOwnerNameChange, checkClientOwnerNameInvalid)
      );
    }
  },
  {
    "key": "userNotes",
    "name": "User Notes",
    "sortable": false,
    "filterable": false,
    "formatter": (cell, row) => {
      return (
        userNotesComponent(userHistoryRecordsStateMap, row, cell, handleUserNotesChange)
      );
    }
  },
  {
    "key": "actions",
    "name": "Actions",
    "sortable": false,
    "filterable": false,
    "formatter": (_, row) => {
      return (
        rowActionsComponent(userHistoryRecordsStateMap, row.uniqueId, handleHistoryRecordUpdate, handleRowStateUpdate)
      );
    }
  }
  ];

  const handleError = (error) => {
    setError(error);
    setShowError(true);
    setSuccess(null);
    setShowSuccess(false);
  }

  const handleBillableTypeChange = (event, uniqueId) => {
    const updatedUserHistory = [...userHistory];
    const index = updatedUserHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
    updatedUserHistory[index].billableType = event.target.value;
    setUserHistory(updatedUserHistory);
  }

  const handleClientNameChange = (event, uniqueId) => {
    const updatedUserHistory = [...userHistory];
    const index = updatedUserHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
    updatedUserHistory[index].clientName = event.target.value;
    setUserHistory(updatedUserHistory);
  }

  const handleClientOwnerNameChange = (event, uniqueId) => {
    const updatedUserHistory = [...userHistory];
    const index = updatedUserHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
    updatedUserHistory[index].clientOwnerName = event.target.value;
    updatedUserHistory[index].clientOwnerEmail = "";
    updatedUserHistory[index].clientOwnerUserId = "";
    setUserHistory(updatedUserHistory);
  }

  const checkClientOwnerNameInvalid = (uniqueId) => {
    const index = userHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
    const name = userHistory[index].clientOwnerName || "";
    const billableType = userHistory[index].billableType
    return billableType === billableTypeVals.billable && name.split(" ").length !== 2;
  }

  const handleUserNotesChange = (event, uniqueId) => {
    const updatedUserHistory = [...userHistory];
    const index = updatedUserHistory.findIndex((userHistoryRecord) => userHistoryRecord.uniqueId === uniqueId);
    updatedUserHistory[index].userNotes = event.target.value;
    setUserHistory(updatedUserHistory);
  }

  const handleSettingSuccessMessage = (message) => {
    setSuccess(message);
    setShowSuccess(true);
    window.setTimeout(() => {
      setShowSuccess(false);
    }, 3000);
    setError(null);
    setShowError(false);
  }

  const normalizeData = (data) => {
    return data.map(company => {
      const rowRecord = {
        "Template Name": company.templateName,
        "Date Accessed": company.fullDateAccessed,
        "Billing Type": billableTypeMap[company.billableType],
        "No. of Companies Accessed": company.numberOfCompaniesAccessed,
        "Client Name": company.clientName,
        "Client Owner Name": company.clientOwnerName,
        "User Notes": company.userNotes
      };
      return rowRecord;
    });
  }

  const flipRowEditState = (uniqueId) => {
    const updatedUserHistoryRecordsStateMap = new Map(userHistoryRecordsStateMap);
    const currentState = updatedUserHistoryRecordsStateMap.get(uniqueId);
    updatedUserHistoryRecordsStateMap.set(uniqueId, !currentState);
    setUserHistoryRecordsStateMap(updatedUserHistoryRecordsStateMap);
  }

  const handleRowStateUpdate = (e, uniqueId) => {
    e.preventDefault();
    flipRowEditState(uniqueId);
  }

  const handleHistoryRecordUpdate = async (event, uniqueId) => {
    const request = createUpdateRequestBody(event, uniqueId, userHistory);
    const token = await getAuthToken();
    API.put("AnnualCompensations",
      API_PATH, {
      headers: {
        'Token': token
      },
      body: request
    }).then((response) => {
      handleSettingSuccessMessage(response.message);
      flipRowEditState(uniqueId, userHistoryRecordsStateMap, setUserHistoryRecordsStateMap);
    }).catch((error) => {
      if (error?.response?.data?.message) {
        handleError(error.response.data.message);
      } else {
        handleError("An error occurred while updating monthly download data. Please try again later.");
      }
      console.log(error.response);
    });
    setIsFormValid(true);
  }

  const handleUserHistoryRecordsStateMap = (data) => {
    const userHistoryRecordsStateMap = new Map();
    data.forEach((userHistoryRecord) => {
      userHistoryRecordsStateMap.set(userHistoryRecord.uniqueId, false);
    });
    setUserHistoryRecordsStateMap(userHistoryRecordsStateMap);
  }

  const fetchUserDownloadHistory = async () => {
    const authToken = await getAuthToken();
    const response = await API.get("AnnualCompensations",
      API_PATH, {
      headers: {
        'Token': authToken
      }
    });
    return response;
  };

  const fetchUserHistory = async () => {
    setLoadingStatus(true);
    fetchUserDownloadHistory()
      .then((response) => {
        setLoadingStatus(false);
        if (response?.data) {
          setUserHistory(response.data);
          handleUserHistoryRecordsStateMap(response.data, setUserHistoryRecordsStateMap);
        } else {
          setUserHistory([]);
          handleUserHistoryRecordsStateMap([], setUserHistoryRecordsStateMap);
        }
      }).catch((error) => {
        setLoadingStatus(false);
        if (error?.response?.data?.message) {
          handleError(error.response.data.message);
        }
      });
  };

  const toggleHeaderFilters = () => {
    setShowHeaderFilters(!showHeaderFilters);
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Analytics.record({ name: 'userHistoryModalOpened' });
      fetchUserHistory();
    } else {
      setUserHistory([]);
      setError(null);
      setShowError(false);
      setSuccess(null);
      setShowSuccess(false);
      setIsFormValid(false);
      setCurrentPage(1);
    }
    return () => { isMounted = false; }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="user-download-history">
      {showError && <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
        <p>
          <strong>Error: </strong>{error}
        </p>
      </Alert>}
      {showSuccess && <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
        <p>
          <strong>Success: </strong>{success}
        </p>
      </Alert>}
      {loading ? <div className="loading">
        Loading...
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true">
        </Spinner>
      </div> :
        <div className="user-history-container">
          <div className="d-flex justify-content-start mb-2">
            <Button title='Toggle Grid Header Filters' variant='primary' onClick={toggleHeaderFilters} style={{ padding: "0.25rem", maxWidth: "2rem", marginRight: "0.2rem", marginLeft: "0.1rem" }}>
              {showHeaderFilters ? <i className="fa fa-sort-amount-up-alt" style={{ padding: "0.25rem" }}></i> : <i className="fa fa-sort-amount-down-alt" style={{ padding: "0.25rem" }}></i>}
            </Button>
            <Button title='Export grid to excel' variant='primary' onClick={() => exportUsageDowloadDataToXlsx(userHistory, normalizeData)} style={{ padding: "0.25rem", marginRight: "0.2rem", maxWidth: "2rem" }}>
              <i className="fa fa-file-csv" style={{ padding: "0.25rem" }}></i>
            </Button>
          </div>
          <Form noValidate validated={isFormValid} onSubmit={(e) => e.preventDefault()}>
            <div className="user-history">
              {userHistory.length > 0 ?
                <SimpleGridComponent
                  columns={columns}
                  data={userHistory}
                  enableRowSelection={false}
                  showHeaderFilters={showHeaderFilters}
                  selectedCurrentPage={currentPage}
                  onCurrentPageSelect={(page) => setCurrentPage(page)}
                  itemsName="User History records"
                />
                : <div className="no-data">No data to display</div>}
            </div>
          </Form>
        </div>
      }
    </div>)
};

export default UserDataDownloadHistoryTab;