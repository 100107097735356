import { Form } from "react-bootstrap";
import { billableTypeVals, billableTypeMap } from "../DataDownloadHistoryUtils/DataDownloadHistoryUtils";

export const billableTypeComponent = (userHistoryRecordsStateMap, uniqueId, cell, handleBillableTypeChange) => {
    return <div key={uniqueId}>
        {userHistoryRecordsStateMap.get(uniqueId) ?
            <div>
                <Form.Control as="select" required={true} value={cell} onChange={(e) => handleBillableTypeChange(e, uniqueId)}>
                    <option value={billableTypeVals.billable}>{billableTypeMap[billableTypeVals.billable]}</option>
                    <option value={billableTypeVals.nonBillableRFP}>{billableTypeMap[billableTypeVals.nonBillableRFP]}</option>
                    <option value={billableTypeVals.nonBillableOther}>{billableTypeMap[billableTypeVals.nonBillableOther]}</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                    Please select a billing type.
                </Form.Control.Feedback>
            </div> : billableTypeMap[cell]}
    </div>;
}