import { API } from "aws-amplify";
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Form } from "react-bootstrap";
import { getAuthToken } from "../../../utils/AuthUtils";
import SearchResultDisplay from '../SearchResultDisplayComponent/SearchResultDisplay';
import PropTypes from 'prop-types';

const ClientOwnerInputComponent = ({ clientOwnerNameLookupTerm, setClientOwnerNameLookupTerm, setSelectedClientOwnerUserRecord, clientOwnerNameInvalid, setClientOwnerNameInvalid, clientOwnerNameErrorMessage, label = "FWC Client Owner Name" }) => {
    const clientOwnerFormGroupRef = React.createRef();
    const [clientOwnerNameInputFocused, setClientOwnerNameInputFocused] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [userSearchResults, setUserSearchResults] = useState([])
    const [showUserSearchResults, setShowUserSearchResults] = useState(false)
    const [selectedResultIndex, setSelectedResultIndex] = useState(0);

    const debounce = (func, delay) => {
        let inDebounce
        return function () {
            const context = this
            const args = arguments
            clearTimeout(inDebounce)
            inDebounce = setTimeout(() => func.apply(context, args), delay)
        }
    }

    const lookupUsers = async (searchPhrase) => {
        setLoadingUsers(true);
        setHasError(false);
        setSelectedClientOwnerUserRecord({});
        searchPhrase = searchPhrase.toLowerCase()
        const queryStringParameters = { searchPhrase }
        const authToken = await getAuthToken();
        API.get("AnnualCompensations", '/users', {
            headers: {
                'Token': authToken,
            },
            queryStringParameters
        })
            .then((response) => {
                setUserSearchResults(response)
                setLoadingUsers(false);
                setShowUserSearchResults(true);
                setHasError(false)
            })
            .catch((error) => {
                console.log(error)
                setHasError(true);
                setLoadingUsers(false)
                setShowUserSearchResults(true)
            })
    }

    const handleUserSelection = (actionType, selectedIndex) => {
        if (userSearchResults.length > 0) {
            const selectedUserRecord = actionType === "click" ? userSearchResults[selectedIndex] : userSearchResults[selectedResultIndex];
            if (selectedUserRecord === undefined) {
                setClientOwnerNameInvalid(true);
                setSelectedClientOwnerUserRecord({});
                return;
            }
            setClientOwnerNameLookupTerm(selectedUserRecord.userName);
            setSelectedClientOwnerUserRecord(selectedUserRecord);
            setShowUserSearchResults(false);
        }
    }

    const handleUserSearch = (e) => {
        const name = e.target.value;
        setClientOwnerNameLookupTerm(name);
        if (name.length > 0) {
            debounceChangeUserHandler(name);
        }
    }

    // eslint-disable-next-line
    const debounceChangeUserHandler = useCallback(debounce(lookupUsers, 250), []);

    const handleClose = () => {
        setLoadingUsers(false);
        setHasError(false);
        setUserSearchResults([]);
        setShowUserSearchResults(false);
    }

    useEffect(() => {
        const formGroupElement = clientOwnerFormGroupRef.current;
        const handleFocusOut = (event) => {
            if (clientOwnerFormGroupRef.current && !clientOwnerFormGroupRef.current.contains(event.relatedTarget)) {
                setClientOwnerNameInputFocused(false);
            }
        };

        if (formGroupElement) {
            formGroupElement.addEventListener('focusout', handleFocusOut);
        }

        return () => {
            if (formGroupElement) {
                formGroupElement.removeEventListener('focusout', handleFocusOut);
            }
        };
    }, [clientOwnerFormGroupRef]);

    return (
        <Form.Group className="mb-2" controlId="clientNameforDataExportForm.clientOwnerName" ref={clientOwnerFormGroupRef}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type="text"
                placeholder={`Please enter ${label} (e.g. John Doe)`}
                onSubmit={handleClose}
                onFocus={() => setClientOwnerNameInputFocused(true)}
                value={clientOwnerNameLookupTerm}
                onChange={(event) => handleUserSearch(event)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault();
                        if (userSearchResults.length > 0) {
                            const selectedUser = userSearchResults[selectedResultIndex];
                            if (selectedUser !== undefined) {
                                setClientOwnerNameLookupTerm(selectedUser.userName);
                                setSelectedClientOwnerUserRecord(selectedUser);
                            } else {
                                setClientOwnerNameInvalid(true);
                                setSelectedClientOwnerUserRecord({});
                            }
                            setShowUserSearchResults(false);
                        }
                    }
                }}
                isInvalid={clientOwnerNameInvalid} />
            <Form.Control.Feedback type="invalid">{clientOwnerNameErrorMessage}</Form.Control.Feedback>
            {clientOwnerNameInputFocused && <SearchResultDisplay
                showSearchResults={showUserSearchResults}
                searchTerm={clientOwnerNameLookupTerm}
                searchResults={userSearchResults}
                resultItem={"userName"}
                handleUpdatingSelectedIndex={(index) => {
                    setSelectedResultIndex(index);
                }}
                loadingResults={loadingUsers}
                handleSelection={(actionType, selectedIndex) => handleUserSelection(actionType, selectedIndex)}
                selectedResultIndex={selectedResultIndex}
                setSelectedResultIndex={setSelectedResultIndex}
            />}
            {hasError ? <Alert variant="danger" className="user-lookup-error-alert" dismissible>
                There was an error retrieving the user information. Please try again later.
            </Alert> : null}
        </Form.Group>
    )
}

ClientOwnerInputComponent.propTypes = {
    clientOwnerNameLookupTerm: PropTypes.string,
    setClientOwnerNameLookupTerm: PropTypes.func,
    setSelectedClientOwnerUserRecord: PropTypes.func,
    clientOwnerNameInvalid: PropTypes.bool,
    setClientOwnerNameInvalid: PropTypes.func,
    clientOwnerNameErrorMessage: PropTypes.string,
    label: PropTypes.string
}

export default ClientOwnerInputComponent;