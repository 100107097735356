import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, Modal, Spinner } from 'react-bootstrap';

import './styles.scss';

const unrestrictedSurveyDataAPI = process.env.REACT_APP_UNRESTRICTED_SURVEY_DATA_API;

export default function QuestionnaireUploadModal({ show, onClose, participantId, participantName }) {
    const [isUploading, setIsUploading] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [warningMessage, setWarningMessage] = useState(null);
    const [showWarningMessage, setShowWarningMessage] = useState(false);

    const [email, setEmail] = useState('');
    const [filePassword, setFilePassword] = useState('');

    const fetchUploadLink = async () => {
        const response = await axios
            .get(`${unrestrictedSurveyDataAPI}/survey-questionnaire-submission/file-upload-link?participantId=${participantId}&participantName=${participantName}`)
        return { url: response.data.url, fileKey: response.data.fileKey };
    }

    const downloadCSV = (csvString) => {
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = "ECS_Survey_Validation.csv";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const handleUploadedFileValidation = async (fileKey) => {
        try {
            const response = await axios.post(`${unrestrictedSurveyDataAPI}/survey-questionnaire-submission`, {
                fileKey: fileKey,
                participantName: participantName,
                participantId: participantId,
                submitterEmail: email,
                filePassword: filePassword
            });
            processFileUploadResponse(response);
        } catch (error) {
            console.log(error);
            // if bad request, show warning message
            if (error?.response?.status === 400) {
                setErrorMessage(error.response.data.message);
                setShowErrorMessage(true);
            }
            throw error;
        }
    }

    const processFileUploadResponse = (response) => {
        const { success, validationErrorCSV, errorMessage } = response.data;

        if (validationErrorCSV) {
            downloadCSV(validationErrorCSV);
        }

        if (success) {
            const message = validationErrorCSV
                ? "File uploaded successfully, but there are validation errors. Please view the validation errors in the downloaded file"
                : "File uploaded successfully pending further review";
            validationErrorCSV ? handleDisplayWarningMessage(message) : handleDisplayingSuccessMessage();
        } else if (errorMessage) {
            handleDisplayErrorMessage(errorMessage);
        } else {
            handleDisplayErrorMessage('Your submission is missing required fields. Please view the validation errors in the downloaded file');
        }
    }

    const handleDisplayingSuccessMessage = () => {
        setSuccessMessage('File uploaded successfully');
        setShowSuccessMessage(true);
        setTimeout(() => {
            setShowSuccessMessage(false);
            handleModalClose();
        }, 10000);
    }

    const handleDisplayWarningMessage = (message) => {
        setWarningMessage(message);
        setShowWarningMessage(true);
    }

    const handleDisplayErrorMessage = (message) => {
        setErrorMessage(message);
        setShowErrorMessage(true);
    }

    const handleFileUpload = async (event) => {
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setShowWarningMessage(false);
        setSuccessMessage('');
        setErrorMessage('');
        setWarningMessage('');
        try {
            event.preventDefault();
            const fileInput = document.querySelector('input[type="file"]');
            if (fileInput.files.length > 0) {
                if (!email) {
                    setErrorMessage('Email is required');
                    setShowErrorMessage(true);
                    return;
                }
                setIsUploading(true);
                const { url, fileKey } = await fetchUploadLink();
                const file = fileInput.files[0];
                await axios.put(url, file, {
                    headers: {
                        'Content-Type': file.type
                    }
                });
                await handleUploadedFileValidation(fileKey);
                setIsUploading(false);
            } else {
                setErrorMessage('Please select a file to upload');
                setShowErrorMessage(true);
                setIsUploading(false);
            }
        } catch (error) {
            console.log(error);
            setIsUploading(false);
        }
    }

    const handleModalClose = () => {
        onClose();
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setShowWarningMessage(false);
        setSuccessMessage('');
        setErrorMessage('');
        setWarningMessage('');
        setFilePassword('');
        setIsUploading(false);
        setEmail('');
    }

    return (
        <Modal show={show} onHide={handleModalClose} className='questionnaire-upload-modal' centered>
            <Modal.Header closeButton>
                <Modal.Title>Secure Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='upload-questionnaire-instructions'>
                    <p>You will receive a confirmation message below if upload is successful. This means we've received your file, but we may still reach out for further details.</p>
                </div>
                <div className='file-input-container'>
                    <input type='file' className='file-input' />
                </div>
                <div className='email-input-container'>
                    <label htmlFor='email-input' className='email-label'>Email (Required):</label>
                    <input
                        id='email-input'
                        type='email'
                        className='email-input'
                        placeholder='Enter your email'
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        required
                    />
                </div>
                <div className='password-input-container'>
                    <label htmlFor='password-input' className='password-label'>Password (Optional):</label>
                    <input
                        id='password-input'
                        type='password'
                        className='password-input'
                        placeholder='Enter file password'
                        value={filePassword}
                        onChange={(event) => setFilePassword(event.target.value)}
                    />
                </div>
                {showSuccessMessage && <Alert variant='success'>{successMessage}</Alert>}
                {showErrorMessage && <Alert variant='danger'>{errorMessage}</Alert>}
                {showWarningMessage && <Alert variant='warning'>{warningMessage}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                <div className='actions-buttons-container'>
                    <button className='btn btn-secondary' onClick={handleModalClose}>Cancel</button>
                    <button
                        className='btn btn-primary'
                        disabled={isUploading}
                        onClick={handleFileUpload}>
                        {isUploading ? <span>Uploading...<Spinner
                            animation="grow"
                            size="sm"
                            aria-hidden="true" /></span> :
                            <span>Upload <i className="fa fa-upload"></i></span>}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

QuestionnaireUploadModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    participantId: PropTypes.string.isRequired,
    participantName: PropTypes.string.isRequired
}