import { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { Alert, Button, Form, Modal, Spinner } from "react-bootstrap";
import DismissiblePill from "../../CommonComponents/DismissiblePill/DismissiblePill";
import { getAuthToken } from "../../utils/AuthUtils";

import { Link } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { ToastContext } from "../../Contexts/ToastProvider";
import { useKeyPress } from "../../Hooks/useKeyPress";
import "./styles.scss";

const TickerLookupModal = ({ onAddTickers, isOpen, onClose }) => {
    const [loadingCompanies, setLoadingCompanies] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [tickers, setTickers] = useState([]);
    const [companyLookupSearchTerm, setCompanyLookupSearchTerm] = useState("");
    const [showCompanySearchResults, setShowCompanySearchResults] = useState(false);
    const [selectedResultIndex, setSelectedResultIndex] = useState(0);
    const [companyLookupResults, setCompanyLookupResults] = useState([]);

    const handleTickers = (selectedTicker) => {
        const newTickers = [...new Set([...tickers, ...selectedTicker])];
        setTickers(newTickers);
    }

    const debounce = (func, delay) => {
        let inDebounce
        return function () {
            const context = this
            const args = arguments
            clearTimeout(inDebounce)
            inDebounce = setTimeout(() => func.apply(context, args), delay)
        }
    }

    const lookupTickers = async (searchPhrase) => {
        setLoadingCompanies(true);
        setHasError(false);
        const queryStringParameters = { searchPhrase }
        const authToken = await getAuthToken();
        API.get("AnnualCompensations", '/tickers', {
            headers: {
                'Token': authToken,
            },
            queryStringParameters
        })
            .then((response) => {
                setCompanyLookupResults(response)
                setLoadingCompanies(false);
                setShowCompanySearchResults(true);
                setHasError(false)
            })
            .catch((error) => {
                console.log(error);
                setHasError(true);
                setLoadingCompanies(false);
                setShowCompanySearchResults(true);
            });
    }

    const handleTickerSelection = (actionType, selectedIndex) => {
        if (companyLookupResults.length > 0) {
            const selectedTicker = actionType === "click" ? companyLookupResults[selectedIndex] : companyLookupResults[selectedResultIndex];
            handleTickers([selectedTicker.ticker]);
            setCompanyLookupSearchTerm("");
            setShowCompanySearchResults(false);
        }
    }

    const handleCompanyLookup = (e) => {
        const tickers = e.target.value.split(",");
        const lastTicker = tickers[tickers.length - 1];
        setCompanyLookupSearchTerm(lastTicker);
        if (lastTicker.length > 0) {
            debounceChangeHandler(lastTicker);
        }
    }

    // eslint-disable-next-line
    const debounceChangeHandler = useCallback(debounce(lookupTickers, 500), []);

    const handleClose = () => {
        setTickers([]);
        setCompanyLookupSearchTerm("");
        setShowCompanySearchResults(false);
        setSelectedResultIndex(0);
        setCompanyLookupResults([]);
        setHasError(false);
        onClose();
    }

    return (
        <Modal className="ticker-lookup-modal" show={isOpen} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Ticker Lookup</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="selected-tickers-container">
                    <Form.Label>Selected Tickers</Form.Label>
                    <div className="selected-tickers">
                        {tickers.map((ticker, index) => {
                            return <DismissiblePill key={index} displayText={ticker} onRemove={() => {
                                const newTickers = tickers.filter((t) => t !== ticker);
                                setTickers(newTickers);
                            }}
                                id={ticker}
                                backgroundColor="black"
                            />
                        })}
                    </div>
                    {tickers.length > 0 ? <div className="clear-all-tickers">
                        <Link className="clear-all-tickers-link" onClick={() => setTickers([])}>Clear All</Link>
                    </div> : null}
                </div>
                <Form.Label>Search for a company</Form.Label>
                <Form.Control
                    className="ticker-lookup-input"
                    type="text"
                    required
                    placeholder="e.g. Apple, Microsoft, etc."
                    value={companyLookupSearchTerm}
                    onChange={(e) => handleCompanyLookup(e)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            if (companyLookupResults.length > 0) {
                                const selectedCompany = companyLookupResults[selectedResultIndex];
                                handleTickers([selectedCompany.ticker]);
                                setCompanyLookupSearchTerm("");
                                setShowCompanySearchResults(false);
                            }
                        }
                    }}
                    autoComplete="off"
                ></Form.Control>
                <TickerLookupDisplay showCompanySearchResults={showCompanySearchResults}
                    companyLookupSearchTerm={companyLookupSearchTerm}
                    companyLookupResults={companyLookupResults}
                    handleUpdatingSelectedIndex={(index) => {
                        setSelectedResultIndex(index);
                    }}
                    loadingCompanies={loadingCompanies}
                    handleTickerSelection={(actionType, selectedIndex) => handleTickerSelection(actionType, selectedIndex)}
                    selectedResultIndex={selectedResultIndex}
                    setSelectedResultIndex={setSelectedResultIndex}
                />
                {hasError ? <Alert variant="danger" className="ticker-lookup-error-alert">
                    There was an error retrieving the ticker information. Please try again later.
                </Alert> : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => { onAddTickers([...new Set(tickers)]); handleClose(); }}>
                    Add <i className="fa fa-plus-square"></i>
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function TickerLookupDisplay(props) {
    const reducer = (state, action) => {
        switch (action.type) {
            case 'arrowUp':
                return {
                    selectedIndex:
                        state.selectedIndex !== 0 ? state.selectedIndex - 1 : props.companyLookupResults.length - 1,
                };
            case 'arrowDown':
                return {
                    selectedIndex:
                        state.selectedIndex !== props.companyLookupResults.length - 1 ? state.selectedIndex + 1 : 0,
                };
            case 'select':
                return { selectedIndex: action.payload };
            default:
                throw new Error();
        }
    };

    const initialState = { selectedIndex: 0 };

    const arrowUpPressed = useKeyPress('ArrowUp');
    const arrowDownPressed = useKeyPress('ArrowDown');
    const [state, dispatch] = useReducer(reducer, initialState);

    const { showToast } = useContext(ToastContext);


    const displayTicker = (company) => {
        if (company.exchange) {
            return company.exchange + ":" + company.ticker;
        }
        return company.ticker;
    }

    const handleCopyValue = async (text) => {
        const type = "text/plain";
        const blob = new Blob([text], { type });
        const data = [new ClipboardItem({ [type]: blob })];

        await navigator.clipboard.write(data);
        showToast("Copied " + text + " to clipboard");
    }

    useEffect(() => {
        if (arrowUpPressed) {
            dispatch({ type: 'arrowUp' });
        }
    }, [arrowUpPressed]);

    useEffect(() => {
        if (arrowDownPressed) {
            dispatch({ type: 'arrowDown' });
        }
    }, [arrowDownPressed]);

    useEffect(() => {
        props.handleUpdatingSelectedIndex(state.selectedIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selectedIndex]);

    useEffect(() => {
        if (props.companyLookupResults.length > 0) {
            dispatch({ type: 'select', payload: 0 });
        }
    }, [props.companyLookupResults]);

    return (
        <div className="ticker-display-pannel" style={{ border: "1px solid", margin: "0 0.25rem 0 0" }}>
            {props.showCompanySearchResults && props.companyLookupSearchTerm && props.companyLookupResults.length > 0 && !props.loadingCompanies ?
                <div className="company-lookup-results" tabIndex={-1}>
                    {props.companyLookupResults.map((company, index) => {
                        return <div key={index} className="container">
                            <div className="row company-lookup-result"
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: index === state.selectedIndex ? '#eee' : ''
                                }}>
                                <div className="col-md-6"
                                    style={{ padding: '5px' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.handleTickerSelection("click", index);
                                    }}>
                                    {company.companyName}
                                </div>
                                <div className="col-md-1 copy-value-button">
                                    <i className="fas fa-clipboard" onClick={() => handleCopyValue(company.companyName)}></i>
                                </div>
                                <div className="col-md-4"
                                    style={{ padding: '5px' }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        props.handleTickerSelection("click", index);
                                    }}>
                                    {displayTicker(company)}
                                </div>
                                <div className="col-md-1 copy-value-button">
                                    <i className="fas fa-clipboard" onClick={() => handleCopyValue(displayTicker(company))}></i>
                                </div>
                            </div>
                        </div>;
                    })}
                </div> : null}
            {props.showCompanySearchResults && props.companyLookupSearchTerm && props.companyLookupResults.length === 0 && !props.loadingCompanies ?
                <div className="company-lookup-results">
                    <div className="company-lookup-result">
                        No companies found
                    </div>
                </div> : null}
            {props.loadingCompanies && props.companyLookupSearchTerm && props.companyLookupSearchTerm.length > 0 ?
                <div className="company-lookup-results">
                    <div className="company-lookup-result">
                        Loading Companies...
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true">
                        </Spinner></div>
                </div> : null}
        </div>);
}

export default TickerLookupModal;