import { Button } from "react-bootstrap";

export const rowActionsComponent = (userHistoryRecordsStateMap, uniqueId, handleHistoryRecordUpdate, handleRowStateUpdate) => {
    return <div className="row-actions" key={uniqueId}>
        {userHistoryRecordsStateMap.get(uniqueId) ?
            <Button onClick={(e) => handleHistoryRecordUpdate(e, uniqueId)}>
                <i className="fa fa-save" style={userHistoryRecordsStateMap.get(uniqueId) ? { color: "green" } : { color: "" }} title="Save"></i>
            </Button> :
            <Button onClick={(e) => handleRowStateUpdate(e, uniqueId)}>
                <i className="fa fa-edit" title="Edit"></i>
            </Button>}
    </div>;
}
