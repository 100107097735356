import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useStateStore } from "../../AppState.js";

import DeleteTemplateConfirmationDialog from "../../CommonComponents/DeleteTemplateConfirmationDialog/DeleteTemplateConfirmationDialog.js";
import { getAuthToken } from "../../utils/AuthUtils.js";

import { formatCompanyIds } from "../../Constants.js";
import './styles.scss';

const TEMPLATES_URL = "/templates";

const DELETE_TEMPLATE_DATA_URL = "/template-data";

const DeleteTempLateDataModal = ({ show, onHide, templates }) => {
    const [loading, setLoadingStatus] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("")
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false);
    const [isFormValidated, setIsFormValidated] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [selectedDate, setSelectedDate] = useState("");
    const [tickers, setTickers] = useState([]);

    const handleFormattingTickers = () => {
        const formattedTickers = formatCompanyIds(tickers);
        setTickers(formattedTickers);
        return formattedTickers;
    }

    const handleOnHide = () => {
        onHide();
        setSelectedTemplate(null);
        setSelectedDate("");
        setTickers([]);
        setIsFormValidated(false);
        setShowSuccess(false);
        setShowError(false);
    }

    const handleSettingSelectedTemplate = (e) => {
        const templateId = e.target.value;
        const templateSelected = templates.find(template => template.templateId === templateId);
        if (templateSelected !== undefined) {
            setSelectedTemplate(templateSelected);
        }
    }

    const handleDeletingTemplateData = async (e) => {
        const formattedTickers = handleFormattingTickers();
        if (e.currentTarget.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            if (formattedTickers.length > 15) {
                setError("Maximum 15 tickers can be entered at a time.");
                setShowError(true);
                return;
            }
            await deleteTemplateData(selectedTemplate, formattedTickers);
        }
        setIsFormValidated(true);
    }

    const deleteTemplateData = async (template, formattedTickers) => {
        const authToken = await getAuthToken();
        setLoadingStatus(true);
        const requestBody = {
            "templateType": template.templateType,
            "tickers": formattedTickers
        };
        switch (template.templateType) {
            case "Executive":
            case "Broad-based":
                requestBody["fye"] = selectedDate;
                break;
            case "Survey":
                requestBody["surveyYear"] = new Date(selectedDate).getFullYear() + 1;
                break;
            default:
                break;
        }
        API.del(
            "AnnualCompensations",
            DELETE_TEMPLATE_DATA_URL + "/" + template.templateId,
            {
                headers: {
                    "Content-Type": "appplication/json",
                    "Token": authToken
                },
                body: requestBody
            }).then(reponse => {
                setSuccess(reponse.message);
                setShowSuccess(true);
                setLoadingStatus(false);
            }).catch(error => {
                setLoadingStatus(false);
                if (error !== undefined && error.response !== undefined &&
                    error.response.data !== undefined && error.response.data.message !== undefined) {
                    setError(error.response.data.message);
                    setShowError(true);
                } else {
                    setError("An error occurred while modifying template data. Please try again later.");
                    setShowError(true);
                }
            });
    }

    return (
        <Modal className="delete-data-modal" show={show} onHide={handleOnHide}>
            <Form noValidate validated={isFormValidated} onSubmit={handleDeletingTemplateData}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Template Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Select Template</Form.Label>
                        <Form.Control required as="select" onChange={handleSettingSelectedTemplate}>
                            <option value="">Select Template</option>
                            {templates.map((template, index) => {
                                return (
                                    <option key={index} value={template.templateId}>{template.templateName}</option>
                                );
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please select a template.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {(selectedTemplate && (selectedTemplate.templateType === "Executive" || selectedTemplate.templateType === "Broad-based")) && <Form.Group>
                        <Form.Label>Enter Fiscal Year End</Form.Label>
                        <Form.Control type="date" placeholder="Enter FYE" onChange={(e) => setSelectedDate(e.target.value)} />
                    </Form.Group>}
                    {(selectedTemplate && selectedTemplate.templateType === "Survey") &&
                        <Form.Group>
                            <Form.Label>Enter Survey Year</Form.Label>
                            <Form.Control type="text" placeholder="Enter Survey Year" onChange={(e) => setSelectedDate(e.target.value)} />
                        </Form.Group>
                    }

                    <Form.Group>
                        <Form.Label>Enter Tickers</Form.Label>
                        <Form.Control as="textarea" value={tickers} required placeholder="Enter Tickers" onChange={(e) => setTickers(e.target.value)} />
                        <Form.Control.Feedback type="invalid">
                            Please enter tickers.
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                            Enter the tickers for which you want to delete the data. Multiple tickers can be entered by separating them with a comma. Copy and paste from excel is also supported.
                        </Form.Text>
                    </Form.Group>

                    <Alert show={showSuccess} variant="success" onClose={() => setShowSuccess(false)} dismissible>
                        <Alert.Heading>Success</Alert.Heading>
                        <p>{success}</p>
                    </Alert>
                    <Alert show={showError} variant="danger" onClose={() => setShowError(false)} dismissible>
                        <Alert.Heading>Error</Alert.Heading>
                        <p>{error}</p>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    {loading ? <Button variant="danger" disabled>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> Deleting...
                    </Button> : <Button type="submit" variant="danger">Delete</Button>}
                    <Button variant="secondary" onClick={handleOnHide}>Cancel</Button>
                </Modal.Footer>
            </Form>
        </Modal >

    );
}


const TemplateDefinitionTab = () => {

    const templates = useStateStore(state => state.templates);
    const fetchTemplates = useStateStore(state => state.fetchTemplates);

    const [loading, setLoadingStatus] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("")
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false);

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const navigate = useNavigate();

    const deleteTemplate = async (templateId) => {
        const authToken = await getAuthToken();
        setLoadingStatus(true);
        API.del(
            "AnnualCompensations",
            TEMPLATES_URL + "/" + templateId,
            {
                headers: {
                    "Content-Type": "appplication/json",
                    "Token": authToken
                }
            }).then(reponse => {
                fetchTemplates();
                setSuccess(reponse.message);
                setShowSuccess(true);
                setLoadingStatus(false);
            }).catch(error => {
                setLoadingStatus(false);
                if (error !== undefined && error.response !== undefined &&
                    error.response.data !== undefined && error.response.data.message !== undefined) {
                    setError(error.response.data.message);
                    setShowError(true);
                } else {
                    setError("An error occurred while modifying template data. Please try again later.");
                    setShowError(true);
                }
            });
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            fetchTemplates();
        }
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading && <div className='loading'>
                Loading... Please wait
            </div>}
            {!loading &&
                <div className='template-definitions-tab'>
                    <DeleteTempLateDataModal show={showDeleteConfirmation} templates={templates} onHide={() => setShowDeleteConfirmation(false)} />
                    <div className='template-definitions-actions'>
                        <Button onClick={() => navigate("/admin/templates/new")} variant='primary'>Add Template Definition</Button>
                        <Button onClick={() => setShowDeleteConfirmation(true)} variant='primary'>Delete template data</Button>
                    </div>
                    <div className="alerts">
                        {showError && <Alert variant='danger' dismissible={true} onClose={() => setShowError(false)}>{error}</Alert>}
                        {showSuccess && <Alert variant='success' dismissible={true} onClose={() => setShowSuccess(false)}>{success}</Alert>}
                    </div>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Template Name</th>
                                <th>Type</th>
                                <th>Active</th>
                                <th>Template Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {templates.length > 0 && templates.map((tempDefinition, index) => {
                                return <tr key={index} >
                                    <td>{index + 1}</td>
                                    <td>{tempDefinition.templateName}</td>
                                    <td>{tempDefinition.templateType}</td>
                                    <td>{tempDefinition.active.toString()}</td>
                                    <td>
                                        <div className="template-actions">
                                            <i title="edit" className="fas fa-edit" onClick={() => navigate("/admin/templates/" + tempDefinition.templateId)}></i>
                                            <DeleteTemplateConfirmationDialog deleteIconClass="fas fa-trash" onConfirmationAction={() => deleteTemplate(tempDefinition.templateId)} />
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </div>}
        </>
    )
}

export default TemplateDefinitionTab;