import { useRef, useState } from "react";
import { Popover, Row, Col, Button, Overlay } from "react-bootstrap";

import './styles.scss';

const DeleteTemplateConfirmationDialog = ({ onConfirmationAction, deleteIconClass }) => {
    const [showPopover, setShowPopover] = useState(false);
    const [popoverTarget, setPopoverTarget] = useState(null);
    const containerRef = useRef(null);

    const handleDeleteClick = (e) => {
        setShowPopover(!showPopover);
        setPopoverTarget(e.target);
    }

    const handleCancelClick = () => {
        setShowPopover(false);
    }

    return (
        <div ref={containerRef} className="delete-confirmation-button">
            <i className={deleteIconClass} onClick={handleDeleteClick} style={{ color: "red" }} />
            <Overlay show={showPopover} target={popoverTarget} container={containerRef} containerPadding={10} placement="right-end" transition={true}>
                <Popover id="confirmation-popover">
                    <Popover.Header as="h3">Are you sure?</Popover.Header>
                    <Popover.Body>
                        <Row>
                            <Col md={6}>
                                <Button className="deny" variant="danger" onClick={onConfirmationAction}>Yes</Button>
                            </Col>
                            <Col md={6}>
                                <Button className="confirm" variant="primary" onClick={handleCancelClick}>No</Button>
                            </Col>
                        </Row>
                    </Popover.Body>
                </Popover>
            </Overlay>
        </div>
    )
}

export default DeleteTemplateConfirmationDialog;