import { useState } from 'react'
import { Badge } from 'react-bootstrap'
import DismissiblePill from '../DismissiblePill/DismissiblePill'
import ToolTipWrapper from '../ToolTipComponent/ToolTipWrapper'

import './styles.scss'

const MultiSelectDropDownInput = ({ options, toggleOption, clearAll, optionSearch }) => {

    const [isDDActive, setIsDDActive] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState([])
    const [optionSearchValue, setOptionSearchValue] = useState('')

    const handleOptionSearch = (e) => {
        optionSearch(e.target.value);
        setOptionSearchValue(e.target.value)
    }

    const resolveSelectedOptions = (prevSelectedOptions, selectedOption) => {
        const newArray = [...prevSelectedOptions]
        if (newArray.map(opt => opt.id).includes(selectedOption.id)) {
            return newArray.filter(item => item.id !== selectedOption.id)
        } else {
            newArray.push(selectedOption)
            return newArray;
        }
    }

    const toggleSelectedOptions = (option) => {
        setSelectedOptions(prevSelectedOptions => resolveSelectedOptions(prevSelectedOptions, option));
        toggleOption({ id: option.id })
    }

    const handleClearAll = () => {
        clearAll();
        setSelectedOptions([]);
    }

    const handleDeSelectOption = (id) => {
        setSelectedOptions(selectedOptions.filter(opt => opt.id !== id));
        toggleOption({ id })
    }

    return (
        <div className="c-multi-select-dropdown" tabIndex={0}
            onMouseLeave={() => setIsDDActive(false)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    setIsDDActive(!isDDActive)
                } else if (e.key === 'Escape') {
                    setIsDDActive(false)
                }
            }}>
            <div className="c-multi-select-dropdown__selected">
                <div className='c-multi-select-dropdown__selected-description'>
                    {selectedOptions.length > 0 ?
                        <>
                            {selectedOptions.map(opt => {
                                return <ToolTipWrapper key={opt.id} placement={"top"} id={opt.id + "-popover"} text={opt.value} element={<DismissiblePill id={opt.id} backgroundColor={"dark"} onRemove={handleDeSelectOption} displayText={opt.id}></DismissiblePill>} />
                            })}
                        </>
                        : <ToolTipWrapper key={"any"} placement={"top"} id={"default-popover"} text={"All Options"} element={<Badge style={{ fontSize: "0.85rem", cursor: "pointer" }} pill bg="dark">ALL</Badge>} />}
                    <input className='c-multi-select-dropdown__search-input' tabIndex={0} type="text" value={optionSearchValue}
                        onKeyDown={(e) => {
                            if(e.key !== "Tab") {
                                setIsDDActive(true)
                            }
                        }}
                        onChange={handleOptionSearch} />
                </div>
                <i className="fa fa-sharp fa-solid fa-chevron-down"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsDDActive(!isDDActive)}
                >
                </i>
            </div>
            <ul className="c-multi-select-dropdown__options" tabIndex={-1} style={{ display: isDDActive ? "block" : "none" }}>
                <li className="c-multi-select-dropdown__option" key={"clear-all"} tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            clearAll();
                        }
                    }}>
                    <p style={{ marginBottom: "0", cursor: "pointer", color: "#00416A", fontWeight: "bold" }} className='c-multi-select-dropdown__option-label' onClick={handleClearAll} tabIndex={0}>Unselect All</p>
                </li>
                {options.map(option => {
                    const isSelected = selectedOptions.map(opt => opt.id).includes(option.id);
                    return (
                        <li className="c-multi-select-dropdown__option" key={option.value} tabIndex={0}>
                            <input type="checkbox" checked={isSelected} onChange={() => toggleSelectedOptions(option)} tabIndex={0} className="c-multi-select-dropdown__option-checkbox"></input>
                            <span className='c-multi-select-dropdown__option-label'>{option.value}</span>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default MultiSelectDropDownInput;