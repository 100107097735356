import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { formatTickers } from "../../Constants";

import "./styles.scss";

const BulkTickerInputModal = ({ isOpen, onClose, onAddTickers, initialTickers, disabled }) => {
    const [tickers, setTickers] = useState(initialTickers || []);

    const handleAddTickers = () => {
        const formattedTickers = formatTickers(tickers);
        setTickers(initialTickers);
        onAddTickers(formattedTickers || []);
        onClose();
    };

    useEffect(() => {
        if (isOpen) {
            setTickers(initialTickers);
        }
    }, [isOpen, initialTickers]);

    return (
        <>
            {!disabled ? <Modal className="bulk-ticker-modal" show={isOpen} onHide={onClose}>
                <Modal.Header>
                    <Modal.Title>Expanded Tickers View</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Tickers</Form.Label>
                        <Form.Control as="textarea" rows={5} value={tickers} onChange={(e) => setTickers(e.target.value)} />
                        <Form.Text className="text-muted">
                            Enter tickers separated by commas or new lines. Copy and paste from Excel is also supported.
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleAddTickers}>
                        Search
                        <i className="fa fa-search"></i>
                    </Button>
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> : null}
        </>
    );
}

export default BulkTickerInputModal;