import pako from 'pako';

export const compressDownloadRequestData = (request) => {
    try {
      const requestStr = JSON.stringify(request);
      const compressedRequest = pako.deflate(requestStr, { to: 'string' });
      const compressedSting = String.fromCharCode.apply(null, compressedRequest)
      const base64Encoded = btoa(compressedSting);
      return JSON.stringify({ action: "download", data: base64Encoded });
    } catch (e) {
      console.log(e)
      throw new Error();
    }
  }